import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { Add, Delete, Edit } from "@mui/icons-material";

//LOCALS
import { BASE_URL, BASE_URL_IMG } from "../../api";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import WizModal from "../WizModal";
import { AddMainNewsForm } from "../Forms/NewsForm/AddMainNewsForm";
import EditNewsForm from "../Forms/NewsForm/EditNewsForm";
import { AddNewsForm } from "../Forms/NewsForm/AddNewsForm";
import { useAppSelector } from "../../redux/hooks";
import toast from "../Toast";

//ICONS
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import SearchBar from "material-ui-search-bar";
import DeletedItem from "../Errors/DeletedItem";
import DeleteConfirm from "../Errors/DeleteConfirm";
import useLocalStorage from "../../hooks/useLocalStorage";
import { NewsProps, PlayerNews } from "../../types/data";
import { useAtom } from "jotai";
import { languageCode } from "../jotai/jotaiAtom";

interface HeadCell {
  disablePadding: boolean;
  id: keyof RealNews;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RealNews
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "title",
    disablePadding: false,
    numeric: false,
    label: "Título Noticia",
  },
  {
    id: "country_code_list",
    disablePadding: false,
    numeric: false,
    label: "Idiomas", 
  },
  {
    id: "text",
    disablePadding: false,
    numeric: false,
    label: "Texto Noticia",
  },
];

interface RealNews {
  country_code: string;
  country_code_list: any;
  daytime?: string;
  img_url: string;
  keywords: string;
  news_id: string;
  own_notice: string;
  text: string;
  title: string;

  player_list: PlayerNews[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

function NewsData({ data }: any) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [newsData, setNewsData] = useState<{ text: string; title: string }>({
    text: "",
    title: "",
  });

  const [itemDeleted, setItemDeleted] = useState();
  const [openDeleteNewsModal, setOpenDeleteNewsModal] =
    useState<SetStateAction<any>>();
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [deleteNewsResponse, setDeleteNewsResponse] =
    useState<SetStateAction<any>>();

  //EDIT NEWS STATE
  const [showEditNewsModal, setShowEditNewsModal] = useState<boolean>(false);
  const [editNewsResponse, setEditNewsResponse] =
    useState<SetStateAction<any>>();

  const [lang, setLangCode] = useAtom(languageCode);

  //ROWS
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [rows, setRows] = useState<RealNews[]>(data);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RealNews>("news_id");

  const [animationParent] = useAutoAnimate<any>();
  const [rowsId, setRowsId] = useState<SetStateAction<any>>();
  const [itemNews, setItemNews] = useState<string | undefined>();

  //MAIN NEWS
  const [showEditItemNewsModal, setShowEditItemNewsModal] =
    useState<boolean>(false);
  const [showMainModalNews, setShowMainModalNews] = useState<boolean>(false);
  const [mainNewsFormData, setMainNewsFormData] = useState<{
    text: any;
    title: any;
    date: any;
    keywords: any;
    url?: any;
    img_url?: any;
    lang?: any;
  }>({
    date: "",
    img_url: "",
    keywords: "",
    text: "",
    title: "",
    url: "",
    lang: "",
  });

  const [showAddNewsModal, setShowAddNewsModal] =
    useState<SetStateAction<any>>();
  const [newsFormData, setNewsFormData] = useState<{
    text: string;
    title: string;
    date: string;
    keywords: string;
    url: string;
    country_code:string;
  }>({ text: "", title: "", date: "", keywords: "", url: "", country_code:"" });
  const filters = useAppSelector((state) => state.player);

  //CREATE RESPONSE
  const [createNewsResponse, setCreateNewsResponse] =
    useState<SetStateAction<any>>();
  const [createMainNewsResponse, setCreateMainNewsResponse] =
    useState<SetStateAction<any>>();

  const [players, setPlayers] = useState<SetStateAction<any>>();
  const [searched, setSearched] = useState<string>("");

  //REFRESH PAGE
  const refreshPage = () => {
    window.location.reload();
  };

  //AÑADIR NUEVAS NOTICIAS
  const { mutate: addMutateMainNews, isLoading: isLoadingMain } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("country_code", "ES");
      formData.append("text", mainNewsFormData?.text);
      formData.append("title", mainNewsFormData?.title);
      formData.append("date", mainNewsFormData?.date);
      formData.append("url", mainNewsFormData?.url);
      formData.append("img_url", mainNewsFormData?.url);
      formData.append("keywords", mainNewsFormData?.keywords);
      return await axios.post(`${BASE_URL}news`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };

        setCreateMainNewsResponse(result);
      },
      onError: (err: any) => {
        setCreateMainNewsResponse(err.response.data || err);
      },
    }
  );

  //AÑADIR NUEVAS NOTICIAS RESPONSE
  useEffect(() => {
    if (createMainNewsResponse?.data?.result === 1) {
      notify("success", "Nueva Noticia Añadida");
      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1500);
    } else if (createMainNewsResponse?.data?.status === "error") {
      notify("error", `${createMainNewsResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createMainNewsResponse]);

  //ADD NEWS COUNTRY CODE
  const addMainNews = () => {
    try {
      if (
        mainNewsFormData.text !== undefined &&
        mainNewsFormData.title !== undefined &&
        mainNewsFormData?.date !== undefined
      )
        addMutateMainNews();
    } catch (err) {
      console.log(err);
    }
  };

  //EDIT NEWS ROW
  const { isLoading, mutate: editNewsRow } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("news_text", newsData?.text);
      formData.append("news_title", newsData?.title);
      return await axios.post(`${BASE_URL}news`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };

        setEditNewsResponse(result);
      },
      onError: (err: any) => {
        setEditNewsResponse(err.response.data || err);
      },
    }
  );

  //AÑADIR NEWS
  const { mutate: addMutateNewsCD, isLoading: isLoadingMutate } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("news_id", rowsId);
      formData.append("country_code", newsFormData?.country_code);
      formData.append("text", newsFormData?.text);
      formData.append("title", newsFormData?.title);
      formData.append("date", newsFormData?.date);
      formData.append("own_notice", "1");

      return await axios.post(`${BASE_URL}news`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setCreateNewsResponse(result);
      },
      onError: (err: any) => {
        setCreateNewsResponse(err.response.data || err);
      },
    }
  );

  //DELETE NEWS
  const { mutate: deleteMutateNews } = useMutation(
    async () => {
      return await axios.delete(
        `${BASE_URL}news`,

        {
          params: {
            news_id: rowsId,
            country_code: itemDeleted,
          },
          headers: {
            Authorization: "Bearer " + uid,
          },
        }
      );
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setDeleteNewsResponse(result);
      },
      onError: (err: any) => {
        setDeleteNewsResponse(err.response.data || err);
      },
    }
  );

  //ADD NEWS COUNTRY CODE
  const addNewCd = () => {
    try {
      if (newsFormData.text !== "" && newsFormData.title !== "")
        addMutateNewsCD();
    } catch (err) {
      console.log(err);
    }
  };

  // GET NEWS BY ID
  const getNewsById = async () => {
    if (rowsId === undefined) {
      return null;
    }
    const { data } = await axios.get(
      `${BASE_URL}news?news_id=${rowsId}`
    );
    return data;
  };


  const { data: newsRow } = useQuery<NewsProps>(
    ["newsById", rowsId],
    getNewsById
  );

  //EDIT NEWS
  const editNews = () => {
    //EDIT NEWS
    try {
      if (newsData?.text !== "" && newsData?.title) {
        editNewsRow();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //DELETE NEWS
  const deleteNews = () => {
    //DELETE NEWS
    try {
      if (itemDeleted !== "") {
        deleteMutateNews();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //SHOW ANIMATION
  const showAnimation = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      editNews();
    }
  };
  const showAnimationAdd = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addNewCd();
    }
  };
  const showAnimationAddMain = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addMainNews();
    }
  };
  const showAnimationCD = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addNewCd();
    }
  };
  const showAnimationDelete = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deleteNews();
    }
  };

  //DELETE NEWS
  const showHiddenDeleteComponent = (item: any) => {
    setOpenDeleteNewsModal(true);
    setItemDeleted(item);
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);
  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //ADD NEWS RESPONSE
  React.useEffect(() => {
    if (createNewsResponse?.data?.result === 1) {
      notify("success", `Noticia Añadida`);

      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (createNewsResponse?.data?.status === "error") {
      notify("error", `${createNewsResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createNewsResponse]);

  //DELETE NEWS RESPONSE
  useEffect(() => {
    if (deleteNewsResponse?.data?.result?.newsDeleted === 1) {
      notify("success", `Noticia Eliminada`);
      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (deleteNewsResponse?.data?.status === "error") {
      notify("error", `${deleteNewsResponse?.data?.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deleteNewsResponse]);

  //HANDLE REQUEST SORT
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RealNews
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //HANDLE CLICK
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  //CHANGE PAGE
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort, numSelected } = props;

    const createSortHandler =
      (property: keyof RealNews) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  //SEARCH
  const requestSearch = (searchVal: string) => {
    const filteredRows = data.filter((row: any) => {
      return row.keywords?.toLowerCase().includes(searchVal.toLowerCase());
    });

    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    setPlayers(newsRow?.result?.map((player) => player));
  }, [newsRow]);


  return (
    <Box sx={{ height:"1200px", width: "1500px" }}>
      <div
        className="mt-20"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ width: "500px" }}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="Buscar Noticia"
          />
        </div>
        <button
          onClick={() => setShowMainModalNews(!showMainModalNews)}
          className="py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800 flex items-center"
        >
          <h1 className="font-sans font-bold">Añadir Noticia</h1>
          <Add />
        </button>
      </div>
      <br />

      <Paper sx={{ width: "100%" }}>
        
        <WizModal
          modalID="news-edit"
          modalOpen={showMainModalNews}
          setOpenModal={(e) => setShowMainModalNews(false)}
          modalWhithDelay={2000}
          modalTitle="Añadir Nueva Noticia"
          onModalClose={(desition) => showAnimationAddMain(desition, 2000)}
        >

          <div>
            <AddMainNewsForm
              onSubmit={(data) => {
                setMainNewsFormData(data as any);
              }}
              isLoading={isLoadingMain}
              update={() => showAnimationAddMain("YES", 2000)}
            />
          </div>
        </WizModal>
        <WizModal
          modalID="news-edit"
          modalOpen={showEditNewsModal}
          setOpenModal={(e) => setShowEditNewsModal(false)}
          modalWhithDelay={2000}
          modalTitle="Editar Noticia"
          onModalClose={(desition) => showAnimation(desition, 2000)}
        >
          <button
            className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
            onClick={() => setShowAddNewsModal(!showAddNewsModal)}
            style={{marginBottom:'12px'}}
          >
            <h1>Añadir Noticia</h1>
            <Add />
          </button>

          <WizModal
            modalID="news-delete"
            modalTitle={"Borrar Noticia"}
            modalOpen={openDeleteNewsModal}
            setOpenModal={(e) => setOpenDeleteNewsModal(false)}
            modalType={"DESITION"}
            modalWhithDelay={2000}
            onModalClose={(desition) => showAnimationDelete(desition, 2000)}
          >
            <div>
              {showDeleteAnimation === "YES" ? (
                <DeletedItem title="Esta Noticia" />
              ) : (
                <DeleteConfirm title="la Noticia" />
              )}
            </div>
          </WizModal>
          <div>
            <div className="flex space-x-2">
              {players?.[0].player_list.map((player: any) => (
                <div
                  key={player.img_profile_url}
                  className=" border shadow rounded-full overflow-hidden hover:scale-125 transition-all transform duration-150 ease-in-out cursor-pointer"
                >
                  <img
                    src={BASE_URL_IMG + player.img_profile_url}
                    width={40}
                    height={40}
                    className="w-16 object-cover group-hover:-translate-y-1  "
                    alt="profile_img"
                  />
                </div>
              ))}
            </div>
            <Paper>
              <TableContainer
                sx={{
                  maxHeight: 840,
                  "&::-webkit-scrollbar": {
                    width: 10,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "cyan",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "lightblue",
                    borderRadius: 2,
                  },
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Lenguaje</TableCell>
                      <TableCell>Titulo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newsRow?.result?.map((row) => (
                      <>
                        <TableRow>
                          <TableCell>{row.country_code}</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>
                            <div className="flex items-center">
                              <EditIcon
                                onClick={() => {
                                  setItemNews(row.country_code);
                                  setShowEditItemNewsModal(
                                    !showEditItemNewsModal
                                  );
                                }}
                              />
                              <TrashIcon
                                onClick={() => {
                                  setRowsId(row.news_id);
                                  showHiddenDeleteComponent(row.country_code);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </WizModal>
        <WizModal
          modalID="news-edit"
          modalOpen={showAddNewsModal}
          setOpenModal={(e) => setShowAddNewsModal(false)}
          modalWhithDelay={2000}
          modalTitle="Añadir Noticia"
          onModalClose={(desition) => showAnimationAdd(desition, 2000)}
        >
          <div>
            <AddNewsForm
              onSubmit={(data: any) => {
                setNewsFormData(data);
              }}
              isLoading={isLoadingMutate}
              update={() => showAnimationAdd("YES", 2000)}
            />
          </div>
        </WizModal>

        <WizModal
          modalID="news-edit"
          modalOpen={showEditItemNewsModal}
          setOpenModal={(e) => setShowEditItemNewsModal(false)}
          modalWhithDelay={2000}
          modalTitle="Editar Noticia"
          onModalClose={(desition) => showAnimationCD(desition, 2000)}
        >
          <div>
            <EditNewsForm
              refreshData={() => refreshPage()}
              itemNews={itemNews!}
              rowsId={rowsId!}
              onSubmit={(data) => console.log("newsData", data)}
              update={() => showAnimationCD("YES", 2000)}
            />
          </div>
        </WizModal>

        <TableContainer
          sx={{
            maxHeight: 840,
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "cyan",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "lightblue",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750, marginTop: 4 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody ref={animationParent}>
              {stableSort(rows as any, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, index) => {
                  const isItemSelected = isSelected(row.news_id as any);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const countryCodes = row.country_code_list.map(
                    (c: any) => c.country_code
                  );
                  const svg = ".svg";
                  return (
                    <React.Fragment key={row.news_id}>
                      <TableRow
                        sx={{ "& > *": { borderBottom: "unset" } }}
                        hover
                        onClick={(event) =>
                          handleClick(event, row.news_id as any)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell sx={{ maxWidth: 200 }}>
                          {row.title}
                        </TableCell>

                        <TableCell sx={{ maxWidth: 200 }}>
                          <div className="flex flex-wrap items-center w-36 gap-1">
                          <img src= {BASE_URL_IMG + "flags/es.svg"} style={{maxWidth:'30px' , marginBottom:'5px', borderWidth: '1px', borderColor:'black'}}/>
                          {countryCodes.map((countryCode: string) => (
                           <img src={BASE_URL_IMG +" flags/" + countryCode + svg}  style={{maxWidth:'30px' , marginBottom:'5px', borderWidth: '1px', borderColor:'black'}}/>
                           
                            
                          ))}
                          </div>
                        </TableCell>
                        <TableCell sx={{ maxWidth: 600 }}>{row.text}</TableCell>
                        <TableCell>
                          <div className="flex items-center justify-center">
                            <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                              <Edit
                                onClick={() => {
                                  setRowsId(row.news_id);
                                  setShowEditNewsModal(!showEditNewsModal);
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default NewsData;
