import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

//LOCALS
import { BASE_URL } from "../../../api";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Loader } from "../../Loader";
import toast from "../../Toast";

interface Values {
  quote: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  item: string;
  update: any;
}

export const EditQuoteForm = ({ onSubmit, item, update }: Props) => {
  const [uid, setUid] = useLocalStorage("UID", "");
  const route = useNavigate();
  const [quoteValue, setQuoteValue] = React.useState("");
  const params = useParams();
  const playerId = params.id as any;
  const [updatedQuoteResponse, setUpdatedQuoteResponse] = React.useState<any>();

  //GET QUOTES BY COUNTRY
  const getQuotesByCountry = async () => {
    const { data } = await axios.get(
      `${BASE_URL}quote?player_id=${playerId}&country_code=${item}`
    );

    return data.result[0];
  };

  const { data: quoteData, isLoading: isBioLoading } = useQuery(
    ["playerQuoteByCountry", item],
    getQuotesByCountry
  );

  const { mutate: updateRowBio, isLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("country_code", item);
      formData.append("quote_text", quoteValue);
      return axios.post(`${BASE_URL}quote`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedQuoteResponse(result);
      },
      onError: (err: any) => {
        setUpdatedQuoteResponse(err.response?.data || err);
      },
    }
  );

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  React.useEffect(() => {
    if (updatedQuoteResponse?.data?.result === 1) {
      notify("success", `Quote Actualizada`);

      setTimeout(() => {
        dismiss();
      }, 1000);
    } else if (updatedQuoteResponse?.data?.status === "error") {
      notify("error", `${updatedQuoteResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedQuoteResponse]);

  if (!isBioLoading || !isLoading)
    return (
      <div>
        <h1>{item}</h1>

        <Formik
          initialValues={{
            quote: quoteData?.quote,
          }}
          onSubmit={(values, { resetForm }) => {
            setQuoteValue(values.quote);
            onSubmit(values);
            resetForm();
            if (quoteValue !== "") {
              updateRowBio();
              update();
            }
          }}
        >
          {({ values, handleChange, handleBlur, submitForm, isSubmitting }) => (
            <Form>
              {isSubmitting ? (
                <Loader />
              ) : (
                <div className="flex flex-col justify-between space-y-4">
                  <div>
                    <textarea
                      className="w-full h-40 px-4 py-2 border"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="quote"
                      placeholder="Quote / Cita"
                      value={values.quote}
                    />
                  </div>
                </div>
              )}
              <button         className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "disabled={isSubmitting} type="submit">
                Actualizar Quote
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  else {
    return <Loader />;
  }
};
