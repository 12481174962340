import { Edit } from "@mui/icons-material";

export const ButtonPencil = ({ ...props }) => {
  return (
    <button
      {...props}
      className="opacity-0 group-hover:opacity-100 absolute right-1/2 top-1/2 left-1/2 transition-all transform duration-150 ease-in-out group-hover:bg-white w-16 h-16 z-10 justify-center items-center rounded-full text-center align-middle cursor-pointer shadow-lg"
    >
      <Edit />
    </button>
  );
};
