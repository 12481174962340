import { Formik } from "formik";
import React, { ReactNode, SetStateAction } from "react";

//ICONS
import { IoCloseSharp } from "react-icons/io5";
import { Player, Positions } from "../../types/data";

//LOCALS
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { Loader } from "../Loader";
import PrimaryPositionSelect from "../select/PrimaryPositionSelect";
import SecondaryPositionSelect from "../select/SecondaryPositionSelect";

interface Props {
  modalID: string;
  modalTitle: string;
  children: ReactNode;
  modalOpen: boolean;
  setOpenModal: SetStateAction<any>;
  modalType?: string;
  modalWhithDelay: number;
  onModalClose: (desition: string) => Promise<void>;
  data: Player | undefined;
  setFormValues: SetStateAction<any>;
  positionData: Positions;
  update: any;
  isLoading: boolean;
}

function EditPlayerModalRd({
  modalID,
  modalTitle,
  positionData,
  children,
  modalOpen,
  setOpenModal,
  modalType,
  onModalClose,
  modalWhithDelay,
  data,
  setFormValues,
  update,
  isLoading,
}: Props) {
  const closeModal = async (desitionType: string) => {
    onModalClose(desitionType);
    if (modalType === "DESITION" && desitionType === "YES" && modalWhithDelay)
      await wizTimeOutFun(modalWhithDelay);
    setOpenModal(false);
  };

  const renderModalType = () => {
    switch (modalType) {
      case "CONFIRM":
        return (
          <label
            htmlFor={modalID}
            className="btn"
            onClick={() => closeModal("YES")}
          >
            ok
          </label>
        );
        break;
      case "DESITION":
        return (
          <>
            <div className="btn" onClick={() => closeModal("YES")}>
              Yes
            </div>
            <div className="btn" onClick={() => closeModal("NO")}>
              No
            </div>
          </>
        );
        break;

      case "NONE":
        return null;
        break;

      default:
        return null;
        break;
    }
  };
  return (
    <div>
      <div>
        <input type="checkbox" id={modalID} className={`modal-toggle`} />
        <div className={`modal ${modalOpen && "modal-open"}`}>
          <div className="modal-box ">
            <div
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={() => closeModal("NO")}
            >
              <IoCloseSharp />
            </div>

            <h3 className="font-bold text-lg">{modalTitle}</h3>
            <div className="p-2">{children}</div>
            {isLoading ? (
              <Loader />
            ) : (
              <Formik
                initialValues={{
                  primary_description:
                    data?.map_positions_translate.primary_description,
                  secondary:
                    data?.map_positions_translate.secondary[0]?.description,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setFormValues({
                    primary_description: values.primary_description,
                    secondary: values.secondary,
                  });
                  update();
                  setSubmitting(false);
                }}
              >
                {({
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex-col space-y-4">
                      <div className="flex flex-col items-start">
                        <div className="flex space-x-2">
                          <h1> {positionData?.first_position.position_id}</h1>
                          <h1> {positionData?.first_position.name}</h1>
                        </div>
                        <PrimaryPositionSelect />
                      </div>
                      <div className="flex-col ">
                        <div>
                          {positionData?.second_positions.map((sec) => (
                            <div key={sec.name} className="flex space-x-4">
                              <h1>{sec.position_code}</h1>|<h1>{sec.name}</h1>
                            </div>
                          ))}
                        </div>

                        <SecondaryPositionSelect />
                      </div>
                    </div>
                    <button
                    style={{marginTop:'12px'}}
                      type="submit"
                      className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
                    >
                      Cambiar Datos
                    </button>
                  </form>
                )}
              </Formik>
            )}

            <div className="modal-action">{renderModalType()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPlayerModalRd;
