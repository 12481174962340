import { useQuery } from "@tanstack/react-query";
import axios from "axios";

//LOCALS
import { BASE_URL } from "../api";
import NewsData from "../components/news/NewsData";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Loader } from "../components/Loader";

const getNews = async () => {
  const { data } = await axios.get(`${BASE_URL}news?country_code=ES`);

  return data;
};
function News() {
  const { data, isLoading } = useQuery(["news"], getNews);
  console.log(data);

  if (!isLoading)
    return (
      <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor:'#f3f3f4',
          height:'90vh'
        }}
      >
          <NewsData data={data?.result} />
        <Footer />
      </div>
      </div>
    );
  else {
    return <Loader />;
  }
}

export default News;
