import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SetStateAction, useEffect, useState } from "react";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";

//LOCALS
import { PLAYER_FILTER_ADD_PARAM } from "../../redux/constants/playerConstants";
import { useAppSelector } from "../../redux/hooks";
import { store } from "../../redux/store";
import WizSelect from "./WizSelect";

function SecondaryPositionSelect() {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [positionSecondary, setPositionSecondary] =
    useState<SetStateAction<any>>(1);
  const [selectValue, setSelectValue] = useState<SetStateAction<any>>(null);
  const filters = useAppSelector((state) => state.player);

  const getPositions = async () => {
    const { data } = await axios.get(
      `${BASE_URL}map.positions?country_code=ES`,
      {
        headers: {
          Authorization: "Bearer " + uid,
        },
      }
    );
    return data;
  };

  const { data, isLoading } = useQuery(["positions"], getPositions);

  const setFilter = (e: any[]) => {
    let values: any[] = [];

    e.map((item: any) => values.push(item.value));
    setPositionSecondary(values.toString());
    store.dispatch({
      type: PLAYER_FILTER_ADD_PARAM,
      payload: {
        secondary_positions: values,
      },
    });
  };
  const reformattedPositionData = data?.result.map(
    (data: { code: string; name: string }) => {
      return {
        itemID: data.code,
        itemDesc: data.name,
        itemImg: null,
      };
    }
  );
  const clearFilters = () => {
    setSelectValue(null);
  };

  useEffect(() => {
    if (reformattedPositionData) {
      let actualValue = reformattedPositionData.filter(
        (item: { itemID: number }) => item.itemID === positionSecondary
      );
      if (actualValue.length > 0) setSelectValue(actualValue[0]);
      else setSelectValue([]);
    }
  }, [positionSecondary]);
  return (
    <div>
      <>
        <WizSelect
          items={reformattedPositionData}
          changeSelect={(e) => setFilter(e)}
          placeHolder={"Posición Secundaria "}
          clearSelect={() => clearFilters()}
          value={selectValue}
          isMultiple={true}
        />
        {!selectValue && (
          <label className="animate_animated animate_fadeInLeft text-red-400 text-sm">
            Posición tiene que ser seleccionada{" "}
          </label>
        )}
      </>
    </div>
  );
}

export default SecondaryPositionSelect;
