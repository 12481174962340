import { SetStateAction, useEffect, useState } from "react";

//LOCALS
import { PLAYER_FILTER_ADD_PARAM } from "../../redux/constants/playerConstants";
import { store } from "../../redux/store";
import { positions } from "../../types/positions";
import WizSelect from "./WizSelect";

function PositionFilter() {
  const [positionID, setPositionID] = useState<SetStateAction<any>>(1);
  const [selectValue, setSelectValue] = useState<SetStateAction<any>>(null);

  const setFilter = (e: any) => {
    setPositionID(e.value);
    store.dispatch({
      type: PLAYER_FILTER_ADD_PARAM,
      payload: {
        position_id: e,
      },
    });
  };
  const reformattedPositionData = positions.primaryPositions.map((data) => {
    return {
      itemID: data.position_id,
      itemDesc: data.position_name,
      itemImg: null,
    };
  });

  useEffect(() => {
    if (reformattedPositionData) {
      let actualValue = reformattedPositionData.filter(
        (item) => item.itemID === positionID
      );
      if (actualValue.length > 0) setSelectValue(actualValue[0]);
      else setSelectValue([]);
    }
  }, [positionID]);
  return (
    <div>
      <>
        <WizSelect
          items={reformattedPositionData}
          changeSelect={(e) => setFilter(e)}
          placeHolder={" Posición Primaria "}
          isMultiple={false}
          value={selectValue}
        />
        {!selectValue && (
          <label className="animate_animated animate_fadeInLeft text-red-400 text-sm">
            Posición tiene que ser seleccionada{" "}
          </label>
        )}
      </>
    </div>
  );
}

export default PositionFilter;
