import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { ButtonPencil } from "../button/ButtonPencil";
import PlayerNameForm from "../Forms/NameForm/PlayerNameForm";
import { Loader } from "../Loader";
import WizModal from "../WizModal";

interface Props {
  data: {
    name: string;
    surname: string;
    id:string;
  };
  isLoading: boolean;
  refetch: () => void;
}

function CoachName({ data, refetch, isLoading }: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [formValues, setFormValues] = useState<{
    name: string;
    surname: string;
  }>();
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [editNameResponse, setEditNameResponse] =
    useState<SetStateAction<any>>();
  const [showName, setShowName] = useState<SetStateAction<any>>();

  const route = useNavigate();
  const { mutate: updateName } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("coach_id", data.id);
      formData.append("name", formValues?.name!);
      formData.append("surname", formValues?.surname!);

      return axios.post(`${BASE_URL}coaches` , formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      } );
    },
    {
      onSuccess: async (res) => {

        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setEditNameResponse(result);
        refetch();
      },
      onError: (err: any) => {
        setEditNameResponse(err.response?.data || err);
      },
    } 
  );

  //UPDATE NAME
  const addName = () => {
    try {
      updateName();
    } catch (err) {
      console.log(err);
    }
  };

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addName();
    }
  };
  if (!isLoading)
    return (
      <div>
        <div className="relative group" style={{textAlign:'center'}}>
        <ButtonPencil onClick={() => setShowName(!showName)} />
          <h1 className="text-4xl font-bold">
            {data?.name} {data?.surname}
          </h1>

        </div>

        <WizModal
          modalID="name-update"
          modalTitle={"Cambiar Nombre"}
          modalOpen={showName}
          setOpenModal={() => setShowName(false)}
          modalType={"DESITION"}
          modalWhithDelay={2000}
          onModalClose={(desition) => showAnimation(desition, 2000)}
        >
          <div>
            <PlayerNameForm
              data={data}
              onSubmit={(data) => setFormValues(data)}
            />
          </div>
        </WizModal>
      </div>
    );
  else {
    return <Loader />;
  }
}

export default CoachName;
