type PrimaryPositions = {
    position_id: number;
    position_name: string
}

type SecondaryPositions = {
    code: string;
    name: string
}

interface Positions {
    primaryPositions: PrimaryPositions[];
    secondaryPositions: SecondaryPositions[]
}


export const positions: Positions = {

    primaryPositions: [

        {
            position_id: 1,
            position_name: "Portero"
        },
        {
            position_id: 2,
            position_name: "Defensa"
        },
        {
            position_id: 3,
            position_name: "Centrocampista"
        },
        {
            position_id: 4,
            position_name: "Delantero"
        },
    ],
    secondaryPositions: [
        { code: 'CAM', name: 'Centrocampista ofensivo' },
        { code: 'CDM', name: 'Centrocampista defensivo' },
        { code: 'GK', name: 'Portero' },
        { code: 'LCB', name: 'Defensor central izquierdo' },
        { code: 'LW', name: 'Lateral izquierdo' },
        { code: 'LB', name: 'Lateral izquierdo Trasero' },
        { code: 'CM', name: 'Centrocampista' },
        { code: 'RCB', name: 'Defensor central derecho' },
        { code: 'RW', name: 'Lateral derecho' },
        { code: 'RB', name: 'Lateral derecho trasero' },
        { code: 'CF', name: 'Second Forward' },
        { code: 'ST', name: 'Delantero' }
    ]
}

