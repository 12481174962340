import React from "react";
//IMAGES
import LoginForm from "../components/MyLoginForm";
import { LoginPageStyles } from "../styles/LoginPageStyles";
import Footer from "../components/Footer";

// STYLES

const Login = () => {
  return (
    <LoginPageStyles>
      <section className="flex  justify-center h-screen items-center">
        <div className="flex flex-col xl:w-1/2 w-full items-center">
          <LoginForm />
          <Footer />
        </div>
      </section>
    </LoginPageStyles>
  );
};

export default Login;
