import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { Loader } from "../Loader";

interface Values {
  club: string;
  season: string;
  division: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

const classNames = {
  primary: "border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]",
};

export const AddCareerForm = ({ onSubmit, update, isLoading }: Props) => {
  if (!isLoading)
    return (
      <div>
        <Formik<{
          club: string;
          season: string;
          division: string;
        }>
          initialValues={{
            club: "",
            season: "",
            division: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            update();
          }}
        >
          {({ values, handleChange, handleBlur, submitForm, isSubmitting }) => (
            <Form>
              <div className="flex flex-col justify-between space-y-4">
                <div className="flex justify-between">
                  <label>Equipo Actual</label>
                  <input
                    className={`${classNames.primary}`}
                    type="text"
                    name="club"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.club}
                  />
                </div>
                <br />
                <div className="flex justify-between">
                  <label>Temporadas</label>
                  <input
                    className={`${classNames.primary}`}
                    type="text"
                    name="season"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.season}
                  />
                </div>
                <br />
                <div className="flex justify-between">
                <label>Division</label>
                <input
                  className={`${classNames.primary}`}
                  type="text"
                  name="division"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.division}
                />
                </div>
                <br />
              <Button disabled={isSubmitting} type="submit">
                Añadir Carrera
              </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  else {
    return <Loader />;
  }
};
