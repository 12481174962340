import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { Loader } from "../../Loader";

interface Data {
  defending: string;
  dribbling: string;
  pace: string;
  passing: string;
  physicality: string;
  shooting: string;
}

interface Values {
  defending: string;
  dribbling: string;
  pace: string;
  passing: string;
  physicality: string;
  shooting: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  data: Data[];
  update: any;
  isLoading: boolean;
}

const classNames = {
  primary: "border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]",
};

export const PerformanceForm: React.FC<Props> = ({
  onSubmit,
  data,
  update,
  isLoading,
}) => {
  if (!isLoading)
    return (
      <Formik
        initialValues={{
          dribbling: data?.[0].dribbling,
          defending: data?.[0].defending,
          pace: data?.[0].pace,
          passing: data?.[0].passing,
          shooting: data?.[0].shooting,
          physicality: data?.[0].physicality,
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          update();
        }}
      >
        {({ values, handleChange, handleBlur, submitForm, isSubmitting }) => (
          <Form>
            {isSubmitting ? (
              <Loader />
            ) : (
              <div className="flex flex-col justify-between">
                <div className="flex">
                  <div className="flex flex-col">
                    <label>Ritmo</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="pace"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pace}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label>Defensas</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="defending"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.defending}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col">
                    <label>Tiros</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="shooting"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shooting}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label>Regateos</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="dribbling"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dribbling}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col">
                    <label>Pases</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="passing"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passing}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label>Físico</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="physicality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.physicality}
                    />
                  </div>
                </div>
              </div>
            )}
            <button   style={{marginTop:'12px'}}     className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " disabled={isSubmitting} type="submit">
              Cambiar
            </button>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
