import { Form, Formik, Field } from "formik";
import * as React from "react";

//ICONS
import { HiOutlineCamera } from "react-icons/hi";
import { Loader } from "../../Loader";

interface Values {
  image: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  setImageUrlPlayer: React.SetStateAction<any>;
  isAdded: boolean;
  imageUrlPlayer: any;
  setIsAdded: React.SetStateAction<any>;
  update: any;
  isLoading: boolean;
}

export const AddImagesProfileForm: React.FC<Props> = ({
  onSubmit,
  setImageUrlPlayer,
  imageUrlPlayer,
  isAdded,
  setIsAdded,
  update,
  isLoading,
}) => {
  const [picturePreview, setPicturePreview] = React.useState<any>();
  const inputPicture = React.useRef<any>();

  const handleInputPictureForm = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const result = (e.target as HTMLInputElement).files;

    if (result![0]?.size < 2e6) {
      setImageUrlPlayer(result![0]);

      var file = result![0];
      setPicturePreview(URL?.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setImageUrlPlayer(null);
    setPicturePreview(null);
    setIsAdded(false);
  };
  const handleInputPictureClick = () => {
    inputPicture.current?.click();
  };

  if (!isLoading)
    return (
      <Formik
        initialValues={{
          image: "",
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          update();
          resetForm();
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4">
              {picturePreview && (
                <div
                  onClick={removeImage}
                  className="flex flex-col items-end filter hover:brightness-110 transition duration-150 transform hover:scale-105 cursor-pointer  p-4 animate__animated animate__slideInDown "
                >
                  <img
                    className="h-32 w-32  object-cover -mb-4 rounded-full "
                    src={picturePreview}
                    alt={picturePreview}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                    }}
                  />
                </div>
              )}
              <div
                className="btn btn-square btn-sm opacity-80 cursor-pointer absolute top-14 right-2"
                onClick={() => handleInputPictureClick()}
              >
                <HiOutlineCamera />
                <input
                  ref={inputPicture}
                  type="file"
                  placeholder="Open a image"
                  accept="image/png"
                  className="hidden"
                  name="image"
                  onChange={handleInputPictureForm}
                />
              </div>
              {imageUrlPlayer !== null && imageUrlPlayer !== undefined && (
                <button 
                className="flex items-center space-x-4 border shadow px-3 py-2 rounded-lg  bg-lime-400 hover:bg-lime-500  transition-all transform duration-150 ease-in-out"
                disabled={isLoading} type="submit">
                  Añadir Imagen Perfil
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
