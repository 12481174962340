import { Player } from "@lottiefiles/react-lottie-player";

// LOCALS
import deleteConfirm from "../../assets/animations/deleteConfirm.json";

function DeleteConfirm({ title }: any) {
  return (
    <div>
      <Player
        autoplay
        loop
        src={deleteConfirm}
        style={{ height: "15vh", width: "15vh" }}
        speed={1}
      ></Player>
      <p className="text-center font-bold">
        ¿Quieres eliminar {title}?
      </p>
    </div>
  );
}

export default DeleteConfirm;
