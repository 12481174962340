import React from "react";
import { VERSION } from "../version";

function Footer() {
  return (
    <footer className="p-4 w-full fixed left-0 bottom-0 bg-white rounded-lg shadow md:flex md:items-center md:p-6">
      <div className="mx-auto w-1000  md:flex md:justify-between">
        <ul className="flex justify-center mt-3 text-sm text-gray-500 dark:text-gray-400">
          <li>
          <span className="text-sm text-gray-500 dark:text-gray-400 mr-20">
          © 2022{" "}
          <a href="https://elitesoccer18.com/" className="hover:underline">
            Poxo™
          </a>
          . All Rights Reserved.
        </span>
          </li>
          <li className="mr-4">
            <a href="#" className="hover:underline">
              About
            </a>
          </li>
          <li className="mr-4">
            <a href="#" className="hover:underline">
              Privacy Policy
            </a>
          </li>
          <li className="mr-4">
            <a href="#" className="hover:underline">
              Licensing
            </a>
          </li>
          <li>
            <a href="#" className="hover:underline">
              {VERSION}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
