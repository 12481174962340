import { Form, Formik } from "formik";
import * as React from "react";
import { Loader } from "../../Loader";

interface Values {
  club?: string;
  season?: string;
  goals?: string;
  assists?: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  item: string;
  data: any;
  update: any;
  isLoading: boolean;
}

interface Career {
  player_id: string;
  club: string;
  season: string;
  goals: string;
  assist: string;
}

const classNames = {
  primary: "border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]",
};

export const EditYouthCareerForm = ({
  onSubmit,
  item,
  data,
  update,
  isLoading,
}: Props) => {
  if (data && !isLoading)
    return (
      <div>
        <h1 className="font-bold">ID: {item}</h1>

        <Formik
          initialValues={{
            club: data?.[0]?.club,
            season: data?.[0]?.season,
            goals: data?.[0]?.goals,
            assists: data?.[0]?.assist,
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            update();
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              {isSubmitting ? (
                <Loader />
              ) : (
                <div className="flex flex-col justify-between space-y-4">
                  <div className="flex justify-between">
                    <div className="flex flex-col items-start">
                      <label>Equipo Actual</label>
                      <input
                        className={`${classNames.primary}`}
                        type="text"
                        name="club"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={data?.[0]?.club}
                        value={values.club}
                      />
                    </div>
                    <div className="flex flex-col items-start">
                      <label>Temporadas</label>
                      <input
                        className={`${classNames.primary}`}
                        type="text"
                        name="season"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={data?.[0]?.season}
                        value={values.season}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col items-start">
                      <label>Goles</label>
                      <input
                        className={`${classNames.primary}`}
                        type="text"
                        name="goals"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={data?.[0]?.goals}
                        value={values.goals}
                      />
                    </div>
                    <div className="flex flex-col items-start">
                      <label>Asistencias</label>
                      <input
                        className={`${classNames.primary}`}
                        type="text"
                        name="assists"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={data?.[0]?.assist}
                        value={values.assists}
                      />
                    </div>
                  </div>
                </div>
              )}

              <button style={{marginTop:'12px'}} className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " disabled={isSubmitting} type="submit">
                Actualizar Carrera
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  else {
    return <Loader />;
  }
};
