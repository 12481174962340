import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import * as React from "react";

//LOCALS
import { BASE_URL } from "../../../api";
import useLocalStorage from "../../../hooks/useLocalStorage";
import toast from "../../Toast";

//LOCALS
import { MyField } from "../../MyField";
import PlayersFilter from "../../select/PlayersFilter";
import { useAppSelector } from "../../../redux/hooks";
import { Loader } from "../../Loader";

interface Values {
  title: string;
  text: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  itemNews: string;
  rowsId: string;
  refreshData: () => void;
  update: any;
}

function EditNewsForm({
  onSubmit,
  itemNews,
  rowsId,
  refreshData,
  update,
}: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [updateRowNew, setUpdateRowNew] = React.useState({
    text: "",
    title: "",
  });
  const [updatedNewResponse, setUpdatedNewReposen] =
    React.useState<React.SetStateAction<any>>();
  const filters = useAppSelector((state) => state.player);
  const getNewsByIdCD = async () => {
    if (rowsId === undefined) {
      return null;
    }
    const { data } = await axios.get(`${BASE_URL}news`, {
      
      params: {
        news_id: rowsId ? rowsId : "0",
        country_code: itemNews ? itemNews : "ES",
        
      },    
    });
    return data.result;
  };
  const { data: newsRowCD, isLoading } = useQuery(
    ["newsByIdCD", itemNews],
    getNewsByIdCD,
    {
      enabled: rowsId !== undefined || rowsId !== "",
    }
    );
    
    const { mutate: updateRowNews } = useMutation(
      async () => {
        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("country_code", itemNews);
        formData.append("news_id", rowsId);
        formData.append("text", updateRowNew.text);
        formData.append("title", updateRowNew.title);
        formData.append("player_list", filters.filters.players_id);
        
        return axios.post(`${BASE_URL}news`, formData, {
          headers: {
            Authorization: "Bearer " + uid,
          },
        });
      },
      {
        onSuccess: async (res) => {
          const result = {
            status: res.status + "-" + res.statusText,
            headers: res.headers,
            data: res.data,
          };
          setUpdatedNewReposen(result);
        },
        onError: (err: any) => {
          setUpdatedNewReposen(err.response?.data || err);
        },
      }
      );
      //TOAST
      const notify = React.useCallback((type: string, message: string) => {
        toast({ type, message });
      }, []);

      const dismiss = React.useCallback(() => {
        toast.dismiss();
      }, []);

      //UPDATE BIO RESPONSE
      React.useEffect(() => {
        if (updatedNewResponse?.data?.status === "ok") {
          notify(
            "success",
            `Noticia Actualizada ${updatedNewResponse.data.result.playerNewsDeleted} Jugadores Borrados ${updatedNewResponse.data.result.playerNewsInserted} `
            );
            
            setTimeout(() => {
              dismiss();
              refreshData();
            }, 1500);
          } else if (updatedNewResponse?.data?.status === "error") {
            notify("error", `${updatedNewResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedNewResponse]);

  if (!isLoading)
    return (
      <div>
        <div className="flex space-x-4">
          <h1>Id {newsRowCD?.[0]?.news_id}</h1>
          <h1>Idioma {newsRowCD?.[0]?.country_code}</h1>
        </div>
        <Formik
          initialValues={{
            title: newsRowCD?.[0]?.title,
            text: newsRowCD?.[0]?.text,
          }}
          onSubmit={(values, { resetForm }) => {
            setUpdateRowNew({
              text: values.text,
              title: values.title,
            });
            onSubmit(values);
            update();
            if (updateRowNew.text !== "" && updateRowNew.title !== "") {
              updateRowNews();
            }
          }}
        >
          {({ values, handleBlur, handleChange, submitForm }) => (
            <Form>
              <div className="flex flex-col justify-between space-y-4">
                <PlayersFilter />

                <div>
                  <Field
                    name="title"
                    placeholder={newsRowCD?.[0]?.title}
                    component={MyField}
                  />
                </div>
                <div>
                  <textarea
                    className="w-full h-40 px-4 py-2 border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="text"
                    placeholder={newsRowCD?.[0]?.text}
                    value={values.text}
                  />
                </div>
              </div>
              <button         className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " type="submit" onClick={submitForm}>
                Actualizar
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  else {
    return <Loader />;
  }
}

export default EditNewsForm;
