import React, { SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import SearchBar from "material-ui-search-bar";
import axios from "axios";
import FormData from "form-data";
import { visuallyHidden } from "@mui/utils";

//ICONS
import { FaUser } from "react-icons/fa";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import PlusIcon from "@mui/icons-material/Add";

//LOCALS
import toast from "./Toast";
import WizModal from "./WizModal";
import wizTimeOutFun from "../utils/WizTimeOutFun";
import DeletedItem from "./Errors/DeletedItem";
import DeleteConfirm from "./Errors/DeleteConfirm";
import { MyForm } from "./MyForm";
import { BASE_URL, BASE_URL_IMG } from "../api";
import { useAppSelector } from "../redux/hooks";
import useLocalStorage from "../hooks/useLocalStorage";
import { useAtom } from "jotai";
import { selectedType } from "./jotai/jotaiAtom";

//IMAGES
import image from "../assets/images/shadow.png";
import { players } from "../types/data";
import { useNavigate } from "react-router-dom";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Preview (Perfil)",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Id (Jugador)",
  },
  {
    id: "nacionalidad",
    numeric: false,
    disablePadding: false,
    label: "Nacionalidad",
  },
  {
    id: "birthdate",
    numeric: false,
    disablePadding: false,
    label: "Fecha de Nacimiento",
  },
  {
    id: "height",
    numeric: false,
    disablePadding: false,
    label: "Altura / Peso",
  },
  {
    id: "position",
    numeric: false,
    disablePadding: false,
    label: "Posición",
  },
  {
    id: "current_team_name",
    numeric: false,
    disablePadding: false,
    label: "Category / Equipo Actual",
  },
];

interface Data {
  id: string;
  birthdate: string;
  category: string;
  current_team_name: string;
  current_team: string;
  flag: string;
  foot: string;
  height: string;
  img_profile_url: string;
  jersey_nro: string;
  map_position: string;
  image: string;
  nacionalidad: string;
  previous_team: string;
  name: string;
  position: string;
  position_name: string;
  surname: string;
  weight: string;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

export function MyTable({ data }: any) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const route = useNavigate();
  const [rows, setRows] = useState<players[]>(data);
  const [animationParent] = useAutoAnimate<any>();
  const [openDeletePlayerModal, setOpenDeletePlayerModal] = useState(false);
  const [openAddPlayerModal, setOpenAddPlayerModal] = useState(false);
  const filters = useAppSelector((state) => state.player);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [itemDeleted, setItemDeleted] = useState(null);
  const [searched, setSearched] = useState<string>("");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectFoot, setSelectFoot] = useState<any>();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [playerData, setPlayerData] = useState<{
    category: string;
    current_team_name: string;
    current_team: string;
    jersey_nro: string;
    weight: string;
    height: string;
    firstName: string;
    lastName: string;
    birthdate: string;
  }>({
    firstName: "",
    lastName: "",
    birthdate: "",
    category: "",
    current_team_name: "",
    current_team: "",
    height: "",
    jersey_nro: "",
    weight: "",
  });
  
  const [responsePlayer, setResponsePlayer] = useState<SetStateAction<any>>();
  //JOTAI
  const [selectType, setSelectType] = useAtom(selectedType);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setRows(data);
  }, [data]);

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort, numSelected } = props;

    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead >
        <TableRow  >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}

            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const navigate = useNavigate();

  //AÑADIR JUGADOR
  const { isLoading, mutate } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("name", playerData?.firstName);
      formData.append("surname", playerData?.lastName);
      formData.append("birthdate", playerData.birthdate);
      formData.append("foot", selectFoot?.value);
      formData.append("category", playerData.category);
      formData.append("current_team", playerData.current_team);
      formData.append("jersey_nro", playerData.jersey_nro);
      formData.append("weight", playerData.weight);
      formData.append("height", playerData.height);
      formData.append("nationality_code", filters?.filters.nationality);
      formData.append(
        "second_nationality_code",
        filters.filters.second_nationality
      );
      formData.append("position_id", filters.filters.position_id?.value);
      return await axios.post(`${BASE_URL}players`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };

        setResponsePlayer(result);
        setOpenAddPlayerModal(false);
      },
      onError: (err: any) => {
        setResponsePlayer(err.response?.data || err);
      },
    }
  );

  const requestSearch = (searchVal: string) => {
    const filteredRows = data.filter((row: any) => {
      return row.name.toLowerCase().includes(searchVal.toLowerCase());
    });

    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const deletePlayer = (id: any) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const showHiddenDeleteComponent = (item: any) => {
    setOpenDeletePlayerModal(true);
    setItemDeleted(item);
  };

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deletePlayer(itemDeleted);
    }
  };
  const showAnimationNd = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addPlayer();
    }
  };

  //REFRESH PAGE
  const refreshPage = () => {
    window.location.reload();
  };

  const addPlayer = () => {
    //ADD PLAYER
    try {
      if (playerData.firstName !== "" && playerData.lastName !== "") {
        mutate();
      }
    } catch (err) {
      setResponsePlayer(err);
    }
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);
  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //PLAYER CREATED RESPONSE
  React.useEffect(() => {
    if (responsePlayer?.data?.result.playerProfileAdded === 1) {
      notify("success", `Jugador añadido`);

      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (responsePlayer?.data?.status === "error") {
      notify("error", `${responsePlayer?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [responsePlayer]);

  return (
    <Box sx={{height:"1200px", width: "100%" }}>
      <div className="mt-20" style={{ display: "flex", justifyContent:'space-between'}}>
        <div style={{ width: "500px" }}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder={"Buscar jugador"}

          />
        </div>

            <button 
            onClick={() => setOpenAddPlayerModal(!openAddPlayerModal)}
            className="py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg   bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800 flex items-center"
            >
              <h1 className="font-sans font-bold">Agregar Jugador</h1>
          <PlusIcon />
        </button>

      </div>
      <Paper sx={{ width: "100%" }}>
        <WizModal
          modalID="player-delete"
          modalTitle={"Borrar Jugador"}
          modalOpen={openDeletePlayerModal}
          setOpenModal={(e) => setOpenDeletePlayerModal(false)}
          modalType={"DESITION"}
          modalWhithDelay={2000}
          onModalClose={(desition) => showAnimation(desition, 2000)}
        >
          <div>
            {showDeleteAnimation === "YES" ? (
              <DeletedItem />
            ) : (
              <DeleteConfirm />
            )}
          </div>
        </WizModal>
        {openAddPlayerModal && (
          <WizModal
            modalID="player-add"
            modalTitle={"Añadir Jugador"}
            modalOpen={openAddPlayerModal}
            setOpenModal={(e) => setOpenAddPlayerModal(false)}
            modalType={"NONE"}
            modalWhithDelay={2000}
            onModalClose={(desition) => showAnimationNd(desition, 2000)}
          >
            <div>
              <MyForm
                onSubmit={(data) => setPlayerData(data)}
                selectFoot={selectFoot}
                setSelectFoot={setSelectFoot}
                isLoading={isLoading}
                update={() => showAnimationNd("YES", 2000)}
              />
            </div>
          </WizModal>
        )}
        <TableContainer
          sx={{
            marginTop: 2,
            maxHeight: 840,
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "cyan",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "lightblue",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ width: 1200 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />

            <TableBody ref={animationParent}>
              {stableSort(rows as any, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.id as any);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id as any)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        {row.img_profile_url === null ? (
                          <img
                            loading="lazy"
                            src={image}
                            alt=""
                            width={100}
                            height={100}
                            className="w-20  object-cover rounded-full"
                            
                          />
                        ) : (
                          <img
                            width={100}
                            height={100}
                            src={BASE_URL_IMG + row.img_profile_url}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; //prevents looping
                              currentTarget.src = image;
                            }}
                            className="w-16 h-16 object-cover  rounded-full"
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name} {row.surname}
                      </TableCell>
                      <TableCell>{row.nacionalidad}</TableCell>
                      <TableCell>{row.birthdate}</TableCell>
                      <TableCell>
                        {row.height}cm /{row.weight}kg
                      </TableCell>
                      <TableCell>
                        {row.position_name} {row.map_position}{" "}
                      </TableCell>
                      <TableCell>
                        {row.category} / {row.current_team_name}{" "}
                      </TableCell>
                      <TableCell>{row.previous_team}</TableCell>
                      <TableCell>
                        <div className="flex items-center">
                          <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                            <EditIcon
                              onClick={() => navigate(`/player/${row.id}`)}
                            />
                          </div>
                          <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                            <TrashIcon
                              onClick={() => showHiddenDeleteComponent(row.id)}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
