import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import News from "../pages/News";
import { Offline } from "../pages/Offline";
import Players from "../pages/Player";
import Coach from "../pages/Coach";
import Teams from "../pages/Teams";

export const Router = () => {
  if (!navigator.onLine) return <Offline />;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/login" element={<Login />} />
        <Route path="/player/:id" element={<Players />} />
        <Route path="/coach/:id" element={<Coach />} />
        <Route path="/teams" element={<Teams />} />
      </Routes>
    </BrowserRouter>
  );
};
