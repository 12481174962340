import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

// LOCALS
import offLine from "../assets/animations/offLine.json";

// STYLES

export const Offline = () => {
  return (
    <div className="h-screen w-screen bg-gray-100 flex items-center">
      <div className="container flex md:flex-row items-center justify-center px-5 text-gray-700">
        <div className="max-w-md">
          <div className="text-6xl font-dark font-bold">No connection</div>
          <p className="text-2xl md:text-3xl font-light leading-normal">
            Sorry, we couldn't connect to our servers
          </p>
          <p className="mb-8">No te preocupes</p>
          <button
            className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue btn"
            onClick={() => {
              window.location.reload();
            }}
          >
            Intenta de Nuevo
          </button>
        </div>
        <div>
          <Player
            autoplay
            loop
            src={offLine}
            style={{ height: "50vh", width: "50vh" }}
            speed={0.7}
          ></Player>
        </div>
      </div>
    </div>
  );
};
