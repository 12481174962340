import { Form, Formik } from "formik";
import * as React from "react";
import { Loader } from "../Loader";

interface Values {
  club: string;
  season: string;
  division: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  item: string;
  data: any;
  update: any;
  isLoading: boolean;
}

interface Career {
  player_id: string;
  club: string;
  season: string;
  division: string;
}

const classNames = {
  primary: "border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]",
};

const storedClub = localStorage.getItem('club');
const storedSeason = localStorage.getItem('season');
const storedDivision = localStorage.getItem('division');
export const EditCareerForm = ({
  onSubmit,
  item,
  data,
  update,
  isLoading,
  
}: Props) => {
  if (isLoading) return <Loader />;
  return (
    <div>
      <h1>{item}</h1>

      <Formik
        initialValues={{
          club: storedClub || data?.[0]?.club || "",
          season: storedSeason || data?.[0]?.season || "",
          division: storedDivision || data?.[0]?.division || "",
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          update();
        }}
      >
        {({ values, handleChange, handleBlur, submitForm, isSubmitting }) => (
          <Form>
              <div className="flex flex-col justify-between space-y-4">
              <div className="flex justify-between">
                  <label>Equipo Actual</label>
                  <input
                    className={`${classNames.primary}`}
                    type="text"
                    name="club"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.club}
                  />
                </div>
                <div className="flex justify-between">
                  <label>Temporadas</label>
                  <input
                    className={`${classNames.primary}`}
                    type="text"
                    name="season"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.season}
                  />
                </div>
                <div className="flex justify-between">
                  <label>Division</label>
                  <input
                    className={`${classNames.primary}`}
                    type="text"
                    name="division"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.division}
                  />
              </div>
              <br />
            <button className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " disabled={isSubmitting} type="submit">
              Actualizar Carrera
            </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
