import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//LOCALS
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppSelector } from "../../redux/hooks";
import { Player } from "../../types/data";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { ButtonPencil } from "../button/ButtonPencil";
import EditPlayerModal from "../EditPlayer/EditPlayerModal";
import toast from "../Toast";
import { teamID } from "../jotai/jotaiAtom";
import { useAtom } from "jotai";

interface Props {
  data: Player | undefined;
  setShowEditFirst: SetStateAction<any>;
  showEditFirst: boolean;
  setShowEditSecond: SetStateAction<any>;
  showEditSecond: boolean;
  setShowEditThird: SetStateAction<any>;
  showEditThird: boolean;
  refreshData: () => void;
  refetch: () => void;
}

function CoachData({
  data,
  setShowEditFirst,
  showEditFirst,
  refreshData,
  refetch,
}: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const route = useNavigate();
  const params = useParams();
  const playerId = params.id as any;
  const filters = useAppSelector((state) => state.player);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [updatedPositionResponse, setUpdatedPositionResponse] =
    useState<SetStateAction<any>>();
  const [updatedFirstModalResponse, setUpdatedFirstModalResponse] =
    useState<SetStateAction<any>>();

  //FIRST MODAL STATE
  const [formValuesSt, setFormValuesSt] = useState<{
    birthdate?: string;
    actual_team?: string;
  }>({
    birthdate: data?.data?.birthdate,
    actual_team: data?.data?.current_team,
  });

  const [teamsData, setteamsData] = useState<any>([]);
  const [matchedTeams, setMatchedTeams] = useState<any>([]);
  const [team_ID, setTeam_ID] = useAtom(teamID);
  
  const getTeams = async () => {
    const response = await axios.get(`${BASE_URL}teams`);
    const teamData = response.data;

    const matchedTeam = teamsData.find(
      (team: { name: string; img_url: string }) =>
        data?.data?.current_team === team.name
        );
        setteamsData(teamData);
        setMatchedTeams(matchedTeam?.team_id)
      };

      useEffect(() => {
        getTeams();
      }, []); 

  //FIRST MODAL UPDATE
  const { mutate: updateMainDataCoach, isLoading: isLoadingMain } = useMutation(
      async () => {
        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("coach_id", playerId);
        formData.append("birthdate", formValuesSt?.birthdate as any);
        formData.append("nationality_code", filters?.filters.nationality);
        formData.append("current_team_id", team_ID);
        formData.append(
          "second_nationality_code",
          filters?.filters.second_nationality
        );
        formData.append("current_team", formValuesSt.actual_team as any);
/*         formData.append("category", formValuesSt.categoria as any); */

        return await axios.post(`${BASE_URL}coaches`, formData, {
          headers: {
            Authorization: "Bearer " + uid,
          },
          
        });
      },
      {
        onSuccess: async (res) => {
          const result = {
            status: res.status + "-" + res.statusText,
            headers: res.headers,
            data: res.data,
          };
          setUpdatedFirstModalResponse(result);
          refetch();
          getTeams();
        },
        onError: (err: any) => {
          setUpdatedFirstModalResponse(err.response?.data || err);
        },
      }
    );

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //FIRST MODAL RESPONSE
  useEffect(() => {
    if (updatedFirstModalResponse?.data?.status === "ok") {
      notify("success", `Coach Actualizado`);
      setTimeout(() => {
        dismiss();
        // refreshData();
        setShowEditFirst(false);
      }, 1000);
    } else if (updatedFirstModalResponse?.data?.status === "error") {
      notify("error", `${updatedFirstModalResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedFirstModalResponse]);


  const showAnimationSt = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (
      desition !== "NO" &&
      formValuesSt.actual_team !== undefined &&
      formValuesSt.birthdate !== undefined
    ) {
      updateMainDataCoach();
    }
  };


  const handleOpenEditFirst = () => {
    setShowEditFirst(true);
  
  }
  return (
    <div style={{width: '800px' }}>
    {showEditFirst && (
      <EditPlayerModal
        data={data?.data}
        modalID="coach-add"
        modalTitle="Actualizar Datos"
        modalOpen={showEditFirst}
        setOpenModal={() => setShowEditFirst(false)}
        modalWhithDelay={2000}
        onModalClose={(decision: string) => showAnimationSt(decision, 2000)}
        setFormValues={setFormValuesSt}
        isLoading={isLoadingMain}
        update={updateMainDataCoach}
      >
        <div></div>
      </EditPlayerModal>
    )}

    <div style={{width: '100%'}}>
      <h1 className="text-4xl font-bold mb-10">Datos</h1>
      <div >
        <div  className=" hover:bg-gray-400 w-40 hover:bg-opacity-20 rounded hover:shadow p-2 group transition-all transform duration-150 ease-in-out">
          <div className="group-hover:opacity-60">
            <label  style={{ whiteSpace: 'nowrap' }}  className="text-lg font-semibold">Fecha de Nacimiento</label>
            <h1>{data?.data.birthdate}</h1>
            <div className="flex items-center space-x-2">
              <div className="flex-col">
                <label className="text-lg font-semibold">Nacionalidad</label>
                <h1>{data?.data?.nacionalidad}</h1> 
              </div>
              {data?.data?.segunda_nacionalidad && (
                <div className="flex-col">
                  <label className="text-sm font-semibold">Segunda</label>
                  <h1>{data?.data?.segunda_nacionalidad}</h1>
                </div>
              )}
            </div>
            <label className="text-lg font-semibold">Equipo Actual</label>
            <h1>{data?.data?.current_team_name}</h1>
            <label className="text-lg font-semibold">Categoria</label>
            <h1>{data?.data?.category}</h1>
          </div>
          <ButtonPencil onClick={handleOpenEditFirst} />
        </div>
      </div>
    </div>
  </div>
  );
}
export default CoachData;

