import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

//LOCALS
import { AddGalleryForm } from "../Forms/GalleryForm/AddImagesForm";
import useLocalStorage from "../../hooks/useLocalStorage";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { BASE_URL, BASE_URL_IMG } from "../../api";
import WizModal from "../WizModal";
import toast from "../Toast";

//ICONS
import DeleteConfirm from "../Errors/DeleteConfirm";
import DeletedItem from "../Errors/DeletedItem";
import { Close } from "@mui/icons-material";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  data: string[];
  refreshData: () => void;
}

type Photos = {
  img_id: string;
  img_url: string;
};

function PlayerGallery({ data, refreshData }: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const router = useNavigate();
  const params = useParams();
  const playerId = params.id as any;

  //MODAL
  const [showAddImages, setShowAddImages] = useState<boolean>(false);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<string>>();

  const [imageUrlPlayer, setImageUrlPlayer] =
    useState<SetStateAction<any>>(false);
  const [imageSelected, setImageSelected] = useState<SetStateAction<any>>(null);

  //STATE RESPONSE
  const [addImageResponse, setAddImageResponse] =
    useState<SetStateAction<any>>();
  const [deleteImageResponse, setDeleteImageResponse] =
    useState<SetStateAction<any>>();
  const [openDeleteImagesModal, setOpenDeleteImagesModal] =
    useState<SetStateAction<any>>(false);

  const getPhotosGallery = async () => {
    const { data } = await axios.get(
      `${BASE_URL}player.galery?player_id=${playerId}`,
      {
        headers: {
          Authorization: "Bearer " + uid,
        },
      }
    );

    return data.result;
  };

  const {
    data: photoGallery,
    status: photoGalleryStatus,
    refetch,
  } = useQuery<Photos[]>(["galery"], getPhotosGallery);

  //ADD IMAGES GALLERY
  const { isLoading, mutate: addImagesGallery } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("player_id", playerId);
      formData.append("image", imageUrlPlayer);

      return await axios.post(`${BASE_URL}player.galery`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setAddImageResponse(result);
        refetch();
      },
      onError: (err: any) => {
        setAddImageResponse(err.response.data || err);
      },
    }
  );

  //DELETE IMAGES GALLERY
  const { isLoading: isImageDeletetingLoading, mutate: deleteImagesGallery } =
    useMutation(
      async () => {
        return await axios.delete(
          `${BASE_URL}player.galery?img_id=${imageSelected}`,
          {
            headers: {
              Authorization: "Bearer " + uid,
            },
          }
        );
      },
      {
        onSuccess: async (res) => {
          const result = {
            status: res.status + "-" + res.statusText,
            headers: res.headers,
            data: res.data,
          };
          setDeleteImageResponse(result);
          refetch();
        },
        onError: (err: any) => {
          setDeleteImageResponse(err.response.data || err);
        },
      }
    );

  //ADD IMAGES
  const addImages = () => {
    try {
      if (imageUrlPlayer) {
        addImagesGallery();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //DELETE IMAGES
  const deleteImage = () => {
    try {
      deleteImagesGallery();
    } catch (err) {
      console.log(err);
    }
  };

  //SHOW ANIMATION
  const showAnimation = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addImages();
    }
  };

  const showAnimationDelete = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deleteImage();
    }
  };

  //TOAST
  const notify = useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = useCallback(() => {
    toast.dismiss();
  }, []);

  //ADD IMAGE RESPONSE
  React.useEffect(() => {
    if (addImageResponse?.data?.result.playerGaleryInserted === 1) {
      notify("success", `Imagen Añadida`);

      setTimeout(() => {
        dismiss();
        setShowAddImages(false);
      }, 1000);
    } else if (addImageResponse?.data?.status === "error") {
      notify("error", `${addImageResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [addImageResponse]);

  //DELETED IMAGE RESPONSE
  useEffect(() => {
    if (deleteImageResponse?.data.result.playerGaleryImageDeleted === 1) {
      notify("success", `Imagen Eliminada`);

      setTimeout(() => {
        dismiss();
      }, 1000);
    } else if (deleteImageResponse?.data.status === "error") {
      notify("error", `${deleteImageResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deleteImageResponse]);

  useEffect(() => {
    console.log(imageUrlPlayer);
  }, [imageUrlPlayer]);

  return (
    <div>
      <WizModal
        modalID="gallery-edit"
        // modalType={"DESITION"}
        modalOpen={showAddImages}
        setOpenModal={(e) => setShowAddImages(false)}
        modalWhithDelay={2000}
        modalTitle="Añadir Nueva Imagen"
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <AddGalleryForm
            onSubmit={(data) => {
              console.log(data);
            }}
            isLoading={isLoading}
            update={() => showAnimation("YES", 2000)}
            setImageUrlPlayer={setImageUrlPlayer}
          />
        </div>
      </WizModal>

      <WizModal
        modalID="gallery-delete"
        modalTitle={"Borrar Imagen"}
        modalOpen={openDeleteImagesModal}
        setOpenModal={(e) => setOpenDeleteImagesModal(false)}
        modalType={"DESITION"}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationDelete(desition, 2000)}
      >
        <div>
          {showDeleteAnimation === "YES" ? (
            <DeletedItem title={"Esta Imagen"} />
          ) : (
            <DeleteConfirm title={"la Imagen"} />
          )}
        </div>
      </WizModal>
      <button
        onClick={() => setShowAddImages(!showAddImages)}
        className="flex mx-4 items-center space-x-4 border shadow px-4 py-2 rounded-lg bg-lime-400 hover:bg-lime-500  transition-all transform duration-150 ease-in-out"
      >
        Añadir Imágenes
        <Add />
      </button>
      <div className="grid grid-cols-2 lg:grid-cols-4   mt-10 gap-4">
        {photoGallery?.map((img) => (
          <div
            key={img.img_id}
            className="group mx-4 hover:scale-110 transition-all transform duration-150 ease-in-out cursor-pointer"
          >
            <img
              src={BASE_URL_IMG + img.img_url}
              className="w-40 object-cover hover:scale-110 transition-all transform duration-150 ease-in-out"
              alt="img"
            />
            <div
              onClick={() => {
                setImageSelected(img.img_id);
                setOpenDeleteImagesModal(!openDeleteImagesModal);
              }}
              className="hidden p-2 rounded-full bg-white group-hover:inline absolute top-0 right-2"
            >
              <Close />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlayerGallery;
