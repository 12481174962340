import { Form, Formik, Field } from "formik";
import * as React from "react";
import { Loader } from "../../Loader";

//LOCALS
import { MyField } from "../../MyField";

interface Values {
  title: string;
  text: string;
  date: string;
  country_code: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

export const AddNewsForm: React.FC<Props> = ({
  onSubmit,
  update,
  isLoading,
}) => {
  if (!isLoading)
    return (
      <Formik<{
        title: string;
        text: string;
        date: string;
        country_code: string;
      }>
        initialValues={{
          country_code: "",
          title: "",
          text: "",
          date: "",
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
          update();
          // resetForm();
          console.log(values);
        }}
      >
        {({ values, handleBlur, handleChange, isSubmitting, submitForm }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4 ">
              <div>
                <select
                  name="country_code"
                  id="country_code"
                  value={values.country_code}
                  onChange={handleChange}
                >
                  <option value="">Selecciona Idioma</option>
                  <option value="DE">DE</option>
                  <option value="CN">CN</option>
                  <option value="GB">GB</option>
                  <option value="FR">FR</option>
                  <option value="IT">IT</option>
                  <option value="PT">PT</option>
                  <option value="RU">RU</option>
                </select>
              </div>
              <div>
                <Field
                  name="title"
                  placeholder="Título de la Noticia"
                  component={MyField}
                />
              </div>
              <div>
                <Field
                  name="date"
                  placeholder="Fecha de la Noticia"
                  component={MyField}
                />
              </div>
              <div>
                <textarea
                  className="w-full h-40 px-4 py-2 border"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="text"
                  placeholder="Descripción de la noticia"
                  value={values.text}
                />
              </div>
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              onClick={submitForm}
              className="py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800 flex items-center"
            >
              Añadir Nueva Noticia
            </button>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
