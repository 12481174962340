import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SetStateAction, useEffect, useState } from "react";

//LOCALS
import { BASE_URL } from "../../api";

//LOCALS
import { PLAYER_FILTER_ADD_PARAM } from "../../redux/constants/playerConstants";
import { store } from "../../redux/store";
import { players } from "../../types/data";
import WizSelect from "./WizSelect";

const getPlayers = async () => {
  const { data } = await axios.get(`${BASE_URL}players?countryid=ES`);

  return data;
};

function PlayersFilter() {
  const [playerId, setPlayerId] = useState<any>(1);
  const [selectValue, setSelectValue] = useState<SetStateAction<any>>(null);
  const { data: playerData, status: statusPlayer } = useQuery<players[]>(
    ["players"],
    getPlayers
  );

  const setFilter = (e: any) => {
    let values: any[] = [];

    e.map((item: any) => values.push(item.value));

    console.log(e);
    setPlayerId(values.toString());
    store.dispatch({
      type: PLAYER_FILTER_ADD_PARAM,
      payload: {
        players_id: values,
      },
    });
  };
  const reformattedPositionData = playerData?.map((data) => {
    return {
      itemID: data.id,
      itemDesc: data.name,
      itemImg: null,
    };
  });
  const clearFilters = () => {};

  useEffect(() => {
    if (reformattedPositionData) {
      let actualValue = reformattedPositionData.filter(
        (item) => item.itemID === playerId
      );
      if (actualValue.length > 0) setSelectValue(actualValue[0]);
      else setSelectValue([]);
    }
  }, [playerId]);
  return (
    <div>
      <>
        <WizSelect
          items={reformattedPositionData}
          changeSelect={(e) => setFilter(e)}
          placeHolder={" Jugadores de la Noticia "}
          clearSelect={() => clearFilters()}
          value={selectValue}
          isMultiple
        />
        {!selectValue && (
          <label className="animate_animated animate_fadeInLeft text-red-400 text-sm">
            Posición tiene que ser seleccionada{" "}
          </label>
        )}
      </>
    </div>
  );
}

export default PlayersFilter;
