import axios, { AxiosRequestConfig } from "axios";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { MyField } from "../../MyField";

interface Values {
  time: string;
  url: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  // update: any;
}

export const AddVideoForm: React.FC<Props> = ({ onSubmit }) => {
  const [file, setFile] = React.useState<File | undefined>();
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  async function handleSubmit() {
    const data = new FormData();

    if (!file) return;

    setSubmitting(true);

    data.append("file", file);

    const config: AxiosRequestConfig = {
      onUploadProgress: function (progressEvent) {
        const percentComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setProgress(percentComplete);
      },
    };

    try {
      await axios.post("/api/videos", data, config);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setSubmitting(false);
      setProgress(0);
    }
  }

  function handleSetFile(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;

    if (files?.length) {
      setFile(files[0]);
    }
  }
  return (
    <div>
      <Formik<{ url: string; time: string }>
        initialValues={{ time: "", url: "" }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          // update();
          resetForm();
        }}
      >
        {({ values }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4">
              <div>
                <Field
                  name="url"
                  placeholder="Direccion Url del Video"
                  component={MyField}
                />
              </div>
              <div style={{ marginBottom: "19px" }}>
                <Field
                  name="time"
                  placeholder="Fecha del Video"
                  component={MyField}
                />
              </div>
            </div>
            <button
              className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
              type="submit"
            >
              Añadir Video
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
