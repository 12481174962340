import React, { SetStateAction, useEffect, useState } from "react";
import {useAtom} from 'jotai';
//LOCALS
import { BASE_URL, BASE_URL_IMG } from "../../api";
import { PLAYER_FILTER_ADD_PARAM } from "../../redux/constants/playerConstants";
import { store } from "../../redux/store";

//LOCALS
import WizSelect from "./WizSelect";
import { languageCode } from "../jotai/jotaiAtom";

const CountryFilter = () => {
  const [selectValue, setSelectValue] = useState<SetStateAction<any>>();
  const [countriesData, setCountriesData] = useState<SetStateAction<any>>();
  const [nationalities, setNationalities] = useState<SetStateAction<any>>();
  const [lang, setLangCode] = useAtom(languageCode)


  useEffect(() => { 

    setLangCode(selectValue)
    console.log(selectValue);

  },[selectValue])

  useEffect(() => { 
    countriesFetch();
  },[])
  
  
  const countriesFetch = () => {
    fetch(`${BASE_URL}countries`, {
      method: "GET",
    })

      .then((res) => res.json())
      .then((res) => setCountriesData(res))
      .catch((err) => console.log(err));
  };

  const nationalitiesData = countriesData?.map((data: any) => {
    return {
      itemID: data.country_code,
      itemDesc: data.name,
      itemImg: BASE_URL_IMG + data.country_flag,
      selected: data.selected === "true" ? true : false,
    };
  });

  const setFilter = (e: any) => {
    setNationalities(e.value);

    store.dispatch({
      type: PLAYER_FILTER_ADD_PARAM,
      payload: {
        nationality: e.value,
      },
    });
  };

  const clearFilters = () => {
    setSelectValue(null);
  };

  useEffect(() => {
    let actualValue = nationalitiesData?.filter(
      (item: any) => item.itemID === nationalities
    );
    if (actualValue?.length > 0) setSelectValue(actualValue[0]);
    else setSelectValue([]);
  }, [nationalities]);

  return countriesData?.length > 0 ? (
    <WizSelect
      items={nationalitiesData}
      changeSelect={(e) => setFilter(e)}
      placeHolder={"Selecciona la Nacionalidad"}
      clearSelect={() => clearFilters()}
      value={selectValue}
      isMultiple={false}
    />
  ) : (
    <div></div>
  );
};

export default CountryFilter;
