import styled from "styled-components";

export const LoginFormStyles = styled.div`
  display: flex;
  padding: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  width: 550px;
  @media (min-width: 768px) {
    width: 550px;
    max-width: 28rem;
  }
  @media (min-width: 1024px) {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 100%;
  }
  @media (min-width: 1280px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .loginButton {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1.5rem;
    background-color: #9ca3af;
    color: #ffffff;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: not-allowed;
  }
  .loginButtonActive {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1.5rem;
    background-color: #6366f1;
    color: #ffffff;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    :hover {
      background-color: rgb(129 140 248);
    }
  }
`;
