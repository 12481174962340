import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import FormData from "form-data";
import React, { SetStateAction, useEffect, useState } from "react";

//LOCALS
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppSelector } from "../../redux/hooks";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { ButtonPencil } from "../button/ButtonPencil";
import { AddBioForm } from "../Forms/BioForm/AddBioForm";
import BioModal from "../Modal/BioModal";
import WizModal from "../WizModal";
import toast from "../Toast";
import { EditBioForm } from "../Forms/BioForm/EditBioForm";
import { EditQuoteForm } from "../Forms/QuoteForm/EditQuoteForm";

//ICONS
import EditIcon from "@mui/icons-material/Edit";
import { Add } from "@mui/icons-material";
import TrashIcon from "@mui/icons-material/Delete";
import { AddQuoteForm } from "../Forms/QuoteForm/AddQuoteForm";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  data: {
    bio: string;
    quote: string;
  };
  refreshData: () => void;
};

type Bio = {
  player_id: string;
  country_code: string;
  bio: string;
};
type PlayerBio = Bio[];

type Quote = {
  player_id: string;
  country_code: string;
  quote: string;
};

type PlayerQuote = Quote[];

function PlayerBio({ data, refreshData }: Props) {
  const route = useNavigate();
  const [uid, setUid] = useLocalStorage("UID", "");
  const params = useParams();
  const playerId = params.id;
  const filters = useAppSelector((state) => state.player);
  const [addedCD, setAddedCD] = useState(false);
  const [addedQuoteCD, setAddedQuoteCD] = useState(false);
  const [quoteItemRow, setQuoteItemRow] = useState<SetStateAction<any>>("");
  const [itemRow, setItemRow] = useState<SetStateAction<any>>("");
  const [itemSelected, setItemSelected] = useState<SetStateAction<any>>(null);
  const [itemQuoteSelected, setItemQuoteSelected] =
    useState<SetStateAction<any>>(null);

  //MODAL
  const [openAddBioModal, setOpenAddBioModal] =
    useState<SetStateAction<any>>(false);
  const [openAddQuoteModal, setOpenAddQuoteModal] =
    useState<SetStateAction<any>>(false);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>(false);
  const [showBioModal, setShowBioModal] = useState<SetStateAction<any>>(false);
  const [showQuoteModal, setShowQuoteModal] =
    useState<SetStateAction<any>>(false);

  //STATE CREATE
  const [createBioResponse, setCreateBioResponse] =
    useState<SetStateAction<any>>();
  const [createQuoteResponse, setCreateQuoteResponse] =
    useState<SetStateAction<any>>();

  //STATE DELETE
  const [deleteBioResponse, setDeleteBioResponse] =
    useState<SetStateAction<any>>();
  const [deleteQuoteResponse, setDeleteQuoteResponse] =
    useState<SetStateAction<any>>();
  const [showOpenEditModal, setShowOpenEditModal] =
    useState<SetStateAction<any>>();
  //STATE FORM
  const [showOpenEditQuoteModal, setShowOpenEditQuoteModal] =
    useState<SetStateAction<any>>(false);
  const [bioFormData, setBioFormData] = useState<{ bio: string }>({ bio: "" });
  const [quoteFormData, setQuoteFormData] = useState<{ quote: string }>({
    quote: "",
  });

  const [listRef] = useAutoAnimate<HTMLDivElement>();

  //GET QUOTE
  const getQuotes = async () => {
    const { data } = await axios.get(`${BASE_URL}quote?player_id=${playerId}`);

    return data.result;
  };

  const { data: quoteData, isLoading: isQuoteLoading } = useQuery<PlayerQuote>(
    ["playerQuote"],
    getQuotes
  );

  //GET BIO
  const getBios = async () => {
    const { data } = await axios.get(`${BASE_URL}bio?player_id=${playerId}`);

    return data.result;
  };

  const { data: bioData, isLoading: isBioLoading } = useQuery<PlayerBio>(
    ["playerBio"],
    getBios
  );

  //AÑADIR BIO
  const { isLoading: isLoadingBio, mutate } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("player_id", playerId);
      formData.append("country_code", filters.filters.nationality);
      formData.append("bio_text", bioFormData?.bio);

      return await axios.post(`${BASE_URL}bio`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setCreateBioResponse(result);
      },
      onError: (err: any) => {
        setCreateBioResponse(err.response?.data || err);
      },
    }
  );
  const { mutate: mutateQuote, isLoading: isLoadingQuote } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("player_id", playerId);
      formData.append("country_code", filters.filters.nationality);
      formData.append("quote_text", quoteFormData?.quote!);

      return await axios.post(`${BASE_URL}quote`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setCreateQuoteResponse(result);
      },
      onError: (err: any) => {
        setCreateQuoteResponse(err.response?.data || err);
      },
    }
  );

  const addQuote = () => {
    //Add Quote
    try {
      if (quoteFormData?.quote !== "") mutateQuote();
    } catch (err) {
      console.log(err);
    }
  };

  const addBio = () => {
    //Add News
    try {
      if (bioFormData?.bio !== "") mutate();
    } catch (err) {
      console.log(err);
    }
  };

  //BORRAR BIO
  const { mutate: deleteRowBio } = useMutation(
    async () => {
      return await axios.delete(
        `${BASE_URL}bio?player_id=${playerId}&country_code=${itemRow}`,
        {
          headers: {
            Authorization: "Bearer " + uid,
          },
        }
      );
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setDeleteBioResponse(result);
      },
      onError: (err: any) => {
        setDeleteBioResponse(err.response?.data || err);
      },
    }
  );
  //BORRAR QUOTE
  const { mutate: deleteRowQuote } = useMutation(
    async () => {
      return await axios.delete(
        `${BASE_URL}quote?player_id=${playerId}&country_code=${quoteItemRow}`,
        {
          headers: {
            Authorization: "Bearer " + uid,
          },
        }
      );
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setDeleteQuoteResponse(result);
      },
      onError: (err: any) => {
        setDeleteQuoteResponse(err.response?.data || err);
      },
    }
  );

  useEffect(() => {
    try {
      if (addedCD) {
        deleteRowBio();
      }
    } catch (err) {
      console.log(err);
    }
  }, [addedCD]);

  useEffect(() => {
    try {
      if (addedQuoteCD) {
        deleteRowQuote();
      }
    } catch (err) {
      console.log(err);
    }
  }, [addedQuoteCD]);
  const deleteQuoteRow = () => {
    try {
      if (quoteItemRow !== "") {
        deleteRowQuote();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //ANIMATION

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addBio();
    }
  };
  const showAnimationQuote = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addQuote();
    }
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //CREATE BIO RESPONSE
  useEffect(() => {
    if (createBioResponse?.data?.result === 1) {
      notify("success", `Bio Añadida`);

      setTimeout(() => {
        dismiss();
        refreshData();
        setShowBioModal(false);
      }, 1000);
    } else if (createBioResponse?.data?.status === "error") {
      notify("error", `${createBioResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createBioResponse]);

  //CREATE QUOTE RESPONSE
  useEffect(() => {
    if (createQuoteResponse?.data?.result === 1) {
      notify("success", `Quote Añadida`);

      setTimeout(() => {
        dismiss();
        refreshData();
        setShowQuoteModal(false);
      }, 1000);
    } else if (createQuoteResponse?.data?.status === "error") {
      notify("error", `${createQuoteResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createQuoteResponse]);

  //DELETE BIO RESPONSE
  useEffect(() => {
    if (deleteBioResponse?.data.result === 1) {
      notify("success", `Bio Eliminada`);

      setTimeout(() => {
        dismiss();
        refreshData();
      }, 1000);
    } else if (deleteBioResponse?.data.status === "error") {
      notify("error", `${deleteBioResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deleteBioResponse]);

  //DELETE QUOTE RESPONSE
  useEffect(() => {
    if (deleteQuoteResponse?.data.result === 1) {
      notify("success", `Quote Eliminada`);

      setTimeout(() => {
        dismiss();
        refreshData();
      }, 1000);
    } else if (deleteQuoteResponse?.data.status === "error") {
      notify("error", `${deleteQuoteResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deleteQuoteResponse]);

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-5">
        <button
          onClick={() => setOpenAddBioModal(!openAddBioModal)}
          className="flex items-center bg-lime-400 hover:bg-lime-500 px-4  py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
        >
          Añadir Bio
          <Add />
        </button>
        <button
          onClick={() => setOpenAddQuoteModal(!openAddQuoteModal)}
          className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
        >
          Añadir Quote
          <Add />
        </button>
      </div>
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Bio"}
        modalOpen={openAddBioModal}
        setOpenModal={(e) => setOpenAddBioModal(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <AddBioForm
            isLoading={isLoadingBio}
            onSubmit={(data) => {
              setBioFormData(data);
            }}
            update={() => showAnimation("YES", 2000)}
          />
        </div>
      </WizModal>
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Quote"}
        modalOpen={openAddQuoteModal}
        setOpenModal={(e) => setOpenAddQuoteModal(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationQuote(desition, 2000)}
      >
        <div>
          <AddQuoteForm
            isLoading={isLoadingQuote}
            onSubmit={(data) => {
              setQuoteFormData(data);
            }}
            update={() => showAnimationQuote("YES", 2000)}
          />
        </div>
      </WizModal>

      <BioModal
        modalID="player-add"
        modalTitle={"Actualizar Bio"}
        modalOpen={showBioModal}
        setOpenModal={(e) => setShowBioModal(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <WizModal
          modalID="player-add"
          modalTitle={"Actualizar Bio"}
          modalOpen={showOpenEditModal}
          setOpenModal={(e) => setShowOpenEditModal(false)}
          modalWhithDelay={2000}
          onModalClose={(desition) => showAnimation(desition, 2000)}
        >
          <div>
            <EditBioForm
              item={itemSelected}
              onSubmit={(editdata) => console.log("editFormData", editdata)}
            />
          </div>
        </WizModal>
        <div>
          <Paper>
            <TableContainer
              sx={{
                maxHeight: 840,
                "&::-webkit-scrollbar": {
                  width: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "cyan",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightblue",
                  borderRadius: 2,
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Idioma</TableCell>
                    <TableCell>Bio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bioData?.map((data) => (
                    <>
                      <TableRow>
                        <TableCell>{data.player_id}</TableCell>
                        <TableCell>{data.country_code}</TableCell>
                        <TableCell>{data.bio}</TableCell>
                        <TableCell>
                          <div className="flex items-center">
                            <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                              <EditIcon
                                onClick={() => {
                                  setItemSelected(data.country_code);
                                  setShowOpenEditModal(!showOpenEditModal);
                                }}
                              />
                            </div>
                            <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                              <TrashIcon
                                onClick={() => {
                                  setItemRow(data.country_code);
                                  setAddedCD(!addedCD);
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </BioModal>

      <WizModal
        modalID="player-add"
        modalTitle={"Actualizar Quote"}
        modalOpen={showQuoteModal}
        setOpenModal={(e) => setShowQuoteModal(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <WizModal
          modalID="player-add"
          modalTitle={"Actualizar Quote"}
          modalOpen={showOpenEditQuoteModal}
          setOpenModal={(e) => setShowOpenEditQuoteModal(false)}
          modalWhithDelay={2000}
          onModalClose={(desition) => showAnimation(desition, 2000)}
        >
          <div>
            <EditQuoteForm
              item={itemQuoteSelected}
              onSubmit={(editdata) => console.log("editFormData", editdata)}
              update={() => showAnimation("YES", 2000)}
            />
          </div>
        </WizModal>
        <div>
          <Paper>
            <TableContainer
              sx={{
                maxHeight: 840,
                "&::-webkit-scrollbar": {
                  width: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "cyan",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightblue",
                  borderRadius: 2,
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Idioma</TableCell>
                    <TableCell>Quote</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quoteData?.map((data) => (
                    <TableRow key={data.player_id}>
                      <TableCell>{data.player_id}</TableCell>
                      <TableCell>{data.country_code}</TableCell>
                      <TableCell>{data.quote}</TableCell>
                      <TableCell>
                        <div className="flex items-center">
                          <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                            <EditIcon
                              onClick={() => {
                                setItemQuoteSelected(data.country_code);
                                setShowOpenEditQuoteModal(
                                  !showOpenEditQuoteModal
                                );
                              }}
                            />
                          </div>
                          <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                            <TrashIcon
                              onClick={() => {
                                setQuoteItemRow(data.country_code);
                                setAddedQuoteCD(!addedQuoteCD);
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </WizModal>
      <div ref={listRef} className="space-y-6">
        <h1 className="text-lg pl-2 uppercase font-bold">Bio</h1>

        <div className="border shadow  p-2 rounded-lg relative group">
          <h1 className="text-lg ">{data.bio}</h1>
          <ButtonPencil onClick={() => setShowBioModal(!showBioModal)} />
        </div>
        <h1 className="text-lg pl-2 uppercase font-bold">Quotes</h1>
        <div className="border shadow  p-2 rounded-lg relative group">
          <h1 className="text-lg">{data.quote}.</h1>
          <ButtonPencil onClick={() => setShowQuoteModal(!showQuoteModal)} />
        </div>
      </div>
    </div>
  );
}

export default PlayerBio;
