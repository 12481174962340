import React, { SetStateAction, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

//LOCALS
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import WizModal from "../WizModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import toast from "../Toast";
import DeletedItem from "../Errors/DeletedItem";
import DeleteConfirm from "../Errors/DeleteConfirm";
import { EditYouthCareerForm } from "../Forms/YouthCareerForm/EditYouthCareerForm";
import { AddYouthCareerForm } from "../Forms/YouthCareerForm/AddYouthCareerForm";

//ICONS
import { FaPlus } from "react-icons/fa";
import { Career } from "../../types/data";
import { useNavigate, useParams } from "react-router-dom";

function PlayerYouthCareer({ data }: any) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const router = useNavigate();
  const params = useParams();
  const playerId = params.id as any;

  const [careerItemSelected, setCareerItemSelected] =
    useState<SetStateAction<any>>();
  const [showEditCareer, setShowEditCareer] =
    useState<SetStateAction<any>>(false);
  const [showDeleteCareer, setShowDeleteCareer] =
    useState<SetStateAction<any>>(false);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>(false);
  const [showAddCareerForm, setShowAddCareerForm] =
    useState<SetStateAction<any>>(false);

  //RESPONSE
  const [updatedCareerResponse, setUpdatedCareerResponse] =
    React.useState<SetStateAction<any>>();
  const [createdCareerResponse, setCreatedCareerResponse] =
    useState<SetStateAction<any>>();
  const [deletedCareerResponse, setDeletedCareerResponse] =
    useState<SetStateAction<any>>();

  const [formValuesCareer, setFormValuesCareer] = useState({
    club: "",
    season: "",
    goals: "",
    assists: "",
  });

  //REFRESH PAGE
  const refreshPage = () => {
    window.location.reload();
  };

  const getYouthCareer = async () => {
    const { data } = await axios.get(
      `${BASE_URL}player.youth.career?player_id=${playerId}`,
      {
        headers: {
          Authorization: "Bearer " + uid,
        },
      }
    );
    return data;
  };

  const {
    data: youthCareerData,
    isLoading: isYouthCareerLoading,
    refetch,
  } = useQuery<Career[]>(["playerYouthCareer"], getYouthCareer);

  //GET YOUTH CAREER per Youth Career ID
  const getCareerById = async () => {
    const { data } = await axios.get(
      `${BASE_URL}player.youth.career?player_id=${playerId}&youth_career_id=${careerItemSelected}`,
      {
        params: {
          player_id: playerId,
          youth_career_id: careerItemSelected,
        },
        headers: {
          Authorization: "Bearer " + uid,
        },
      }
    );

    return data.result;
  };

  const { data: careerData, isLoading: isCareerLoading } = useQuery<Career[]>(
    ["playerCareerById", careerItemSelected],
    getCareerById,
    {
      enabled: careerItemSelected !== undefined,
    }
  );

  const [formValues, setFormValues] = React.useState<{
    club?: string;
    season?: string;
    goals?: string;
    assists?: string;
  }>({
    club: careerData?.[0]?.club!,
    season: careerData?.[0]?.season!,
    goals: careerData?.[0]?.goals!,
    assists: careerData?.[0]?.assist!,
  });

  //ADD CAREER
  const { mutate: addRowCareer, isLoading: addRowLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("player_id", playerId);
      formData.append("season", formValuesCareer.season);
      formData.append("goals", formValuesCareer.goals);
      formData.append("club", formValuesCareer.club);
      formData.append("assist", formValuesCareer.assists);
      return axios.post(`${BASE_URL}player.youth.career`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setCreatedCareerResponse(result);
        refetch();
        setShowAddCareerForm(false);
      },
      onError: (err: any) => {
        setCreatedCareerResponse(err.response?.data || err);
      },
    }
  );

  useEffect(() => {
    setFormValues({
      club: careerData?.[0]?.club!,
      season: careerData?.[0]?.season!,
      goals: careerData?.[0]?.goals!,
      assists: careerData?.[0]?.assist!,
    });
  }, [careerItemSelected, careerData]);

  const { mutate: updateRowCareer, isLoading: updateRowLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("youth_career_id", careerItemSelected);
      formData.append("club", formValues.club!);
      formData.append("season", formValues.season!);
      formData.append("goals", formValues.goals!);
      formData.append("assist", formValues.assists!);
      return axios.post(`${BASE_URL}player.youth.career`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedCareerResponse(result);
      },
      onError: (err: any) => {
        setUpdatedCareerResponse(err.response?.data || err);
      },
    }
  );

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //UPDATE CAREER RESPONSE
  React.useEffect(() => {
    if (updatedCareerResponse?.data?.result === 1) {
      notify("success", `Carrera Actualizada`);

      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (updatedCareerResponse?.data?.status === "error") {
      notify("error", `${updatedCareerResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedCareerResponse]);

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (
      desition !== "NO" &&
      formValues.assists !== "" &&
      formValues.club !== "" &&
      formValues.goals !== "" &&
      formValues.season !== ""
    ) {
      updateRowCareer();
    }
  };

  React.useEffect(() => {
    if (createdCareerResponse?.data?.result === 1) {
      notify("success", `Carreta Añadida`);

      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (createdCareerResponse?.data?.status === "error") {
      notify("error", `${createdCareerResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createdCareerResponse]);

  const showAnimationNd = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (
      desition !== "NO" &&
      formValuesCareer.season !== "" &&
      formValuesCareer.goals !== "" &&
      formValuesCareer.assists !== "" &&
      formValuesCareer.club !== ""
    ) {
      addRowCareer();
    }
  };

  const { mutate: deleteCareer } = useMutation(
    async () => {
      return axios.delete(
        `${BASE_URL}player.youth.career?player_id=${playerId}&youth_career_id=${careerItemSelected}`,
        {
          headers: {
            Authorization: "Bearer" + uid,
          },
        }
      );
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setDeletedCareerResponse(result);
      },
      onError: (err: any) => {
        setDeletedCareerResponse(err.response?.data || err);
      },
    }
  );

  const showAnimationDelete = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deleteCareer();
    }
  };

  React.useEffect(() => {
    if (deletedCareerResponse?.data?.result === 1) {
      notify("success", `Carrera Eliminada`);

      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (deletedCareerResponse?.data?.status === "error") {
      notify("error", `${deletedCareerResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deletedCareerResponse]);

  return (
    <div className="p-6 ">
      <WizModal
        modalID="player-add"
        modalTitle={"Actualizar Carrera"}
        modalOpen={showEditCareer}
        setOpenModal={(e) => setShowEditCareer(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <EditYouthCareerForm
            item={careerItemSelected}
            data={careerData}
            isLoading={updateRowLoading}
            onSubmit={(data) => {
              setFormValues(data);
            }}
            update={() => showAnimation("YES", 2000)}
          />
        </div>
      </WizModal>
      <WizModal
        modalID="player-delete"
        modalTitle={"Borrar Carrera"}
        modalOpen={showDeleteCareer}
        setOpenModal={(e) => setShowDeleteCareer(false)}
        modalType={"DESITION"}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationDelete(desition, 2000)}
      >
        <div>
          {showDeleteAnimation === "YES" ? (
            <DeletedItem title={true} />
          ) : (
            <DeleteConfirm title={true} />
          )}
        </div>
      </WizModal>
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Carrera"}
        modalOpen={showAddCareerForm}
        setOpenModal={(e) => setShowAddCareerForm(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationNd(desition, 2000)}
      >
        <div>
          <AddYouthCareerForm
            onSubmit={(data) => setFormValuesCareer(data)}
            isLoading={addRowLoading}
            update={() => showAnimationNd("YES", 2000)}
          />
        </div>
      </WizModal>
      <div className="flex place-items-center justify-between mr-14">
      <h1 className="text-3xl font-bold  my-4">Carrera Juvenil</h1>
      <button
        className="flex items-center space-x-4 border mb-3 shadow px-3 py-2 rounded-lg  bg-lime-400 hover:bg-lime-500  transition-all transform duration-150 ease-in-out"
        onClick={() => setShowAddCareerForm(!showAddCareerForm)}
      >
        <h1>Añadir Carrera</h1>
        <FaPlus />
      </button>
      </div>
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Club</TableCell>
                <TableCell>Temporadas</TableCell>
                <TableCell>Goles</TableCell>
                <TableCell>Asistencias</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.youth_career?.map((career: Career) => (
                <TableRow key={career.career_id}>
                  <TableCell>{career.club}</TableCell>
                  <TableCell>{career.season}</TableCell>
                  <TableCell>{career.goals}</TableCell>
                  <TableCell>{career.assist}</TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                        <Edit
                          onClick={() => {
                            setCareerItemSelected(career.youth_career_id);
                            setShowEditCareer(!showEditCareer);
                          }}
                        />
                      </div>
                      <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                        <Delete
                          onClick={() => {
                            setCareerItemSelected(career.youth_career_id);
                            setShowDeleteCareer(!showDeleteCareer);
                          }}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
}

export default PlayerYouthCareer;
