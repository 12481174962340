import { combineReducers } from "redux";
import authReducer from "./authReducer";



// reducers
import { playerReducer } from "./playerReducer";



export const rootReducer = combineReducers({
    player: playerReducer,
    auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
