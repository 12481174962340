import styled from "styled-components";
import estadio from "../assets/images/estadio.jpg";

export const LoginPageStyles = styled.div`
  background-image: url(${estadio});
  background-size: cover; 
  background-position: center; 

  
  .wiz-auth-form {
    max-width: 40em;
  }
`;