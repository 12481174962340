import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";

//IMAGES
import image from "../assets/images/shadow.png";

//COMPONENTS
import CoachData from "../components/coachData/CoachData";
import PlayerGallery from "../components/playerData/PlayerGallery";
import PlayerImages from "../components/playerData/PlayerImage";
import PlayerNews from "../components/playerData/PlayerNews";
import PlayerVideos from "../components/playerData/PlayerVideos";
import { ButtonPencil } from "../components/button/ButtonPencil";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAtom } from "jotai";
import { showImageModal } from "../components/jotai/jotaiAtom";
import { BASE_URL, BASE_URL_IMG } from "../api";
import { useParams } from "react-router-dom";
import { Player } from "../types/data";
import CoachCareer from "../components/coachData/CoachCareer";
import CoachName from "../components/coachData/CoachName";

function Coach() {
  const params = useParams();

  const playerId = params.id;

  //NAME
  const [showImage, setShowImage] = useAtom(showImageModal);

  //SHOW STATE
  const [showNews, setShowNews] = useState(true);
  const [showName, setShowName] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showEditFirst, setShowEditFirst] = useState(false);
  const [showEditSecond, setShowEditSecond] = useState(false);
  const [showEditThird, setShowEditThird] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [teamsData, setteamsData] = useState<any>([]);

  const getTeams = async () => {
    const response = await axios.get(`${BASE_URL}teams`);
    const teamData = response.data;

    setteamsData(teamData);
  };

  const getPlayersById = async () => {
    const { data } = await axios.get(
      `${BASE_URL}coaches?id=${playerId}&countryid=ES`
    );
    return data;
  };

  const { data, isLoading, refetch } = useQuery<Player>(
    ["playerById", playerId],
    getPlayersById
  );

  const refreshPage = () => {
    window.location.reload();
    setTimeout(getTeams, 1000);
  };
  useEffect(() => {
    setIsRefreshing(false);
  }, [data]);

  //HANDLE STATE
  const handleNews = () => {
    setShowNews(true);
    setShowVideos(false);
    setShowGallery(false);
  };
  const handlePerformance = () => {
    setShowNews(false);
    setShowVideos(false);
    setShowGallery(false);
  };
  const handleGallery = () => {
    setShowNews(false);
    setShowVideos(false);
    setShowGallery(true);
  };

  const handleVideos = () => {
    setShowNews(false);
    setShowVideos(true);
    setShowGallery(false);
  };

  const classNames = {
    primary:
      "border-t-4 border-blue-300 transition-all transform duration-150 ease-out",
  };

  const [imageUrl, setImageUrl] = useState("");
  const [matchedTeam, setMatchedTeam] = useState({img_url: ""});
  
  /*   async function fetchData() {
    const matchedTeam = teamsData.find(
      (team: { name: string; img_url: string }) =>
        data?.data?.current_team === team.name
    );

    if (matchedTeam) {
    }
    setImageUrl(matchedTeam?.img_url);
  } */
  

  useEffect(() => {
    getTeams();
  }, []);
  

  
  useEffect(() => {
    if (teamsData) {
      const matched_Team = teamsData.find(
        (team: { name: string; img_url: string }) =>
          data?.data?.current_team_name === team.name
      );
      setMatchedTeam(matched_Team);

    }
  }, [teamsData, data]);
  
  useEffect(() => {
    setImageUrl(matchedTeam?.img_url);
  }, [matchedTeam]);

  if (!isLoading)
    return (
      <div className="pb-72 h-[200%] " style={{ backgroundColor: "#f3f3f4" }}>
        <>
          <Header />

          <div
            className="flex max-w-[70%] mx-auto"
            style={{ backgroundColor: "white" }}
          >
            <div className="border shadow rounded">
              <CoachName
                data={data?.data!}
                isLoading={isLoading}
                refetch={refetch}
              />
              <br />
              <div className="flex justify-center">
                <div>
                  {data?.data?.img_profile_url === null ? (
                    <img
                      loading="lazy"
                      src={image}
                      alt=""
                      className="w-32 h-32 object-cover border-2 border-black rounded-full"
                    />
                  ) : (
                    <img
                      className="w-32 h-32 object-cover border-2 border-black rounded-full"
                      src={BASE_URL_IMG + data?.data?.img_profile_url}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; //prevents looping
                        currentTarget.src = image;
                      }}
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "100px",
                      marginTop: "-130px",
                      borderWidth: "2px",
                      borderColor: "black",
                    }}
                    className="bg-white rounded-full h-12 w-12 flex justify-center"
                  >
                    <img
                      className="w-8 h-10 object-cover rounded-full mt-0.5"
                      src={BASE_URL_IMG + imageUrl}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = image;
                      }}
                    />
                  </div>
                </div>
              </div>
              <br />
              <PlayerImages refreshData={() => refreshPage()} />
              <CoachCareer data={data} />
            </div>

            <div className="border bg-white  shadow p-4">
              <div
                className="relative group"
                style={{
                  width: "100%",
                  backgroundImage:
                    "url('https://www.freewebheaders.com/wp-content/gallery/football/cache/football-stadium-green-strips-lawn-web-header.jpg-nggid044194-ngg0dyn-1280x720x100-00f0w010c010r110f110r010t010.jpg')",
                  backgroundRepeat: " no-repeat",
                  backgroundSize: "100%",
                }}
              >
                {data?.data?.img_header_url === null ? (
                  <img
                    style={{ width: "10%" }}
                    loading="lazy"
                    src={image}
                    alt=""
                    className="w-96 object-cover"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; //prevents looping
                      currentTarget.src = image;
                    }}
                  />
                ) : (
                  <img
                    style={{ maxHeight: 230, maxWidth: 250 }}
                    src={BASE_URL_IMG + data?.data?.img_header_url}
                    className="w-96 object-cover"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; //prevents looping
                      currentTarget.src = image;
                    }}
                  />
                )}

                <ButtonPencil onClick={() => setShowImage(!showImage)} />
              </div>
              <br />
              <CoachData
                refetch={refetch}
                data={data}
                showEditSecond={showEditSecond}
                showEditFirst={showEditFirst}
                showEditThird={showEditThird}
                setShowEditFirst={setShowEditFirst}
                setShowEditSecond={setShowEditSecond}
                setShowEditThird={setShowEditThird}
                refreshData={() => refreshPage()}
              />
              <br />
              <div className="flex flex-col bg-white w-[1500px] mx-auto">
                <div className=" flex space-x-10">
                  <button
                    className={`${
                      showNews ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2 pb-10`}
                    onClick={handleNews}
                  >
                    Noticias
                  </button>
                  <button
                    className={`${
                      showGallery ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2  pb-10`}
                    onClick={handleGallery}
                  >
                    Gallery
                  </button>
                  <button
                    className={`${
                      showVideos ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2  pb-10`}
                    onClick={handleVideos}
                  >
                    Videos
                  </button>
                </div>
                <div className="pb-10">
                  {showNews && <PlayerNews data={data?.news!} />}
                  {showGallery && (
                    <PlayerGallery
                      data={data?.galery!}
                      refreshData={() => refreshPage()}
                    />
                  )}
                  {showVideos && (
                    <PlayerVideos
                      refreshData={() => refreshPage()}
                      data={data?.videos!}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      </div>
    );
}

export default Coach;
