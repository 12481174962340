import { Field, Form, Formik } from "formik";
import React from "react";
import { MyField } from "../../MyField";

type Values = {
  name: string;
  surname: string;
};

interface Props {
  onSubmit: (values: Values) => void;
  data: {
    name: string;
    surname: string;
  };
}
export const PlayerNameForm: React.FC<Props> = ({ onSubmit, data }) => {
  return (
    <Formik<{ name: string; surname: string }>
      initialValues={{
        name: data?.name,
        surname: data?.surname,
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
      }}
    >
      {({ values, handleBlur, handleChange }) => (
        <Form>
          <div className="flex flex-col justify-between space-y-4">
            <div>
              <Field
                name="name"
                placeholder="Nombre del Jugador"
                component={MyField}
              />
            </div>
            <div>
              <Field
                name="surname"
                placeholder="Apellidos del Jugador"
                component={MyField}
              />
            </div>
          </div>
          <button style={{marginTop:'12px'}} className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " type="submit">Actualizar Nombre</button>
        </Form>
      )}
    </Formik>
  );
};

export default PlayerNameForm;
