import { Form, Formik } from "formik";
import * as React from "react";
import { Loader } from "../../Loader";

interface Values {
  club: string;
  season: string;
  goals: string;
  assists: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

const classNames = {
  primary: "border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]",
};

export const AddCareerForm = ({ onSubmit, update, isLoading }: Props) => {
  if (!isLoading)
    return (
      <div>
        <Formik<{
          club: string;
          season: string;
          goals: string;
          assists: string;
        }>
          initialValues={{
            club: "",
            season: "",
            goals: "",
            assists: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            update();
          }}
        >
          {({ values, handleChange, handleBlur, submitForm, isSubmitting }) => (
            <Form>
              <div className="flex flex-col justify-center space-y-3">
                <div className="flex justify-between">
                  <div className="flex flex-col items-start">
                    <label>Equipo Actual</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="club"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.club}
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label>Temporadas</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="season"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.season}
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col items-start">
                    <label>Goles</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="goals"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.goals}
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label>Asistencias</label>
                    <input
                      className={`${classNames.primary}`}
                      type="text"
                      name="assists"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.assists}
                    />
                  </div>
                </div>

                <button
                  className="ml-36 w-36 flex items-center space-x-4 border shadow px-3 py-2 rounded-lg bg-lime-400 hover:bg-lime-500 transition-all transform duration-150 ease-in-out text-center"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Añadir Carrera
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  else {
    return <Loader />;
  }
};
