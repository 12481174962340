import React, { SetStateAction, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Add } from "@mui/icons-material";

//LOCALS
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import WizModal from "../WizModal";
import { EditCareerForm } from "./EditCareerForm";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import toast from "../Toast";
import { AddCareerForm } from "./AddCareerForm";

//ERRORS
import DeletedItem from "../Errors/DeletedItem";
import DeleteConfirm from "../Errors/DeleteConfirm";

//ICONS
import { useNavigate, useParams } from "react-router-dom";
import { Career } from "../../types/data";

function CoachCareer({ data }: any) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const router = useNavigate();
  const params = useParams();
  const playerId = params.id as any;
  const [careerItemSelected, setCareerItemSelected] =
    useState<SetStateAction<any>>();

  //SHOW MODAL
  const [showEditCareer, setShowEditCareer] =
    useState<SetStateAction<any>>(false);
  const [showDeleteCareer, setShowDeleteCareer] =
    useState<SetStateAction<any>>(false);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>(false);
  const [showAddCareerForm, setShowAddCareerForm] =
    useState<SetStateAction<any>>(false);

  //RESPONSE
  const [updatedCareerResponse, setUpdatedCareerResponse] =
    React.useState<SetStateAction<any>>();
  const [createdCareerResponse, setCreatedCareerResponse] =
    useState<SetStateAction<any>>();
  const [deletedCareerResponse, setDeletedCareerResponse] =
    useState<SetStateAction<any>>();

  //REFRESH PAGE
  const refreshPage = () => {
    window.location.reload();
  };

  const getCareerById = async () => {
    const { data } = await axios.get(`${BASE_URL}coach.career`, {
      params: {
        coach_id: playerId,
        career_id: careerItemSelected,
      },
      headers: {
        Authorization: "Bearer " + uid,
      },
    });

    return data.result;
  };

  const {
    data: careerData,
    isLoading: isCareerLoading,
    refetch,
  } = useQuery<Career[]>(
    ["playerCareerById", careerItemSelected],
    getCareerById,
    {
      enabled: careerItemSelected !== undefined,
    }
  );

  const [formValues, setFormValues] = React.useState({
    club: careerData?.[0]?.club,
    season: careerData?.[0]?.season,
    division: careerData?.[0]?.season,

  });

  const [formValuesCareer, setFormValuesCareer] = useState({
    club: "",
    season: "",
    division: "",
  });

  //ADD CAREER
  const { mutate: addRowCareer, isLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("coach_id", playerId);
      formData.append("season", formValuesCareer.season);
      formData.append("club", formValuesCareer.club);
      formData.append("division", formValuesCareer.division);
      return await axios.post(`${BASE_URL}coach.career`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setCreatedCareerResponse(result);
        refetch();
        setShowAddCareerForm(false);
      },
      onError: (err: any) => {
        setCreatedCareerResponse(err.response?.data || err);
      },
    }
  );

  useEffect(() => {
    setFormValues({
      club: careerData?.[0]?.club,
      season: careerData?.[0]?.season,
      division: careerData?.[0]?.division,
    });
  }, [careerItemSelected, careerData]);

  const { mutate: updateRowCareer, isLoading: updateLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("coach_id", playerId);
      formData.append("career_id", careerItemSelected);
      formData.append("club", formValues.club!);
      formData.append("season", formValues.season!);
      formData.append("division", formValues.division!);
      return axios.post(`${BASE_URL}coach.career`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedCareerResponse(result);
        setShowEditCareer(false);
      },
      onError: (err: any) => {
        setUpdatedCareerResponse(err.response?.data || err);
      },
    }
  );

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //UPDATE CAREER RESPONSE

  React.useEffect(() => {
    if (updatedCareerResponse?.data?.result === 1) {
      notify("success", `Carrera Actualizada`);

      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (updatedCareerResponse?.data?.status === "error") {
      notify("error", `${updatedCareerResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedCareerResponse]);

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);
    // await setShowDeleteAnimation();

    if (
      desition !== "NO" &&
      formValues.club !== "" &&
      formValues.season !== ""
    ) {
      updateRowCareer();
    }
  };

  React.useEffect(() => {
    if (createdCareerResponse?.data?.result === 1) {
      notify("success", `Carreta Añadida`);

      setTimeout(() => {
        dismiss();
        // refetch();
        refreshPage();
      }, 1000);
    } else if (createdCareerResponse?.data?.status === "error") {
      notify("error", `${createdCareerResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createdCareerResponse]);

  const showAnimationNd = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);
    // await setShowDeleteAnimation();

    if (
      desition !== "NO" &&
      formValues.club !== "" &&
      formValues.season !== ""
    ) {
      addRowCareer();
    }
  };

  const { mutate: deleteCareer } = useMutation(
    async () => {
      return axios.delete(
        `${BASE_URL}coach.career?coach_id=${playerId}&career_id=${careerItemSelected}`,
        {
          headers: {
            Authorization: "Bearer" + uid,
          },
        }
      );
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setDeletedCareerResponse(result);
      },
      onError: (err: any) => {
        setDeletedCareerResponse(err.response?.data || err);
      },
    }
  );

  const showAnimationDelete = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deleteCareer();
    }
  };

  React.useEffect(() => {
    if (deletedCareerResponse?.data?.result === 1) {
      notify("success", `Carrera Eliminada`);

      setTimeout(() => {
        dismiss();
        // refetch();
        refreshPage();
      }, 1000);
    } else if (deletedCareerResponse?.data?.status === "error") {
      notify("error", `${deletedCareerResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deletedCareerResponse]);


  
  const handleEditClick = (career: Career) => {
    localStorage.setItem("club", career.club);
    localStorage.setItem("season", career.season);
    localStorage.setItem("division", career.division);
  
    setCareerItemSelected(career.career_id);
    setShowEditCareer(!showEditCareer);
  };

  return (
    <div className="p-6 ">
      <WizModal
        modalID="player-add"
        modalTitle={"Actualizar Carrera"}
        modalOpen={showEditCareer}
        setOpenModal={(e) => setShowEditCareer(false)}
        modalWhithDelay={2000}
      >
        <div>
          <EditCareerForm
            data={careerData}
            onSubmit={(data) => {
              setFormValues(data);
            }}
            isLoading={updateLoading}
            item={""}
            update={() => showAnimation("YES", 2000)}
          />
        </div>
      </WizModal>
      <WizModal
        modalID="player-delete"
        modalTitle={"Borrar Carrera"}
        modalOpen={showDeleteCareer}
        setOpenModal={(e) => setShowDeleteCareer(false)}
        modalType={"DESITION"}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationDelete(desition, 2000)}
      >
        <div>
          {showDeleteAnimation === "YES" ? (
            <DeletedItem title={true} />
          ) : (
            <DeleteConfirm title={true} />
          )}
        </div>
      </WizModal>
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Carrera"}
        modalOpen={showAddCareerForm}
        setOpenModal={(e) => setShowAddCareerForm(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationNd(desition, 2000)}
      >
        <div>
          <AddCareerForm
            onSubmit={(data) => setFormValuesCareer(data)}
            update={() => showAnimationNd("YES", 2000)}
            isLoading={isLoading}
          />
        </div>
      </WizModal>
      <button
        className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
        onClick={() => setShowAddCareerForm(!showAddCareerForm)}
      >
        <div className="flex place-items-center justify-between mr-14"></div>
        <h1 className="mr-3">Añadir Carrera</h1>
        <Add />
      </button>
      <h1 className="text-xl font-bold border-b my-4">Carrera</h1>
      
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Club</TableCell>
                <TableCell>Temporadas</TableCell>
                <TableCell>Division</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.career?.map((career: Career) => (
                <TableRow key={career.career_id}>
                  <TableCell>{career.club}</TableCell>
                  <TableCell>{career.season}</TableCell>
                  <TableCell>{career.division}</TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                        <Edit
                          onClick={() => {
                            handleEditClick(career);
                            setCareerItemSelected(career.career_id);
                            setShowEditCareer(!showEditCareer);
                          }}
                        />
                      </div>
                      <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                        <Delete
                          onClick={() => {
                            setCareerItemSelected(career.career_id);
                            setShowDeleteCareer(!showDeleteCareer);
                          }}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );

}

export default CoachCareer;
