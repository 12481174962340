import { Form, Formik, Field, ErrorMessage } from "formik";
import * as React from "react";
import { Loader } from "../Loader";

//LOCALS
import { MyField } from "../MyField";

interface Values {
  name: string;
  img_url: File | null;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

interface AddTeamFormProps {
  name: string;
  img_url: File | null;
}

export const AddTeamForm: React.FC<Props> = ({
  onSubmit,
  update,
  isLoading,
}) => {
  if (!isLoading) {
    return (
      <Formik<AddTeamFormProps & { img_url: File | null }>
        initialValues={{
          name: "",
          img_url: null,
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          try {
            await onSubmit(values);
            update();
            console.log("values", values);
            // Puedes mostrar un mensaje de éxito aquí si lo deseas
            resetForm();
          } catch (error) {
            console.error("Error:", error);

          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4">
              <div className="flex items-center space-x-6">
                <div>
                  <label className="mr-4" htmlFor="img_url">
                    Escudo
                  </label>
                  <input
                    type="file"
                    id="img_url"
                    name="img_url"
                    accept="image/png"
                    onChange={(event) => {
                      const file = event.currentTarget.files?.[0];
                      setFieldValue("img_url", file);
                    }}
                  />
                  <ErrorMessage name="img_url" component="div" />
                </div>
              </div>
              <div className="flex items-center space-x-6">
                <div>
                  <Field name="name" placeholder="Nombre" component={MyField} />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center space-x-4">
              <button
                disabled={isSubmitting}
                type="submit"
                className="mt-3 py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800 flex items-center"
              >
                Añadir Equipo
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  } else {
    return <Loader />;
  }
};
