import { useQuery } from "@tanstack/react-query";
import axios from "axios";

//LOCALS
import { BASE_URL } from "../api";
import TeamsData from "../components/teams/TeamsData";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Loader } from "../components/Loader";

const getTeams = async () => {
  const { data } = await axios.get(`${BASE_URL}teams`);
  return data;
};
function Teams() {
  const { data, isLoading } = useQuery(["teams"], getTeams);

  if (!isLoading)
    return (
      <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor:'#f3f3f4',
          height:'100vh'
        }}
      >
          <TeamsData data={data} 
          />
        <Footer />
      </div>
      </div>
    );
  else {
    return <Loader />;
  }
}

export default Teams;
