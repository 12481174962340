import Select from "react-select";
import { Formik } from "formik";
import React, { ReactNode, SetStateAction } from "react";

//ICONS
import { IoCloseSharp } from "react-icons/io5";

//LOCALS
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { players } from "../../types/data";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";

interface Props {
  modalID: string;
  modalTitle: string;
  children: ReactNode;
  modalOpen: boolean;
  setOpenModal: SetStateAction<any>;
  modalType?: string;
  modalWhithDelay: number;
  onModalClose: (desition: string) => Promise<void>;
  data: players | undefined;
  setFormValues: SetStateAction<any>;
  setSelectFoot: SetStateAction<any>;
  selectFoot: { value: number; label: string };
  update: any;
  isLoading: boolean;
}

function EditPlayerModalNd({
  modalID,
  modalTitle,
  children,
  modalOpen,
  setOpenModal,
  modalType,
  onModalClose,
  modalWhithDelay,
  data,
  setFormValues,
  setSelectFoot,
  selectFoot,
  update,
  isLoading,
}: Props) {
  const router = useNavigate();
  const closeModal = async (desitionType: string) => {
    onModalClose(desitionType);
    if (modalType === "DESITION" && desitionType === "YES" && modalWhithDelay)
      await wizTimeOutFun(modalWhithDelay);
    setOpenModal(false);
  };

  const renderModalType = () => {
    switch (modalType) {
      case "CONFIRM":
        return (
          <label
            htmlFor={modalID}
            className="btn"
            onClick={() => closeModal("YES")}
          >
            ok
          </label>
        );
        break;
      case "DESITION":
        return (
          <>
            <div className="btn" onClick={() => closeModal("YES")}>
              Yes
            </div>
            <div className="btn" onClick={() => closeModal("NO")}>
              No
            </div>
          </>
        );
        break;

      case "NONE":
        return null;
        break;

      default:
        return null;
        break;
    }
  };

  const footData = [
    {
      value: 1,
      label: "Derecho",
    },
    {
      value: 2,
      label: "Izquierdo",
    },
  ];
  const handleChangeFoot = (event: any) => {
    setSelectFoot(event);
  };

  const classNames = {
    primary: "border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]",
  };

  return (
    <div>
      <div>
        <input type="checkbox" id={modalID} className={`modal-toggle`} />
        <div className={`modal ${modalOpen && "modal-open"}`}>
          <div className="modal-box ">
            <div
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={() => closeModal("NO")}
            >
              <IoCloseSharp />
            </div>

            <h3 className="font-bold text-lg">{modalTitle}</h3>
            <div className="p-2">{children}</div>
            {!isLoading ? (
              <Formik<{
                height: string | undefined;
                weight: string | undefined;
                foot_name: string | undefined;
                jersey_nro: string | undefined;
              }>
                initialValues={{
                  height: data?.height,
                  weight: data?.weight,
                  foot_name: data?.foot_name,
                  jersey_nro: data?.jersey_nro,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setFormValues({
                    height: values.height,
                    weight: values.weight,
                    foot_name: values.foot_name,
                    jersey_nro: values.jersey_nro,
                  });
                  update();
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex-col">
                      <div className="flex flex-col items-start">
                        <label>Altura</label>
                        <input
                          className={`${classNames.primary}`}
                          type="text"
                          name="height"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.height}
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <label>Peso</label>
                        <input
                          className={`${classNames.primary}`}
                          type="text"
                          name="weight"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.weight}
                        />
                      </div>
                    </div>
                    <div className="flex-col ">
                      <div className="flex flex-col items-start">
                        <label>Pie</label>
                        <Select
                          placeholder="Seleccionar Pie"
                          options={footData}
                          value={selectFoot}
                          onChange={(e) => handleChangeFoot(e)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start">
                      <label>Camiseta</label>
                      <input
                        className="border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]"
                        type="text"
                        name="jersey_nro"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jersey_nro}
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{marginTop:'12px'}}
                      className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
                    >
                      Cambiar Datos
                    </button>
                  </form>
                )}
              </Formik>
            ) : (
              <Loader />
            )}

            <div className="modal-action">{renderModalType()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPlayerModalNd;
