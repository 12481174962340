import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//LOCALS
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppSelector } from "../../redux/hooks";
import { Player } from "../../types/data";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { ButtonPencil } from "../button/ButtonPencil";
import EditPlayerModal from "../EditPlayer/EditPlayerModal";
import EditPlayerModalNd from "../EditPlayer/EditPlayerModalNd";
import EditPlayerModalRd from "../EditPlayer/EditPlayerModalRd";
import toast from "../Toast";
import { teamID } from "../jotai/jotaiAtom";
import { useAtom } from "jotai";



interface Props {
  data: Player | undefined;
  setShowEditFirst: SetStateAction<any>;
  showEditFirst: boolean;
  setShowEditSecond: SetStateAction<any>;
  showEditSecond: boolean;
  setShowEditThird: SetStateAction<any>;
  showEditThird: boolean;
  refreshData: () => void;
  refetch: () => void;
}

function PlayerData({
  data,
  setShowEditFirst,
  showEditFirst,
  setShowEditSecond,
  showEditSecond,
  setShowEditThird,
  showEditThird,
  refreshData,
  refetch,
}: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const route = useNavigate();
  const params = useParams();
  const playerId = params.id as any;
  const filters = useAppSelector((state) => state.player);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [updatedPositionResponse, setUpdatedPositionResponse] =
    useState<SetStateAction<any>>();
  const [updatedSecondModalResponse, setUpdatedSecondModalResponse] =
    useState<SetStateAction<any>>();
  const [updatedFirstModalResponse, setUpdatedFirstModalResponse] =
    useState<SetStateAction<any>>();
  const [selectFoot, setSelectFoot] = useState<any>({
    value: 1,
    label: "Derecho",
  });

  //FIRST MODAL STATE
  const [formValuesSt, setFormValuesSt] = useState<{
    birthdate?: string;
    actual_team?: string;
  }>({
    birthdate: data?.data?.birthdate,
    actual_team: data?.data?.current_team,
  });

  //SECOND MODAL STATE
  const [formValuesNd, setFormValuesNd] = useState<{
    height?: string;
    weight?: string;
    foot_name?: string;
    jersey_nro?: string;
  }>({
    height: data?.data?.height,
    weight: data?.data?.weight,
    foot_name: data?.data?.foot_name,
    jersey_nro: data?.data?.jersey_nro,
  });

  //THIRD MODAL STATE
  const [formValuesRd, setFormValuesRd] = useState<{
    jersey_nro?: string;
    primary_description?: string;
    secondary?: { code: string; description: string }[];
  }>({
    jersey_nro: data?.data?.jersey_nro,
    primary_description: data?.map_positions_translate?.primary_description,
    secondary: data?.map_positions_translate?.secondary,
  });

  //POSITIONS
  const getPositions = async () => {
    const { data } = await axios.get(
      `${BASE_URL}player.positions?player_id=${playerId}&country_code=ES`
    );
    return data.result;
  };

  const { data: positionData, isLoading } = useQuery(
    ["playerPosition"],
    getPositions
  );

  const [teamsData, setteamsData] = useState<any>([]);
  const [matchedTeams, setMatchedTeams] = useState<any>([]);
  const [team_ID, setTeam_ID] = useAtom(teamID);
  
  const getTeams = async () => {
    const response = await axios.get(`${BASE_URL}teams`);
    const teamData = response.data;

    const matchedTeam = teamsData.find(
      (team: { name: string; img_url: string }) =>
        data?.data?.current_team === team.name
        );
        setteamsData(teamData);
        setMatchedTeams(matchedTeam?.team_id)
      };

      useEffect(() => {
        getTeams();
      }, []); 
      
  //FIRST MODAL UPDATE
  const { mutate: updateMainDataPlayer, isLoading: isLoadingMain } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("birthdate", formValuesSt?.birthdate as any);
      formData.append("nationality_code", filters?.filters.nationality);
      formData.append("current_team_id", team_ID);
      formData.append(
        "second_nationality_code",
        filters?.filters.second_nationality
      );
      formData.append("current_team", formValuesSt.actual_team as any);
  
      return await axios.post(`${BASE_URL}players`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedFirstModalResponse(result);
        refetch();
        getTeams();
      },
      onError: (err: any) => {
        setUpdatedFirstModalResponse(err.response?.data || err);
      },
    }
    
  );

    
  console.log("TeamID",teamID)
  //SECOND MODAL UPDATE
  const { mutate: updateModalNd, isLoading: isLoadingSd } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("height", formValuesNd.height as any);
      formData.append("weight", formValuesNd.weight as any);
      formData.append("foot", selectFoot?.value);
      formData.append("jersey_nro", formValuesNd?.jersey_nro!);
      return await axios.post(`${BASE_URL}players`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedSecondModalResponse(result);
        refetch();
      },
      onError: (err: any) => {
        setUpdatedSecondModalResponse(err.response?.data || err);
      },
    }
  );

  //THIRD MODAL STATE
  const { mutate: updatePositionsPlayer, isLoading: updatePositionsLoading } =
    useMutation(
      async () => {
        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("player_id", playerId);
        formData.append(
          "first_position_code",
          filters.filters.primary_position.value
        );
        formData.append(
          "second_position_list",
          filters.filters.secondary_positions
        );
        return await axios.post(`${BASE_URL}player.positions`, formData, {
          headers: {
            Authorization: "Bearer " + uid,
          },
        });
      },
      {
        onSuccess: async (res) => {
          const result = {
            status: res.status + "-" + res.statusText,
            headers: res.headers,
            data: res.data,
          };
          setUpdatedPositionResponse(result);
          refetch();
        },
        onError: (err: any) => {
          setUpdatedPositionResponse(err.response?.data || err);
        },
      }
    );

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //FIRST MODAL RESPONSE
  useEffect(() => {
    if (updatedFirstModalResponse?.data?.status === "ok") {
      notify("success", `Jugador Actualizado`);
      setTimeout(() => {
        dismiss();
        setShowEditFirst(false);
      }, 1000);
    } else if (updatedFirstModalResponse?.data?.status === "error") {
      notify("error", `${updatedFirstModalResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedFirstModalResponse]);

  //SECOND MODAL RESPONSE
  useEffect(() => {
    if (updatedSecondModalResponse?.data?.status === "ok") {
      notify("success", `Jugador Actualizado`);
      setTimeout(() => {
        dismiss();
        setShowEditSecond(false);
      }, 1000);
    } else if (updatedSecondModalResponse?.data?.status === "error") {
      notify("error", `${updatedSecondModalResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedSecondModalResponse]);

  //THIRD MODAL RESPONSE
  React.useEffect(() => {
    if (updatedPositionResponse?.data?.status === "ok") {
      notify(
        "success",
        `Primera Posición ${updatedPositionResponse.data.result.fisrtPositionUpdated} Actualizada
         Segunda Posición ${updatedPositionResponse.data.result.secondPositionsAdded} Añadida
         Segunda Posición ${updatedPositionResponse.data.result.secondPositionsAdded} Eliminada
        `
      );

      setTimeout(() => {
        dismiss();
        setShowEditThird(false);
      }, 1500);
    } else if (updatedPositionResponse?.data?.status === "error") {
      notify("error", `${updatedPositionResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedPositionResponse]);

  //ANIMATION
  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      updatePositionsPlayer();
    }
  };
  const showAnimationSt = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (
      desition !== "NO" &&
      formValuesSt.actual_team !== undefined &&
      formValuesSt.birthdate !== undefined
    ) {
      updateMainDataPlayer();
    }
  };

  const showAnimation2 = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (
      desition !== "NO" &&
      formValuesNd.height !== undefined &&
      formValuesNd.weight !== undefined
    ) {
      updateModalNd();
    }
  };

  const handleOpenEditFirst = () => {
    setShowEditFirst(true);
  
  }

console.log(data?.data);
  const handleOpenEditSecond = () => setShowEditSecond(true);
  const handleOpenEditThird = () => setShowEditThird(true);

  return (
    <div>
      {showEditFirst && (
        <EditPlayerModal
          data={data?.data}
          modalID="player-add"
          modalTitle={"Actualizar Datos "}
          modalOpen={showEditFirst}
          setOpenModal={() => setShowEditFirst(false)}
          modalWhithDelay={2000}
          onModalClose={(desition: string) => showAnimationSt(desition, 2000)}
          setFormValues={setFormValuesSt}
          isLoading={isLoadingMain}
          update={() => showAnimationSt("YES", 2000)}
        >
          <div></div>
        </EditPlayerModal>
      )}

      {showEditSecond && (
        <EditPlayerModalNd
          data={data?.data}
          modalID="player-add"
          modalTitle={"Actualizar Datos "}
          modalOpen={showEditSecond}
          setOpenModal={() => setShowEditSecond(false)}
          setSelectFoot={setSelectFoot}
          isLoading={isLoadingSd}
          selectFoot={selectFoot}
          modalWhithDelay={2000}
          onModalClose={(desition: string) => showAnimation2(desition, 2000)}
          setFormValues={setFormValuesNd}
          update={() => showAnimation2("YES", 2000)}
        >
          <div></div>
        </EditPlayerModalNd>
      )}
      {showEditThird && (
        <EditPlayerModalRd
          data={data}
          positionData={positionData}
          modalID="player-add"
          modalTitle={"Actualizar Datos "}
          modalOpen={showEditThird}
          setOpenModal={() => setShowEditThird(false)}
          isLoading={updatePositionsLoading}
          modalWhithDelay={2000}
          onModalClose={(desition: string) => showAnimation(desition, 2000)}
          setFormValues={setFormValuesRd}
          update={() => showAnimation("YES", 2000)}
        >
          <div></div>
        </EditPlayerModalRd>
      )}
      <h1 className="text-4xl font-bold mb-10">Datos</h1>
      <div className="flex space-x-10">
        <div className="relative hover:bg-gray-400 w-40 hover:bg-opacity-20 rounded hover:shadow p-2 group transition-all transform duration-150 ease-in-out">
          <div className="group-hover:opacity-60">
            <label className="text-lg font-semibold">Fecha de Nacimiento</label>
            <h1>{data?.data?.birthdate}</h1>
            <div className="flex items-center space-x-2">
            </div>
            <label className="text-lg font-semibold">Equipo Actual</label>
            <h1>{data?.data?.current_team_name}</h1>
          </div>
          <ButtonPencil onClick={handleOpenEditFirst } />
        </div>
        <div className="relative hover:bg-gray-400 hover:bg-opacity-20 w-40 rounded hover:shadow p-2 group transition-all transform duration-150 ease-in-out">
          <label className="text-lg font-semibold">Altura</label>
          <h1>{data?.data?.height} cm</h1>
          <label className="text-lg font-semibold">Peso</label>
          <h1>{data?.data?.weight} kg</h1>
          <label className="text-lg font-semibold">Pie</label>
          <h1>{data?.data?.foot_name}</h1>
          <label className="text-lg font-semibold">Camiseta</label>
          <h1>{data?.data?.jersey_nro}</h1>

          <ButtonPencil onClick={handleOpenEditSecond} />
        </div>
        <div className="relative hover:bg-gray-400 hover:bg-opacity-20 w-40 rounded hover:shadow p-2 group transition-all transform duration-150 ease-in-out">
          <label className="text-lg font-semibold">Posición Primaria</label>
          <h1>
            {data?.map_positions_translate?.primary_code} |{" "}
            {data?.map_positions_translate?.primary_description}
          </h1>

          <label className="text-lg font-semibold">Secundaria</label>
          {data?.map_positions_translate?.secondary?.map((position) => (
            <h1 key={position.code}>
              {position.code} | {position.description}
            </h1>
          ))}

          <ButtonPencil onClick={handleOpenEditThird} />
        </div>
      </div>
    </div>
  );
}

export default PlayerData;
