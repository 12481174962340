import { PayloadAction } from "@reduxjs/toolkit";
import { LOGIN } from "../constants/authConstants";

const authInitialState = {
    userData: {
        UID: null,
        userName: null,
        userImg: null,
        userInfo: {
            country_code: "ES",
        },
    },
};

function authReducer(state = authInitialState, action: PayloadAction) {
    switch (action.type) {
        case LOGIN:
            return { ...state, userData: action.payload };
        default:
            return state;
    }

}

export default authReducer;
