import { Add } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";

//LOCALS
import toast from "../Toast";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import WizModal from "../WizModal";
import { AddImagesProfileForm } from "../Forms/ProfileForm/AddImagesProfileForm";
import { AddImagesHeaderForm } from "../Forms/ProfileForm/AddImagesHeaderForm";
import { useAtom } from "jotai";
import { showImageModal } from "../jotai/jotaiAtom";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  refreshData: () => void;
}

function PlayerImages({ refreshData }: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const route = useNavigate();
  const params = useParams();
  const playerId = params.id as any;

  const [isAdded, setIsAdded] = useState(false);
  const [isAddedBig, setIsAddedBig] = useState(false);

  //JOTAI
  const [showImage, setShowImage] = useAtom(showImageModal);

  //MODAL
  const [openImagesAddModal, setOpenImagesAddModal] = useState<boolean>();
  const [openImagesHeaderAddModal, setOpenImagesHeaderAddModal] =
    useState<boolean>(false);

  //IMAGE DATA
  const [imagesData, setImagesData] = useState<SetStateAction<any>>();
  const [imageUrlProfilePlayer, setImageUrlProfilePlayer] =
    useState<SetStateAction<any>>();
  const [imageUrlHeaderPlayer, setImageUrlHeaderPlayer] =
    useState<SetStateAction<any>>();

  //IMAGE RESPONSE
  const [editImagesResponse, setEditImagesResponse] =
    useState<SetStateAction<any>>();
  const [imagesResponse, setImagesResponse] = useState<SetStateAction<any>>();

  useEffect(() => {
    console.log("imageUrl", imageUrlProfilePlayer);
  }, [imageUrlProfilePlayer]);

  //ADD IMAGES PROFILE
  const { isLoading, mutate: mutateImages } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("img_profile", imageUrlProfilePlayer);

      return axios.post(`${BASE_URL}players`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setImagesResponse(result);
      },
      onError: (err: any) => {
        setImagesResponse(err.response?.data || err);
      },
    }
  );

  //ADD IMAGES HEADER
  const { mutate: updateImages, isLoading: isHeaderLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("img_header", imageUrlHeaderPlayer);
      return axios.post(`${BASE_URL}players`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setEditImagesResponse(result);
        refreshData();
        setIsAddedBig(!isAddedBig);
      },
      onError: (err: any) => {
        setEditImagesResponse(err.response?.data || err);
      },
    }
  );

  //ADD IMAGES
  const addImages = () => {
    //Add News
    try {
      if (imageUrlProfilePlayer !== "") {
        mutateImages();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addImagesGr = () => {
    //Add News
    try {
      if (imageUrlHeaderPlayer !== "") {
        updateImages();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //SHOW ANIMATION
  const showAnimation = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addImages();
    }
  };
  const showAnimationNd = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addImagesGr();
    }
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //IMAGE HEADER RESPONSE
  React.useEffect(() => {
    if (editImagesResponse?.data?.status === "ok") {
      notify("success", `Imagen Header Actualizada`);

      setTimeout(() => {
        dismiss();
        setShowImage(false);
      }, 1000);
    } else if (editImagesResponse?.data?.status === "error") {
      notify("error", `${editImagesResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [editImagesResponse]);

  //IMAGE PROFILE RESPONSE
  React.useEffect(() => {
    if (imagesResponse?.data?.status === "ok") {
      notify("success", `Imagen Perfil Actualizada`);

      setTimeout(() => {
        dismiss();
        setOpenImagesAddModal(false);
      }, 1000);
    } else if (imagesResponse?.data?.status === "error") {
      notify("error", `${imagesResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [imagesResponse]);

  return (
    <div className="px-6">
      <div className="flex items-center space-x-4">
        <button
          onClick={() => setOpenImagesAddModal(!openImagesAddModal)}
          className="flex items-center space-x-4 border shadow px-3 py-2 rounded-lg  bg-lime-400 hover:bg-lime-500  transition-all transform duration-150 ease-in-out"
        >
          <h1>Añadir Imagen Perfil</h1>
          <Add />
        </button>
      </div>

      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Imagen Grande"}
        modalOpen={openImagesAddModal}
        setOpenModal={(e) => setOpenImagesAddModal(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <h1>Imagen de Perfil</h1>
          <AddImagesProfileForm
            isAdded={isAdded}
            setIsAdded={setIsAdded}
            imageUrlPlayer={imageUrlProfilePlayer}
            setImageUrlPlayer={setImageUrlProfilePlayer}
            onSubmit={(data) => {
              setIsAdded(!isAdded);
            }}
            isLoading={isLoading}
            update={() => showAnimation("YES", 2000)}
          />
        </div>
      </WizModal>

      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Imagen"}
        modalOpen={showImage}
        setOpenModal={(e) => setShowImage(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationNd(desition, 2000)}
      >
        <div>
          <h1>Imagen de Cabecera</h1>
          <AddImagesHeaderForm
            isLoading={isHeaderLoading}
            isAdded={isAddedBig}
            setIsAdded={setIsAddedBig}
            imageUrlPlayer={imageUrlHeaderPlayer}
            setImageUrlPlayer={setImageUrlHeaderPlayer}
            update={() => showAnimationNd("YES", 2000)}
            onSubmit={(data) => {
              console.log(data);
            }}
          />
        </div>
      </WizModal>
    </div>
  );
}

export default PlayerImages;
