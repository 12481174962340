import React, { Fragment, SetStateAction, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//STYLES
import { WizSelectStyles } from "../../styles/WizSelectStyles";

interface Props {
  items: any;
  changeSelect: any;
  placeHolder: string;
  selectRef: any;
  isMultiple: boolean;
  value: any;
  defaultValue: any;
}

const WizSelect = ({
  items,
  changeSelect,
  placeHolder,
  selectRef,
  isMultiple = false,
  value,
  defaultValue,
}: Props) => {
  const [currentValue, setCurrentValue] = useState<SetStateAction<any>>(value);
  const animatedComponents = makeAnimated();

  const reformattedData = items?.map((data: any) => {
    return {
      value: data.itemID,
      label: data.itemImg ? (
        <div className="flex items-center">
          <img
            width={34}
            height={34}
            src={data.itemImg}
            alt=""
            className="flex-shrink-0 h-6 w-6 rounded-full"
          />
          <span className={" font-normal ml-3 block truncate"}>
            {data.itemDesc}
          </span>
        </div>
      ) : (
        data.itemDesc
      ),
    };
  });

  const setDefaultValue = () => {
    if (defaultValue) return reformattedData[defaultValue];
    if (reformattedData?.length === 1) return reformattedData[0];
    return null;
  };

  const setValue = () => {
    if ((reformattedData?.length === 1 || defaultValue) && !currentValue) {
      return reformattedData[0];
    }
    if (value) return currentValue;
    return null;
  };

  const clearValue = () => {
    setCurrentValue(null);
  };

  const onChangeFilters = (val: any, trigger: any) => {
    if (trigger.action === "clear") {
      return clearValue();
    }
    setCurrentValue(val);
    changeSelect(val);
  };

  useEffect(() => {
    return () => {
      clearValue();
    };
  }, []);

  useEffect(() => {
    setValue();
  }, [value]);

  return (
    <WizSelectStyles data-testid="WizSelect">
      <Fragment>
        <Select
          ref={selectRef}
          className="wiz-select"
          classNamePrefix="wiz-select"
          isDisabled={false}
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          // menuIsOpen={true}
          name={currentValue}
          defaultValue={setDefaultValue()}
          value={setValue()}
          placeholder={placeHolder ?? "All"}
          options={reformattedData}
          components={animatedComponents}
          onChange={(val, triger) => {
            onChangeFilters(val, triger);
          }}
          isMulti={isMultiple}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              // text: wizColors.black,
              //   primary25: wizColors.primary,
              //   primary: wizColors.primary,
            },
          })}
        />
      </Fragment>
    </WizSelectStyles>
  );
};

WizSelect.propTypes = {
  items: PropTypes.array,
  changeSelect: PropTypes.func,
  placeHolder: PropTypes.string,
  clearSelect: PropTypes.func,
  selectRef: PropTypes.object,
  isMultiple: PropTypes.bool,
  value: PropTypes.object,
  defaultValue: PropTypes.bool,
};

export default WizSelect;
