import React, { useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

// LOCALS
import loader from "../assets/animations/loader.json";

export const Loader = () => {
  useEffect(() => {
    document.body.style.cursor = "wait";

    return () => {
      document.body.style.cursor = "default";
    };
  }, []);

  return (
    <div className="p-24">
      <Player
        autoplay
        loop
        src={loader}
        style={{ height: "10em", width: "10em" }}
        speed={0.7}
      ></Player>
    </div>
  );
};
