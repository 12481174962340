import { Form, Formik } from "formik";
import React, { useState, useEffect, SetStateAction, useRef } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import FormData from "form-data";

//ICONS
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//LOCALS
import { ButtonStyles } from "../styles/ButtonStyles";
import { LoginFormStyles } from "../styles/LoginFormStyles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader } from "./Loader";
import toast from "../components/Toast";
import useLocalStorage from "../hooks/useLocalStorage";
import { store } from "../redux/store";
import { LOGIN } from "../redux/constants/authConstants";
import { BASE_URL } from "../api";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
interface Login {
  user: string;
  password: string;
}

const LoginForm = () => {
  const queryClient = useQueryClient();
  const [uid, setUid] = useLocalStorage("UID", "");
  const [userInfo, setUserInfo] = useLocalStorage("USER", "");
  const [formValues, setFormValues] = useState({
    user: "",
    password: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState<boolean>(false);
  const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false);
  const [loginResult, setLoginResult] = useState<SetStateAction<any>>(null);
  const [shake, setShake] = useState(false);

  const formatResponse = (res: any) => {
    return JSON.stringify(res, null, 2);
  };

  const { isLoading, mutate } = useMutation(
    async () => {
      const data = new FormData();
      data.append("_method", "POST");
      data.append("user", email);
      data.append("password", password);
      data.append("user_type", 2);
      return await axios.post(`${BASE_URL}login`, data);
    },
    {
      onSuccess: (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUid(result.data.result.token);
        setUserInfo(email);
        setLoginResult(result);

        store.dispatch({
          type: LOGIN,
          payload: {
            userData: result.data.result,
          },
        });
      },
      onError: (err: any) => {
        setLoginResult(err.response?.data || err);
      },
    }
  );

  const loginUser = () => {
    if (email !== "" && password !== "") {
      try {
        mutate();
      } catch (err) {
        setLoginResult(err);
      }
    }
  };

  const Schema = z.object({
    user: z.string().email(),
    password: z.string(),
  });

  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    if (loginResult?.data.status === "error") {
      notify("error", `${loginResult?.data.result.error_msg}`);

      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [loginResult]);

  //TOGGLE PASSWORD
  const switchShownC = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (uid) navigate("/");
  }, [uid]);

  const handleEmail = (e: string) => {
    setEmail(e);
  };
  const handlePassword = (e: string) => {
    setPassword(e);
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <LoginFormStyles>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="w-full h-100">
            <h2 className="text-2xl lg:text-4xl  font-bold text-black">
              Inicia Sesión
            </h2>
            <br/>
            <Formik<{ user: string; password: string }>
              innerRef={ref}
              initialValues={{
                user: "",
                password: "",
              }}
              // validationSchema={toFormikValidationSchema(Schema)}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                // setSubmitting(true);

                setFormValues({
                  user: values.user,
                  password: values.password,
                });

                setSubmitting(false);
                // resetForm();
                {
                  formValues.password && formValues.user
                    ? loginUser()
                    : console.log("Hello");
                }
                // loginUser();
                // mutate();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                handleChange,
                setFieldValue,
                submitForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ButtonStyles>
                    <label className="block text-gray-700">
                      Correo Electrónico
                    </label>
                    <input
                      type="email"
                      name="user"
                      onChange={(e) => handleEmail(e.target.value)}
                      onBlur={handleBlur}
                      value={email}
                      placeholder="Correo Electrónico"
                      className={`w-full px-4 py-3 rounded-lg bg-gray-200 ${
                        incorrectEmail
                          ? `shake border-2 border-red-400 focus:border-red-500`
                          : ""
                      } mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none`}
                    />
                  </ButtonStyles>
                  {incorrectEmail ? (
                    <div className="text-red-400 text-sm">
                      Usuario {formValues.user} no existe
                    </div>
                  ) : null}
                  {errors.user && (
                    <div className="text-red-400 text-lg">{errors.user}</div>
                  )}

                  <ButtonStyles className="relative">
                    <label className="block text-gray-700">Contraseña</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={(e) => handlePassword(e.target.value)}
                      onBlur={handleBlur}
                      value={password}
                      placeholder="Contraseña"
                      className={`w-full px-4 py-3 rounded-lg bg-gray-200 ${
                        incorrectPassword && shake
                          ? `shake border-2 border-red-400 focus:border-red-500`
                          : ""
                      } mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none`}
                    />
                    <div
                      className="form-control flex flex-row items-center my-2 space-x-2 absolute right-4 top-10 cursor-pointer "
                      onClick={switchShownC}
                    >
                      {!showPassword ? (
                        <div>
                          <VisibilityOffIcon />
                        </div>
                      ) : (
                        <div>
                          <RemoveRedEyeIcon />
                        </div>
                      )}
                    </div>
                  </ButtonStyles>
                  {errors.password && (
                    <div className="text-red-400 text-lg">
                      {errors.password}
                    </div>
                  )}
                  {incorrectPassword ? (
                    <div className="text-red-400 text-sm">Contraseña</div>
                  ) : null}
                  <div />

                  <button
                    type="submit"
                    disabled={email === "" || password === ""}
                    onClick={loginUser}
                    className={`w-full group text-center flex items-center text-xl justify-center  ${
                      email === "" || password === ""
                        ? "loginButton"
                        : "loginButtonActive"
                    }`}
                  >
                    <h2 className="text-2xl ">Iniciar Sesión</h2>
                  </button>
                  {/* <Button type="submit" onSubmit={submitForm}>
                    Iniciar Sesion
                  </Button> */}
                </form>
              )}
            </Formik>
          </div>
        )}
      </LoginFormStyles>
    </>
  );
};

export default LoginForm;
