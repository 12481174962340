import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useState } from "react";

//ICONS
import { Delete, Edit } from "@mui/icons-material";

//LOCALS
import toast from "../Toast";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { PerformanceForm } from "../Forms/PerformanceForm/PerformanceForm";
import WizModal from "../WizModal";
import { useNavigate, useParams } from "react-router-dom";
import { Performance } from "../../types/data";

interface Props {
  data: Performance[];
  refreshData: () => void;
  refetch: () => void;
}

function PlayerPerformance({ data, refreshData, refetch }: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const router = useNavigate();
  const params = useParams();
  const playerId = params.id as any;
  const [showPerformanceForm, setShowPerformanceForm] =
    useState<SetStateAction<any>>(false);
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>(false);
  const [updatedPerformanceData, setUpdatedPerformanceData] = useState<
    SetStateAction<any>
  >({});
  const [performanceResponse, setPerformanceResponse] =
    useState<SetStateAction<any>>();

  const [listRef] = useAutoAnimate<any>();

  //UPDATE PERFORMANCE
  const { isLoading, mutate } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("defending", updatedPerformanceData.defending);
      formData.append("dribbling", updatedPerformanceData.dribbling);
      formData.append("pace", updatedPerformanceData.pace);
      formData.append("passing", updatedPerformanceData.passing);
      formData.append("physicality", updatedPerformanceData.physicality);
      formData.append("shooting", updatedPerformanceData.shooting);

      return axios.post(`${BASE_URL}player.performance`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },

    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setPerformanceResponse(result);
        refetch();
      },
      onError: (err: any) => {
        setPerformanceResponse(err.response?.data || err);
      },
    }
  );

  const updatePerformance = () => {
    //Update Performance Data
    try {
      if (
        updatedPerformanceData.defending !== "" &&
        updatedPerformanceData.defending !== "" &&
        updatedPerformanceData.pace !== "" &&
        updatedPerformanceData.passing !== "" &&
        updatedPerformanceData.physicality !== "" &&
        updatedPerformanceData.shooting !== ""
      ) {
        mutate();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //UPDATE PERFORMANCE RESPONSE
  React.useEffect(() => {
    if (performanceResponse?.data?.result === 1) {
      notify(
        "success",
        `Rendimiento Actualizado
      `
      );

      setTimeout(() => {
        dismiss();
        setShowPerformanceForm(false);
      }, 1000);
    } else if (performanceResponse?.data?.status === "error") {
      notify("error", `${performanceResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [performanceResponse]);

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      updatePerformance();
    }
  };

  return (
    <div className="flex space-x-2">
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Rendimiento"}
        modalOpen={showPerformanceForm}
        setOpenModal={(e) => setShowPerformanceForm(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <PerformanceForm
            isLoading={isLoading}
            data={data}
            onSubmit={(updateData) => setUpdatedPerformanceData(updateData)}
            update={() => showAnimation("YES", 2000)}
          />
        </div>
      </WizModal>
      <div ref={listRef}>
        {data?.map((performance) => (
          <div
            key={performance.defending}
            className="p-4 border shadow flex space-x-10"
          >
            <div>
              <label className="font-bold text-lg">Ritmo</label>
              <h1>{performance.pace}</h1>
            </div>
            <div>
              <label className="font-bold text-lg">Defensas</label>
              <h1>{performance.defending}</h1>
            </div>
            <div>
              <label className="font-bold text-lg">Tiros</label>
              <h1>{performance.shooting}</h1>
            </div>
            <div>
              <label className="font-bold text-lg">Regateos</label>
              <h1>{performance.dribbling}</h1>
            </div>
            <div>
              <label className="font-bold text-lg">Pases</label>
              <h1>{performance.passing}</h1>
            </div>
            <div>
              <label className="font-bold text-lg">Físico</label>
              <h1>{performance.physicality}</h1>
            </div>
      <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
      <Edit onClick={() => setShowPerformanceForm(!showPerformanceForm)}/>
      </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlayerPerformance;
