import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Add } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";

//LOCALS
import toast from "../Toast";
import { BASE_URL } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { AddNewsForm } from "../Forms/NewsForm/AddNewsForm";
import WizModal from "../WizModal";
import { ButtonPencil } from "../button/ButtonPencil";
import EditNewsForm from "../Forms/NewsForm/EditNewsForm";
import { useAppSelector } from "../../redux/hooks";
import { News } from "../../types/data";
import { useNavigate } from "react-router-dom";

interface NewsProps {
  text: string;
  title: string;
  date: string;
  lang?: string;
}

interface Props {
  data: News[];
}

function PlayerNews({ data }: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const route = useNavigate();
  const playerId = "10";
  const filters = useAppSelector((state) => state.player);
  const [openNewsAddModal, setOpenNewsAddModal] =
    useState<SetStateAction<any>>();
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>(false);
  const [newsData, setNewsData] = useState<NewsProps>({
    text: "",
    title: "",
    date: "",
    lang: "",
  });

  const [newsResponse, setNewsResponse] = useState<SetStateAction<any>>(false);
  const [showEditNewsModal, setShowEditNewsModal] =
    useState<SetStateAction<any>>(false);
  const [editNewsResponse, setEditNewsResponse] =
    useState<SetStateAction<any>>();

  const [listRef] = useAutoAnimate<HTMLDivElement>();


  const { isLoading: mutateLoading, mutate } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("player_id", playerId);
      formData.append("country_code", filters.filters.nationality);
      formData.append("date", newsData.date);
      formData.append("text", newsData.text);
      formData.append("title", newsData.title);


      return axios.post(`${BASE_URL}news`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setNewsResponse(result);
      },
      onError: (err: any) => {
        setNewsResponse(err.response?.data || err);
      },
    }
  );

  const { mutate: updateNews, isLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("news_text", newsData.text);
      formData.append("news_title", newsData.title);

      return axios.post(`${BASE_URL}news`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setEditNewsResponse(result);
      },
      onError: (err: any) => {
        setEditNewsResponse(err.response?.data || err);
      },
    }
  );

  const addNews = () => {
    //Add News
    try {
      mutate();
    } catch (err) {
      console.log(err);
    }
  };

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addNews();
    }
    // await onEditModeDelete();
  };
  const showAnimationNd = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);
    if (desition !== "NO") {
      updateNews();
    }
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  React.useEffect(() => {
    if (newsResponse?.data?.result === 1) {
      notify("success", `Noticia Añadida`);

    } else if (newsResponse?.data?.status === "error") {
      notify("error", `${newsResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [newsResponse]);

  return (
    <div className="space-y-6">
      <button
        onClick={() => route("/news")}
        className="flex items-center space-x-4 border shadow px-3 ml-3 py-2 rounded-lg  bg-lime-400 hover:bg-lime-500  transition-all transform duration-150 ease-in-out"
      >
        Noticias
        <Add />
      </button>
      <WizModal
        modalID="player-add"
        modalTitle={"Editar Noticia"}
        modalOpen={showEditNewsModal}
        setOpenModal={(e) => setShowEditNewsModal(false)}
        modalType={"DESITION"}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationNd(desition, 2000)}
      >
        <div>
          <EditNewsForm
            onSubmit={(data) => console.log(data)}
            itemNews={""}
            rowsId={""}
            refreshData={function (): void {
              throw new Error("Function not implemented.");
            }}
            update={() => showAnimationNd("YES", 2000)}
          />
        </div>
      </WizModal>
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Noticia"}
        modalOpen={openNewsAddModal}
        setOpenModal={(e) => setOpenNewsAddModal(false)}
        modalType={"DESITION"}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <AddNewsForm
            onSubmit={(data) => setNewsData(data)}
            update={() => showAnimation("YES", 2000)}
            isLoading={mutateLoading}
          />
        </div>
      </WizModal>
      <div ref={listRef} className="space-y-6">
        {data?.map((news: News) => (
          <div key={news.news_id} className="p-4 border shadow group ">
            <ButtonPencil
              onClick={() => setShowEditNewsModal(!showEditNewsModal)}
            />
            <h1 className="font-bold text-lg">{news.title}</h1>
            <h1>{news.text}</h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlayerNews;
