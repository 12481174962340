import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Add, Delete } from "@mui/icons-material";
import axios from "axios";

//LOCALS
import { AddVideoForm } from "../Forms/VideoForm/AddVideoForm";
import useLocalStorage from "../../hooks/useLocalStorage";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import DeleteConfirm from "../Errors/DeleteConfirm";
import DeletedItem from "../Errors/DeletedItem";
import toast from "../../components/Toast";
import YoutubeEmbed from "./YoutubeEmbed";
import { BASE_URL } from "../../api";
import WizModal from "../WizModal";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa";

interface Props {
  data: string[];
  refreshData: () => void;
}

interface PlayerVideoProps {
  player_id: string;
  player_video_id: string;
  time: string;
  url: string;
}

function PlayerVideos({ data, refreshData }: Props) {
  const [listRef] = useAutoAnimate<HTMLDivElement>();
  const [uid, setUid] = useLocalStorage("UID", "");
  const params = useParams();
  const router = useNavigate();
  const playerId = params.id as any;

  const [formValues, setFormValues] = useState({ url: "", time: "" });
  const [itemSelected, setItemSelected] = useState<SetStateAction<any>>(null);

  //STATE RESPONSE
  const [addVideoGalleryResponse, setAddVideoGalleryResponse] =
    useState<SetStateAction<any>>();
  const [deleteVideoResponse, setDeleteVideoResponse] =
    useState<SetStateAction<any>>();

  //STATE MODAL
  const [openDeleteNewsModal, setOpenDeleteNewsModal] =
    useState<SetStateAction<any>>();
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [showVideoModal, setShowVideoModal] =
    useState<SetStateAction<any>>(false);

  //GET VIDEOS GALLERY
  const getVideos = async () => {
    const { data } = await axios.get(
      `${BASE_URL}player.video?player_id=${playerId}`,
      {
        headers: {
          Authorization: "Bearer " + uid,
        },
      }
    );
    return data.result.result;
  };
  const {
    data: videosData,
    isLoading: isVideosLoading,
    refetch,
  } = useQuery<PlayerVideoProps[]>(["playerVideo"], getVideos);

  useEffect(() => {
    console.log("videosData", videosData);
  }, [videosData]);

  //ADD VIDEOS GALLERY
  const addVideosGallery = async (data: any) => {
    let urlData = data.url;
    if (data.url?.includes?.("youtube.com/shorts")) {
      urlData = data.url.split("?")[0].replace("shorts/", "watch?v=");
    }
    const formData = new FormData();
    formData.append("_method", "POST");
    formData.append("player_id", playerId);
    formData.append("url", urlData);
    formData.append("time", data.time);
    const response = await fetch(`${BASE_URL}player.video`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + uid,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setAddVideoGalleryResponse(data);
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  // const { isLoading, mutate: addVideosGallery } = useMutation(
  //   async () => {
  //     const formData = new FormData();
  //     formData.append("_method", "POST");
  //     formData.append("player_id", playerId);
  //     formData.append("url", formValues.url);
  //     formData.append("time", formValues.time);

  //     return await axios.post(`${BASE_URL}player.video`, formData, {
  //       headers: {
  //         Authorization: "Bearer " + uid,
  //       },
  //     });
  //   },
  //   {
  //     onSuccess: async (res) => {
  //       const result = {
  //         status: res.status + "-" + res.statusText,
  //         headers: res.headers,
  //         data: res.data,
  //       };
  //       setAddVideoGalleryResponse(result);
  //       refetch();
  //     },
  //     onError: (err: any) => {
  //       setAddVideoGalleryResponse(err.response.data || err);
  //     },
  //   }
  // );

  //REMOVE VIDEOS GALLERY
  // const { isLoading: isDeleteVideosLoading, mutate: deleteVideosGallery } =
  //   useMutation(
  //     async () => {
  //       return await axios.delete(
  //         `${BASE_URL}player.video?player_video_id=${itemSelected}`,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + uid,
  //           },
  //         }
  //       );
  //     },
  //     {
  //       onSuccess: async (res) => {
  //         const result = {
  //           status: res.status + "-" + res.statusText,
  //           headers: res.headers,
  //           data: res.data,
  //         };
  //         setDeleteVideoResponse(result);
  //         setTimeout(() => {
  //           refetch();
  //         }, 1000);
  //       },
  //       onError: (err: any) => {
  //         setDeleteVideoResponse(err.response.data || err);
  //       },
  //     }
  //   );

  const deleteVideoGallery = async () => {
    const response = await fetch(
      `${BASE_URL}player.video?player_video_id=${itemSelected}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + uid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setDeleteVideoResponse(data);
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  //ADD VIDEO
  const addVideo = (data: any) => {
    console.log("data", data);
    try {
      if (
        data.url.includes("www.youtube.com/watch?v=") ||
        data.url.includes("www.youtube.com/shorts/")
      ) {
        addVideosGallery(data);
        console.log("url", data.url);
      } else {
        toast({ type: "error", message: "Url is incorrect" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //DELETE VIDEO
  const deleteVideo = () => {
    try {
      deleteVideoGallery();
      // deleteVideosGallery();
    } catch (err) {
      console.log(err);
    }
  };

  //SHOW ANIMATION
  const showAnimationDelete = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deleteVideo();
    }
  };

  const showAnimation = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addVideo(data);
    }
  };

  //TOAST
  const notify = useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = useCallback(() => {
    toast.dismiss();
  }, []);

  //ADD IMAGE RESPONSE
  React.useEffect(() => {
    if (addVideoGalleryResponse?.data?.result.playerVideoAdded === 1) {
      notify("success", `Video Añadido`);

      setTimeout(() => {
        dismiss();
        setShowVideoModal(false);
      }, 1000);
    } else if (addVideoGalleryResponse?.data?.status === "error") {
      notify("error", `${addVideoGalleryResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [addVideoGalleryResponse]);

  //DELETED IMAGE RESPONSE
  useEffect(() => {
    if (deleteVideoResponse?.data?.result.videoDeleted === 1) {
      notify("success", `Video Eliminado`);

      setTimeout(() => {
        dismiss();
      }, 1000);
    } else if (deleteVideoResponse?.data?.status === "error") {
      notify("error", `${deleteVideoResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deleteVideoResponse]);

  return (
    <div ref={listRef} className="space-y-10">
      <button
        onClick={() => setShowVideoModal(!showVideoModal)}
        className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 ml-2 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
      >
        Añadir Video Nuevo
        <Add />
      </button>
      <WizModal
        modalID="player-add"
        modalTitle={"Añadir Video"}
        modalOpen={showVideoModal}
        setOpenModal={(e) => setShowVideoModal(false)}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimation(desition, 2000)}
        // onModalClose={(desition) => showAnimation(desition, 2000)}
      >
        <div>
          <AddVideoForm
            onSubmit={(data) => addVideo(data)}
            // update={() => showAnimation("YES", 2000)}
          />
        </div>
      </WizModal>
      <WizModal
        modalID="gallery-delete"
        modalTitle={"Borrar Noticia"}
        modalOpen={openDeleteNewsModal}
        setOpenModal={(e) => setOpenDeleteNewsModal(false)}
        modalType={"DESITION"}
        modalWhithDelay={2000}
        onModalClose={(desition) => showAnimationDelete(desition, 2000)}
      >
        <div>
          {showDeleteAnimation === "YES" ? (
            <DeletedItem title={"Este video"} />
          ) : (
            <DeleteConfirm title={"el video"} />
          )}
        </div>
      </WizModal>
      {!isVideosLoading &&
        videosData?.map((video) => {
          return (
            <div key={video.url} className="flex  space-x-10">
              {video.url.includes("www.youtube.com/watch?v=") && (
                <YoutubeEmbed
                  key={video.player_video_id}
                  embedID={video.url?.split("v=")[1].split("&")[0]}
                />
              )}
              <div>
                <div
                  onClick={() => {
                    setItemSelected(video.player_video_id);
                    setOpenDeleteNewsModal(!openDeleteNewsModal);
                  }}
                  className="flex items-center cursor-pointer  hover:shadow  px-4 py-2 hover:rounded-full hover:bg-red-100 transition-all transform duration-150 ease-in-out"
                >
                  <FaTrash />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PlayerVideos;
