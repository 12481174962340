import { Form, Formik, Field } from "formik";
import * as React from "react";
import { Loader } from "../../Loader";

//LOCALS
import { MyField } from "../../MyField";
import CountryFilter from "../../select/NationalityFilter";
import { BASE_URL_IMG } from "../../../api";

interface Values {
  title: string;
  text: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

interface AddMainNewsProps {
  title: string;
  text: string;
  date: string;
  url: string;
  keywords: string;
  lang?: string;
}

export const AddMainNewsForm: React.FC<Props> = ({
  onSubmit,
  update,
  isLoading,
}) => {
  if (!isLoading)
    return (
      <Formik<AddMainNewsProps>
        initialValues={{
          title: "",
          text: "",
          date: "",
          url: "",
          keywords: "",
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          update();
        }}
      >
        {({ values, handleBlur, handleChange, isSubmitting }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4">
              <CountryFilter />
              <div className="flex  items-center space-x-6">
                <div>
                  <Field
                    name="title"
                    placeholder="Título de la Noticia"
                    component={MyField}
                  />
                </div>
                <div>
                  <Field
                    name="date"
                    placeholder="Fecha de la Noticia"
                    component={MyField}
                  />
                </div>
              </div>
              <div className="flex items-center space-x-6">
                <div>
                  <Field
                    name="url"
                    placeholder="Enlace de la Noticia"
                    component={MyField}
                  />
                </div>
                <div>
                  <Field
                    name="keywords"
                    placeholder="Keywords para buscar"
                    component={MyField}
                  />
                </div>
              </div>
              <div>
                <textarea
                  className="w-full h-40 px-4 py-2 border"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="text"
                  placeholder="Descripción de la noticia"
                  value={values.text}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between items-center space-x-4">
              <button
                disabled={isSubmitting}
                type="submit"
                className="py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800 flex items-center"
              >
                Añadir Noticia Nueva
              </button>
              <img
                src={BASE_URL_IMG+"flags/es.svg"} 
                alt=""
                style={{maxWidth:'45px' , marginBottom:'5px', borderWidth: '1px', borderColor:'black'}}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
