import { useEffect, useState } from "react";
import Select from "react-select";
import { useAtom } from "jotai";
import { FaHome } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import useLocalStorage from "../hooks/useLocalStorage";
import { selectedType } from "./jotai/jotaiAtom";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { BASE_URL } from "../api";
import logo from "../assets/images/logo.png";

type Option = {
  value: string;
  label: string;
};

export const options: Option[] = [
  { value: "jugadores", label: "Jugadores" },
  { value: "coaches", label: "Entrenadores" },
  { value: "teams", label: "equipos" },
  { value: "noticias", label: "noticias" },
];

export default function Header() {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [userInfo, setUserInfo] = useLocalStorage("USER", "");
  const [userDetails, setUserDetails] = useState<any>([]);

  const [selectType, setSelectType] = useAtom(selectedType);
  const navigate = useNavigate();
  const location = useLocation();

  const executeLoginDetails = () => {
    fetch(`${BASE_URL}user?user=${userInfo}`, {
      method: "GET",

      headers: {
        Authorization: `Bearer ${uid}`,
      },
    })
      .then((res) => res.json())
      .then((res) => setUserDetails(res.result[0]))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    executeLoginDetails();
  }, []);

  useEffect(() => {
    setSelectType(options[0]);
  }, []);

  const handleChange = (event: any) => {
    setSelectType(event);
  };

  const handleLogout = () => {
    if (uid) setUid("");
    navigate("/login");
  };

  const handleJugadoresClick = () => {

    if (location.pathname !== "/" ) {
      navigate("/");
      setTimeout(() => {
        setSelectType(options[0]);
      }, 1);
    } else  {
      setSelectType(options[0]);
    }
  };
  const handleCoachClick = () => {

    if (location.pathname !== "/"  ) {
      navigate("/");
      setTimeout(() => {
        setSelectType(options[1]);
      }, 1);
    } else  {
      setSelectType(options[1]);
    }
  };

  

  return (
    <header>
      <div className="sticky w-screen items-center top-0 p-4 flex shadow justify-center bg-white">
          <div className=" max-w-[1350px] flex justify-between w-full">
            <button
              onClick={() => navigate("/")}
              type="button"
              /*               className="py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg   bg-blue-200 dark:text-black dark:border-gray-600 hover:bg-blue-300 dark:hover:text-gray-800 flex items-center" */
            >
              {/*               <h1 className="font-sans font-bold">Home</h1>
              <FaHome size={24} className="ml-2" /> */}
              <img style={{ width: 80 }} src={logo} alt="" />
            </button>

            <button
              onClick={handleJugadoresClick}
              style={{ marginRight: "20px", marginLeft: "20px" }}
              className="text-lg font-bold block py-2 pl-3 pr-4 dark:text-black md:dark:hover:text-lime-500 md:dark:hover:bg-transparent"
            >
              Jugadores
            </button>
            <button
              onClick={handleCoachClick}
              className="text-lg font-bold block py-2 pl-3  dark:text-black md:dark:hover:text-lime-500 md:dark:hover:bg-transparent"
              style={{ marginRight: "20px" }}
            >
              Entrenadores
            </button>
            <button
              onClick={() => navigate("/teams")}
              className="text-lg font-bold block py-2 pl-3 pr-4 dark:text-black md:dark:hover:text-lime-500 md:dark:hover:bg-transparent"
              style={{ marginRight: "20px" }}
            >
              Equipos
            </button>
            <button
              className="text-lg font-bold block py-2 pl-3 dark:text-black md:dark:hover:text-lime-500 md:dark:hover:bg-transparent"
              onClick={() => navigate("/news")}
            >
              Noticias
            </button>

            <div className="flex space-x-10 items-center justify-self-end ml-auto">
              <div className="text-lg font-bold">{userDetails?.fullname}</div>
              <button
                onClick={handleLogout}
                className="h-10 w-10 rounded-full hover:bg-lime-500 transition-all transform duration-150 ease-in-out bg-lime-400 cursor-pointer  shadow text-center flex items-center justify-center "
              >
                <IoMdLogOut />
              </button>
            </div>
          </div>
        </div>
    </header>
  );
}
