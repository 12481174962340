import { Form, Formik, Field } from "formik";
import * as React from "react";
import { MyField } from "../MyField";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

//LOCALS
import CountryFilter from "../select/NationalityFilter";
import SecondNationalityFilter from "../select/SecondNationalityFilter";
import { Loader } from "../Loader";

interface Values {
  firstName: string;
  lastName: string;
  birthdate: string;
  category: string;
  current_team: string;
  current_team_name: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

export const MyCoachForm: React.FC<Props> = ({
  onSubmit,
  update,
  isLoading,
}) => {
  const Schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    birthdate: z.string(),
    category: z.string(),
    current_team: z.string(),
    current_team_name: z.string(),
  });

  interface AddCoachFormData {
    firstName: string;
    lastName: string;
    birthdate: string;
    category: string;
    current_team: string;
    current_team_name: string;
  }

  if (!isLoading)
    return (
      <Formik<AddCoachFormData>
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          firstName: "",
          lastName: "",
          birthdate: "",
          category: "",
          current_team: "",
          current_team_name: "",
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values);
          setSubmitting(false);
          update();
          // resetForm();
        }}
      >
        {({ values, errors, isSubmitting, submitForm }) => (
          <Form>
            <div className="flex flex-col justify-between">
              <div className="flex flex-col justify-start space-y-2 mb-4 ">
                <CountryFilter />
              </div>
              <div className="flex flex-col justify-start space-y-2 mb-4 ">
                <SecondNationalityFilter />
              </div>
              <div className="flex justify-between">
                <div className="flex-col items-start">
                  <Field
                    name="firstName"
                    placeholder="Nombre"
                    component={MyField}
                  />
                  {errors.firstName && (
                    <div className="text-sm text-left text-red-400">
                      {errors.firstName}
                    </div>
                  )}
                </div>
                <div>
                  <Field
                    name="lastName"
                    placeholder="Apellido"
                    component={MyField}
                  />
                  {errors.lastName && (
                    <div className="text-sm text-left text-red-400">
                      {errors.lastName}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between my-4">
                <div>
                  <Field
                    name="birthdate"
                    placeholder="Nacimiento/yyyy-mm-dd"
                    component={MyField}
                  />
                  {errors.birthdate && (
                    <div className="text-sm text-left text-red-400">
                      {errors.birthdate}
                    </div>
                  )}
                </div>
                <div>
                  <Field
                    name="current_team"
                    placeholder="Equipo Actual"
                    component={MyField}
                  />
                  {errors.current_team && (
                    <div className="text-sm text-left text-red-400">
                      {errors.current_team}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between"></div>
            </div>
            <button
              className="py-2.5 px-10 mr-2 mt-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg   bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800"
              disabled={isSubmitting}
              type="submit"
              onClick={submitForm}
            >
              Añadir Coach
            </button>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
