import * as React from "react";
import { FieldProps } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

export const MyField: React.FC<FieldProps & TextFieldProps> = ({
  placeholder,
  field,
}) => {
  return (
    <TextField
      sx={{
        // width: { sm: 20, md: 200 },
        "& .MuiInputBase-root": {
          height: 50,
          padding: 2,
        },
      }}
      placeholder={placeholder}
      {...field}
    />
  );
};
