import React, { useEffect } from "react";
import {
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { selectedType } from "../components/jotai/jotaiAtom";
import { MyTable } from "../components/MyTable";
import  {MyCoachTable}  from "../components/MyCoachTable";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Loader } from "../components/Loader";
import useLocalStorage from "../hooks/useLocalStorage";
import { BASE_URL } from "../api";
import { players } from "../types/data";

const getPlayers = async () => {
  const { data } = await axios.get(`${BASE_URL}players?countryid=ES`);

  return data;

};
const getCoaches = async () => {
  const { data } = await axios.get(`${BASE_URL}coaches?countryid=ES`);

  return data;

};


function Home() {
  const navigate = useNavigate();

  const [uid, setUid] = useLocalStorage("UID", "");
  const [selectType, setSelectType] = useAtom(selectedType);
  useEffect(() => {
    if (!uid || uid === "") {
      navigate("/login");
    }
  }, [uid]);

  const { data: playerData, status: statusPlayer } = useQuery<players[]>(
    ["players"],
    getPlayers
  );

  const { data: coachData, status: statusCoaches } = useQuery<players[]>(
    ["coaches"],
    getCoaches
  );
  
  return (
    <>
      {statusPlayer === "loading" && statusCoaches === "loading" ? (
        <div className="h-screen bg-white flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <Header />
          <div
            className="flex items-start h-[89vh]"
            style={{ textAlign: "center", flex: 1, backgroundColor:'#f3f3f4'  }}
          >
            {/* <div>
              <Sidebar
                data={selectType.value === "jugadores" ? playerData : coachData}
              />
            </div> */}
            <div className="flex flex-col items-start mx-auto">
              {selectType.value === "jugadores" ? (
                <MyTable data={playerData} />
              ) :  (
                <MyCoachTable data={coachData} />
              )}
              <div>
                {/* <button onClick={() => navigate("/news")}>
                  <div className="flex items-center space-x-4 my-4 cursor-pointer group hover:bg-blue-200 p-4 rounded-lg shadow transition-all transform duration-150 ease-in-out">
                    <h1 className="text-4xl font-bold  group-hover:text-white drop-shadow-lg">
                      Ver Noticias
                    </h1>
                    <div className="group-hover:translate-x-2 transition-all transform duration-150 ease-in-out">
                      <FaArrowRight fontSize="large" />
                    </div>
                  </div>
                </button> */}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default Home;
