import React, { useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

// LOCALS
import deletedItem from "../../assets/animations/deletedItem.json";

// STYLES

function DeletedItem({ title }: string | any) {
  return (
    <div>
      <Player
        autoplay
        loop
        src={deletedItem}
        style={{ height: "15vh", width: "15vh" }}
        speed={0.7}
      ></Player>
      <p className="text-center font-bold">
        {title} ha sido eliminado
      </p>
    </div>
  );
}

export default DeletedItem;
