import { Form, Formik, Field } from "formik";
import * as React from "react";

//LOCALS
import CountryFilter from "../../select/NationalityFilter";
import { MyField } from "../../MyField";
import { Loader } from "../../Loader";

interface Values {
  bio: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  update: any;
  isLoading: boolean;
}

export const AddBioForm: React.FC<Props> = ({
  onSubmit,
  update,
  isLoading,
}) => {
  if (!isLoading)
    return (
      <Formik<{ bio: string }>
        initialValues={{
          bio: "",
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values);
          setSubmitting(false);
          update();
        }}
      >
        {({ values, isSubmitting, submitForm }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4">
              <div>
                <Field name="bio" placeholder="Biografía" component={MyField} />
              </div>
              <div>
                <CountryFilter />
              </div>
              <div className="h-20" />
            </div>
            <button  className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " disabled={isSubmitting} type="submit" onClick={submitForm}>
              Añadir Bio
            </button>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
