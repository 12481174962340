import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { Add, Edit } from "@mui/icons-material";

//LOCALS
import { BASE_URL, BASE_URL_IMG } from "../../api";
import wizTimeOutFun from "../../utils/WizTimeOutFun";
import WizModal from "../WizModal";
import { AddTeamForm } from "./AddTeamForm";
import { useAppSelector } from "../../redux/hooks";
import toast from "../Toast";

//ICONS
import SearchBar from "material-ui-search-bar";
import TrashIcon from "@mui/icons-material/Delete";
import useLocalStorage from "../../hooks/useLocalStorage";
import { NewsProps } from "../../types/data";

import EditTeamForm from "./EditTeamForm";
import DeletedItem from "../Errors/DeletedItem";
import DeleteConfirm from "../Errors/DeleteConfirm";
import defaultImage from "../../assets/images/logo_shadow.png";

interface HeadCell {
  disablePadding: boolean;
  id: keyof RealTeams;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RealTeams
  ) => void;
  order: Order;
  orderBy: keyof RealTeams;
  rowCount: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "img_url",
    disablePadding: false,
    numeric: false,
    label: "Escudo",
  },
  {
    id: "name",
    disablePadding: false,
    numeric: false,
    label: "Nombre",
  },
];

interface RealTeams {
  name: string;
  team_id: string;
  img_url: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

function TeamsData({ data }: any) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [TeamsData, setTeamsData] = useState<{ name: string; img_url: string }>(
    {
      name: "",
      img_url: "",
    }
  );

  const [itemDeleted, setItemDeleted] = useState();
  const [openDeleteNewsModal, setOpenDeleteNewsModal] =
    useState<SetStateAction<any>>();
  const [showDeleteAnimation, setShowDeleteAnimation] =
    useState<SetStateAction<any>>();
  const [deleteTeamResponse, setdeleteTeamResponse] =
    useState<SetStateAction<any>>();

  //EDIT NEWS STATE
  const [editTeamsResponse, seteditTeamsResponse] =
    useState<SetStateAction<any>>();

  //ROWS
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [rows, setRows] = useState<RealTeams[]>(data);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RealTeams>("team_id");

  const [animationParent] = useAutoAnimate<any>();
  const [rowsId, setRowsId] = useState<SetStateAction<any>>();
  const [itemNews, setItemNews] = useState<string | undefined>();

  //MAIN NEWS
  const [showEditItemNewsModal, setShowEditItemNewsModal] =
    useState<boolean>(false);
  const [showAddTeamModal, setshowAddTeamModal] = useState<boolean>(false);
  const [mainteamsFormData, setMainteamsFormData] = useState<{
    img_url: any;
    name: any;
  }>({
    img_url: "",
    name: "",
  });

  const [showAddNewsModal, setShowAddNewsModal] =
    useState<SetStateAction<any>>();
  const [teamsFormData, setteamsFormData] = useState<{
    name: string;
    img_url: string;
  }>({ name: "", img_url: "" });
  const filters = useAppSelector((state) => state.player);

  //CREATE RESPONSE
  const [createTeamResponse, setCreateTeamResponse] =
    useState<SetStateAction<any>>();
  const [createMainTeamResponse, setCreateMainTeamResponse] =
    useState<SetStateAction<any>>();

  const [searched, setSearched] = useState<string>("");

  //REFRESH PAGE
  const refreshPage = () => {
    window.location.reload(); 
  };

  //AÑADIR NUEVAS NOTICIAS RESPONSE
  useEffect(() => {
    if (createMainTeamResponse?.data?.result === 1) {
      notify("success", "Nueva Noticia Añadida");
      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1500);
    } else if (createMainTeamResponse?.data?.status === "error") {
      notify("error", `${createMainTeamResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createMainTeamResponse]);

  //EDIT NEWS ROW
  const { isLoading, mutate: editteamsRow } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("news_text", TeamsData?.name);
      formData.append("news_title", TeamsData?.img_url);
      return await axios.post(`${BASE_URL}teams`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };

        seteditTeamsResponse(result);
      },
      onError: (err: any) => {
        seteditTeamsResponse(err.response.data || err);
      },
    }
  );

  //AÑADIR TEAM
  const { mutate: addMutateTeamsCD, isLoading: isLoadingMutate } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "POST");
      formData.append("team_name", mainteamsFormData.name);
      formData.append("image", mainteamsFormData.img_url);
      return await axios.post(`${BASE_URL}teams`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        console.log(mainteamsFormData.img_url);
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setCreateTeamResponse(result);
        console.log("R: ",  result);
      },
      onError: (err: any) => {
        setCreateTeamResponse(err.response.data || err);
      },
    }
  );

  const addNewCd = () => {
    try {
      if (teamsFormData.name !== "" && teamsFormData.img_url !== "")
        addMutateTeamsCD();
    } catch (err) {
      console.log(err);
    }
  };

  // GET TEAMS BY ID
  const getTeamsById = async () => {
    if (rowsId === undefined) {
      return null;
    }
    const { data } = await axios.get(`${BASE_URL}teams?team_id=${rowsId}`);
    return data;
  };

  const { data: teamsRow } = useQuery<
    { team_id: string; name: string; img_url: string }[]
  >(["teamsById", rowsId], getTeamsById);

  //DELETE TEAM
  const deleteNews = () => {
    try {
      if (itemDeleted !== "") {
        deleteTeam(itemDeleted);
      }
    } catch (err) {
      console.error("Error deleting team:", err);
    }
  };

  //SHOW ANIMATION
  const showAnimation = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      editTeams();
    }
  };
  const showAnimationAdd = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addNewCd();
    }
  };
  const showAnimationAddMain = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
    }
  };
  const showAnimationCD = async (desition: string, ms: number) => {
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      addNewCd();
    }
  };
  const showAnimationDelete = async (desition: string, ms: number) => {
    await setShowDeleteAnimation(desition);
    await wizTimeOutFun(ms);

    if (desition !== "NO") {
      deleteNews();
    }
  };

  //DELETE NEWS
  const showHiddenDeleteComponent = () => {
    setOpenDeleteNewsModal(true);
    setItemDeleted(rowsId);
  };

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);
  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);
console.log("RR;",createTeamResponse?.data?.result);
  //ADD TEAM RESPONSE
  React.useEffect(() => {
    if (createTeamResponse?.data?.status === "ok") {
      notify("success", `Equipo Añadido`);
      setTimeout(() => {
        dismiss();
        refreshPage();
      }, 1000);
    } else if (createTeamResponse?.data?.status === "error") {
      notify("error", `${createTeamResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [createTeamResponse]);

  //EDIT Teams
  const editTeams = () => {
    try {
      if (TeamsData?.name !== "" && TeamsData?.img_url) {
        editteamsRow();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //DELETE NEWS RESPONSE
  useEffect(() => {
    if (deleteTeamResponse?.data?.result?.newsDeleted === 1) {
      notify("success", `Equipo Eliminado`);
      setTimeout(() => {
        refreshPage();
      }, 3000);
    } else if (deleteTeamResponse?.data?.status === "error") {
      notify("error", `${deleteTeamResponse?.data?.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [deleteTeamResponse]);

  //HANDLE REQUEST SORT
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RealTeams
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //HANDLE CLICK
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  //CHANGE PAGE
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, rowCount, onRequestSort, numSelected } = props;

    const createSortHandler =
      (property: keyof RealTeams) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  //SEARCH
  const requestSearch = (searchVal: string) => {
    const filteredRows = data.filter((row: any) => {
      return row.name.toLowerCase().includes(searchVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const { mutate: deleteTeam } = useMutation(
    async (team_id) => {
      await axios.delete(`${BASE_URL}teams?team_id=${team_id}`, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: () => {
      },
      onError: (error) => {
        console.log("aaaaaaaa");
      },
    }
  );

  useEffect(() => {
    if (showDeleteAnimation === "YES") {
      deleteTeam(rowsId);
    }
  }, [showDeleteAnimation, rowsId]);
  return (
    <Box sx={{ height: "1000px", width: "1500px", marginBottom: "30px" }}>
      <div
        className="mt-20"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ width: "500px" }}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="Buscar Equipo"
          />
        </div>
        <button
          onClick={() => setshowAddTeamModal(!showAddTeamModal)}
          className="py-2.5 px-10 mr-2 mb-2 text-sm font-medium text-gray-600 focus:outline-none rounded-lg bg-lime-400 dark:text-black dark:border-gray-600 hover:bg-lime-500 dark:hover:text-gray-800 flex items-center"
        >
          <h1 className="font-sans font-bold">Añadir Equipo</h1>
          <Add />
        </button>
      </div>
      <br />

      <Paper sx={{ width: "100%" }}>
        <WizModal
          modalID="team-delete"
          modalTitle={"Borrar Equipo"}
          modalOpen={openDeleteNewsModal}
          setOpenModal={(e) => setOpenDeleteNewsModal(false)}
          modalType={"DESITION"}
          modalWhithDelay={2000}
          onModalClose={(desition) => showAnimation(desition, 2000)}
        >
          <div>
            {showDeleteAnimation === "YES" ? (
              <DeletedItem />
            ) : (
              <DeleteConfirm />
            )}
          </div>
        </WizModal>
        <WizModal
          modalID="team-add"
          modalOpen={showAddTeamModal}
          setOpenModal={(e) => setshowAddTeamModal(false)}
          modalWhithDelay={2000}
          modalTitle="Añadir Equipo"
          onModalClose={(desition) => showAnimationAddMain(desition, 2000)}
        >
          <div>
            <AddTeamForm
              onSubmit={(data) => {
                setMainteamsFormData(data as any);
              }}
              isLoading={isLoadingMutate}
              update={
                () =>
                  addMutateTeamsCD() /*  showAnimationAddMain("YES", 2000) */
              }
            />
          </div>
        </WizModal>

        <WizModal
          modalID="news-edit"
          // modalType={"DESITION"}
          modalOpen={showEditItemNewsModal}
          setOpenModal={(e) => setShowEditItemNewsModal(false)}
          modalWhithDelay={2000}
          modalTitle="Editar Equipo"
          onModalClose={(desition) => showAnimationCD(desition, 2000)}
        >
          <div>
            <EditTeamForm
              refreshData={() => refreshPage()}
              teamsRow={teamsRow!}
              itemNews={itemNews!}
              rowsId={rowsId}
              onSubmit={(data) => console.log("newsData", data)}
              update={() => showAnimationCD("YES", 2000)}
            />
          </div>
        </WizModal>

        <TableContainer
          sx={{
            maxHeight: 840,
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "cyan",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "lightblue",
              borderRadius: 2,
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750, marginTop: 4 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody ref={animationParent}>
              {stableSort(rows as any, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, index) => {
                  const isItemSelected = isSelected(row.team_id as any);

                  return (
                    <React.Fragment key={row.team_id}>
                      <TableRow
                        sx={{ "& > *": { borderBottom: "unset" } }}
                        hover
                        onClick={(event) =>
                          handleClick(event, row.team_id as any)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell sx={{ maxWidth: 100 }}>
                          <img
                            src={BASE_URL_IMG + row.img_url}
                            onError={(
                              e: React.SyntheticEvent<HTMLImageElement, Event>
                            ) => {
                              const target = e.target as HTMLImageElement;
                              target.src = defaultImage;
                            }}
                            className="ml-5 w-20 h-22"
                            style={{ maxHeight: 90, minHeight: 90 }}
                            alt=""
                          />
                        </TableCell>

                        <TableCell sx={{ maxWidth: 200 }}>
                          <h1 style={{ fontSize: "20px" }}> {row.name} </h1>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center justify-center">
                            <div className="p-2 rounded-full hover:bg-gray-100 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                              <Edit
                                onClick={() => {
                                  setRowsId(row.team_id);
                                  setShowEditItemNewsModal(true);
                                  console.log(row.team_id);
                                }}
                              />
                            </div>
                            <div className="p-2 rounded-full hover:bg-red-200 transform transition-all duration-150 ease-in-out cursor-pointer hover:translate-y-2">
                              <TrashIcon
                                /*                                 onClick={() => deleteTeam(row.team_id)} */
                                onClick={() => {
                                  showHiddenDeleteComponent();
                                  deleteTeam(row.team_id);
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default TeamsData;
