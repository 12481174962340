import { Button } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as React from "react";

//LOCALS
import { BASE_URL, BASE_URL_IMG } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import toast from "../Toast";

//LOCALS
import { MyField } from "../MyField";
import PlayersFilter from "../select/PlayersFilter";
import { useAppSelector } from "../../redux/hooks";
import { Loader } from "../Loader";
import { SetStateAction, useState } from "react";
import { AddImagesProfileForm } from "../Forms/ProfileForm/AddImagesProfileForm";
import { lightGreen } from "@mui/material/colors";
import defaultImage from "../../assets/images/logo_shadow.png"
interface Values {
  name: string;
  img_url: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  itemNews: string;
  rowsId: string;
  refreshData: () => void;
  update: any;
  teamsRow: { name: string; team_id: string; img_url: string }[];
}

function EditTeamForm({
  onSubmit,
  rowsId,
  refreshData,
  update,
  teamsRow,
}: Props) {
  const [uid, setUid] = useLocalStorage("UID", "");
  const [updateRowNew, setUpdateRowNew] = React.useState({
    name: "",
    img_url: "",
  });
  const [updatedNewResponse, setUpdatedNewReposen] =
    React.useState<React.SetStateAction<any>>();
  const filters = useAppSelector((state) => state.player);

  console.log(rowsId);

  /*     {
      enabled: rowsId !== undefined || rowsId !== "",
    } */
  const { mutate: updateRowNews } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("team_id", rowsId);
      formData.append("team_name", updateRowNew?.name ?? teamsRow[0].name);

      if (updateRowNew?.name === undefined) {
        formData.append("image", updateRowNew?.img_url);
      } else {
        formData.append("team_name", updateRowNew?.name);
        formData.append("image", updateRowNew?.img_url);
      }

      return axios.post(`${BASE_URL}teams`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedNewReposen(result);
      },
      onError: (err: any) => {
        setUpdatedNewReposen(err.response?.data || err);
      },
    }
  );
  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //UPDATE BIO RESPONSE
  React.useEffect(() => {
    if (updatedNewResponse?.data?.status === "ok") {
      notify(
        "success",
        `Equipo Actualizado`
      );

      setTimeout(() => {
        dismiss();
        refreshData(); 
      }, 1500);
    } else if (updatedNewResponse?.data?.status === "error") {
      notify("error", `${updatedNewResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedNewResponse]);

  const [isAdded, setIsAdded] = useState(false);
  const [imageUrlTeam, setimageUrlTeam] = useState<SetStateAction<any>>();
  const [imagesResponse, setImagesResponse] = useState<SetStateAction<any>>();

  //ADD IMAGES PROFILE
  const { mutate: mutateImages } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("team_id", "teamID");
      formData.append("img_profile", imageUrlTeam);

      return axios.post(`${BASE_URL}players`, formData, {
        headers: {
          Authorization: "Bearer " + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setImagesResponse(result);
      },
      onError: (err: any) => {
        setImagesResponse(err.response?.data || err);
      },
    }
  );

  console.log("row" + teamsRow);
  return (
    <div>
      <div className="flex space-x-4"></div>
      <Formik
        initialValues={{
          name: teamsRow?.[0]?.name,
          img_url: teamsRow?.[0]?.img_url,
        }}
        onSubmit={(values, { resetForm }) => {
          console.log("valie" + values);
          setUpdateRowNew({
            name: values.name,
            img_url: values.img_url,
          });
          onSubmit(values);
          update();
          if (updateRowNew.name !== "") {
            updateRowNews();
          }
        }}
      >
        {({ values, handleBlur, handleChange, submitForm, setFieldValue }) => (
          <Form>
            <div className="flex flex-col justify-between space-y-4">
              <label className="font-bold mr-4" htmlFor="img_url">
                Escudo
              </label>
              <img
                className="ml-5 w-20 h-22"
                style={{ maxHeight: 90, minHeight: 90 }}
                src={BASE_URL_IMG + teamsRow?.[0]?.img_url}
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  const target = e.target as HTMLImageElement;
                  target.src = defaultImage;
                }}
                alt="escudo"
              />
              <label className="font-bold mr-4" htmlFor="img_url">
                Nuevo Escudo
              </label>
              <input
                type="file"
                id="img_url"
                name="img_url"
                accept="image/png"
                onChange={(event) => {
                  console.log("evento", event);
                  const file = event.currentTarget.files?.[0];
                  setFieldValue("img_url", file);
                }}
              />
              <ErrorMessage name="img_url" component="div" />
              <label className="font-bold mr-4" htmlFor="img_url">
                Nombre
              </label>
              <div>
                <input
                  name="name"
                  type="text"
                  defaultValue={teamsRow?.[0]?.name}
                  onChange={handleChange}
                  style={{ borderRadius: "5px", height: "39px", border: "3px",borderColor: "lightgray" }} // Ajusta el valor "8px" según el redondeo que desees
                />

                {/*               <Field
                  name="name"
                    placeholder={teamsRow?.[0]?.name}  
                  i="dsadashdas"
                  component={MyField}
                /> */}
              </div>
            </div>
            <button
              className="flex items-center mt-4 bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
              type="submit"
              onClick={submitForm}
            >
              Actualizar
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditTeamForm;
