import { PayloadAction } from "@reduxjs/toolkit"
import { PLAYER_FILTER_ADD_PARAM, PLAYER_FILTER_RESTART_STATE } from "../constants/playerConstants"

const InitialState = {
    filters: {}
}



export const playerReducer = (
    state = InitialState, action: PayloadAction<any>
) => {
    switch (action.type) {
        case PLAYER_FILTER_ADD_PARAM:
            return {
                ...state, filters: { ...state.filters, ...action.payload }
            }
        case PLAYER_FILTER_RESTART_STATE:
            return InitialState

        default:
            return state
    }
}