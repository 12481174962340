import { Button } from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as React from "react";
import { MyField } from "./MyField";
import Select from "react-select";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

//LOCALS
import CountryFilter from "./select/NationalityFilter";
import PositionFilter from "./select/PositionFilter";
import SecondNationalityFilter from "./select/SecondNationalityFilter";
import { Loader } from "./Loader";

interface Values {
  firstName: string;
  lastName: string;
  weight: string;
  height: string;
  birthdate: string;
  jersey_nro: string;
  category: string;
  current_team: string;
  current_team_name: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  setSelectFoot: React.SetStateAction<any>;
  selectFoot: Foot | undefined;
  update: any;
  isLoading: boolean;
}

type Foot = {
  value: number;
  label: string;
};
const footData: Foot[] = [
  {
    value: 1,
    label: "Derecho",
  },
  {
    value: 2,
    label: "Izquierdo",
  },
];

export const MyForm: React.FC<Props> = ({
  onSubmit,
  setSelectFoot,
  selectFoot,
  update,
  isLoading,
}) => {
  const handleChangeFoot = (event: any) => {
    setSelectFoot(event);
  };

  const Schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    weight: z.string(),
    height: z.string(),
    birthdate: z.string(),
    jersey_nro: z.string(),
    category: z.string(),
    current_team: z.string(),
    current_team_name: z.string(),
  });

  interface AddPlayerFormData {
    firstName: string;
    lastName: string;
    weight: string;
    height: string;
    birthdate: string;
    jersey_nro: string;
    category: string;
    current_team: string;
    current_team_name: string;
  }
  interface AddCoachFormData {
    firstName: string;
    lastName: string;
    birthdate: string;
    category: string;
    current_team: string;
    current_team_name: string;
  }

  if (!isLoading)
    return (
      <Formik<AddPlayerFormData>
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          firstName: "",
          lastName: "",
          weight: "",
          height: "",
          birthdate: "",
          jersey_nro: "",
          category: "",
          current_team: "",
          current_team_name: "",
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values);
          setSubmitting(false);
          update();
          // resetForm();
        }}
      >
        {({ values, errors, isSubmitting, submitForm }) => (
          <Form>
            <div className="flex flex-col justify-between">
              <div className="flex flex-col justify-start space-y-2 mb-4 ">
                <CountryFilter />
              </div>
              <div className="flex flex-col justify-start space-y-2 mb-4 ">
                <SecondNationalityFilter />
              </div>
              <div className="flex justify-between">
                <div className="flex-col items-start">
                  <Field
                    name="firstName"
                    placeholder="Nombre"
                    component={MyField}
                  />
                  {errors.firstName && (
                    <div className="text-sm text-left text-red-400">
                      {errors.firstName}
                    </div>
                  )}
                </div>
                <div>
                  <Field
                    name="lastName"
                    placeholder="Apellido"
                    component={MyField}
                  />
                  {errors.lastName && (
                    <div className="text-sm text-left text-red-400">
                      {errors.lastName}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between my-4">
                <div>
                  <Field name="weight" placeholder="Peso" component={MyField} />
                  {errors.weight && (
                    <div className="text-sm text-left text-red-400">
                      {errors.weight}
                    </div>
                  )}
                </div>
                <div>
                  <Field
                    name="height"
                    placeholder="Altura"
                    component={MyField}
                  />
                  {errors.height && (
                    <div className="text-sm text-left text-red-400">
                      {errors.height}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between my-4">
                <div>
                  <Field
                    name="birthdate"
                    placeholder="Nacimiento/yyyy-mm-dd"
                    component={MyField}
                  />
                  {errors.birthdate && (
                    <div className="text-sm text-left text-red-400">
                      {errors.birthdate}
                    </div>
                  )}
                </div>
                {/* <PrimaryPositionSelect /> */}
                <PositionFilter />
              </div>
              <div className="flex justify-between my-4">
                <div>
                  <Field
                    name="jersey_nro"
                    placeholder="Número de Camiseta"
                    component={MyField}
                  />
                  {errors.jersey_nro && (
                    <div className="text-sm text-left text-red-400">
                      {errors.jersey_nro}
                    </div>
                  )}
                </div>
                <div>
                  <Field
                    name="current_team"
                    placeholder="Equipo Actual"
                    component={MyField}
                  />
                  {errors.current_team && (
                    <div className="text-sm text-left text-red-400">
                      {errors.current_team_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <Field
                    name="category"
                    placeholder="Categoria"
                    component={MyField}
                  />
                  {errors.category && (
                    <div className="text-sm text-left text-red-400">
                      {errors.category}
                    </div>
                  )}
                </div>
                <div>
                  <Select
                    placeholder="Seleccionar Pie"
                    options={footData}
                    value={selectFoot}
                    onChange={(e) => handleChangeFoot(e)}
                  />
                </div>
              </div>
            </div>
            <button disabled={isSubmitting} type="submit" onClick={submitForm}>
              Añadir Jugador
            </button>
          </Form>
        )}
      </Formik>
    );
  else {
    return <Loader />;
  }
};
