import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";

//IMAGES
import image from "../assets/images/shadow.png";
//COMPONENTS
import PlayerBio from "../components/playerData/PlayerBio";
import PlayerCareer from "../components/playerData/PlayerCareer";
import PlayerData from "../components/playerData/PlayerData";
import PlayerGallery from "../components/playerData/PlayerGallery";
import PlayerImages from "../components/playerData/PlayerImage";
import PlayerNews from "../components/playerData/PlayerNews";
import PlayerPerformance from "../components/playerData/PlayerPerformance";
import PlayerVideos from "../components/playerData/PlayerVideos";
import PlayerYouthCareer from "../components/playerData/PlayerYouthCareer";
import { ButtonPencil } from "../components/button/ButtonPencil";
import PlayerName from "../components/playerData/PlayerName";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAtom } from "jotai";
import { showImageModal } from "../components/jotai/jotaiAtom";
import { BASE_URL, BASE_URL_IMG } from "../api";
import { useParams } from "react-router-dom";
import { Player } from "../types/data";

function Players() {
  const params = useParams();

  const playerId = params.id;

  //NAME
  const [showImage, setShowImage] = useAtom(showImageModal);

  //SHOW STATE
  const [showNews, setShowNews] = useState(true);
  const [showPerformance, setShowPerformance] = useState(false);
  const [showBio, setShowBio] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showEditFirst, setShowEditFirst] = useState(false);
  const [showEditSecond, setShowEditSecond] = useState(false);
  const [showEditThird, setShowEditThird] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [teamsData, setteamsData] = useState<any>([]);

  const getTeams = async () => {
    const response = await axios.get(`${BASE_URL}teams`);
    const teamData = response.data;

    setteamsData(teamData);
  };

  const getPlayersById = async () => {
    const { data } = await axios.get(
      `${BASE_URL}players?id=${playerId}&countryid=ES`
    );

    return data;
  };

  const { data, isLoading, refetch } = useQuery<Player>(
    ["playerById", playerId],
    getPlayersById
  );

  const refreshPage = () => {
    window.location.reload();
    setTimeout(getTeams, 1000);
  };

  useEffect(() => {
    setIsRefreshing(false);
  }, [data]);

  //HANDLE STATE
  const handleNews = () => {
    setShowNews(true);
    setShowBio(false);
    setShowPerformance(false);
    setShowVideos(false);
    setShowGallery(false);
  };
  const handlePerformance = () => {
    setShowNews(false);
    setShowBio(false);
    setShowPerformance(true);
    setShowVideos(false);
    setShowGallery(false);
  };
  const handleBio = () => {
    setShowNews(false);
    setShowBio(true);
    setShowPerformance(false);
    setShowVideos(false);
    setShowGallery(false);
  };
  const handleGallery = () => {
    setShowNews(false);
    setShowBio(false);
    setShowPerformance(false);
    setShowVideos(false);
    setShowGallery(true);
  };

  const handleVideos = () => {
    setShowNews(false);
    setShowBio(false);
    setShowPerformance(false);
    setShowVideos(true);
    setShowGallery(false);
  };

  const classNames = {
    primary:
      "border-t-4 border-blue-300 transition-all transform duration-150 ease-out",
  };

  const [imageUrl, setImageUrl] = useState("");

  /*   async function fetchData() {
    const matchedTeam = teamsData.find(
      (team: { name: string; img_url: string }) =>
        data?.data?.current_team === team.name
    );

    if (matchedTeam) {

    }
    setImageUrl(matchedTeam?.img_url);
  } */

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (teamsData) {
      const matchedTeam = teamsData.find(
        (team: { name: string; img_url: string }) =>
          data?.data?.current_team_name === team.name
      );

      setImageUrl(matchedTeam?.img_url);
    }
  }, [teamsData, data]);

  if (!isLoading)
    return (
      <div className="pb-36  " style={{ backgroundColor: "#f3f3f4" }}>
        <>
          <Header />

          <div
            className="bg-blue-200 flex xl:flex-row flex-col  xl:max-w-[50%] w-full mx-auto"
            /*             style={{ backgroundColor: "#f3f3f4" }} */
          >
            <div
              className="border shadow  rounded w-full mx-auto"
              style={{ backgroundColor: "white" }}
            >
              <PlayerName
                data={data?.data!}
                isLoading={isLoading}
                refetch={refetch}
              />
              <br />
              <div className="flex justify-center">
                {data?.data?.img_profile_url === null ? (
                  <img
                    loading="lazy"
                    src={image}
                    alt=""
                    className="w-32 h-32 object-cover border-2 border-black rounded-full"
                  />
                ) : (
                  <img
                    className="w-32 h-32 object-cover border-2 border-black rounded-full"
                    alt="alt"
                    src={BASE_URL_IMG + data?.data?.img_profile_url}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; //prevents looping
                      currentTarget.src = image;
                    }}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    marginLeft: "100px",
                    marginTop: "-2px",
                    borderWidth: "2px",
                    borderColor: "black",
                  }}
                  className="bg-white rounded-full h-12 w-12 flex justify-center"
                >
                  <img
                    alt="alt"
                    className="w-8 h-10 object-cover rounded-full mt-0.5"
                    src={BASE_URL_IMG + imageUrl}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = image;
                    }}
                  />
                </div>
              </div>
              <br />
              <PlayerImages refreshData={() => refreshPage()} />
              <PlayerCareer data={data} />
              <div
                className="flex flex-col "
                style={{ backgroundColor: "white" }}
              >
                <div className="flex  space-x-10">
                  <button
                    className={`${
                      showNews ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2 pb-10`}
                    onClick={handleNews}
                  >
                    Noticias
                  </button>
                  <button
                    className={`${
                      showPerformance ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2  pb-10`}
                    onClick={handlePerformance}
                  >
                    Rendimiento
                  </button>
                  <button
                    className={`${
                      showBio ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2  pb-10`}
                    onClick={handleBio}
                  >
                    Bio
                  </button>
                  <button
                    className={`${
                      showGallery ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2  pb-10`}
                    onClick={handleGallery}
                  >
                    Gallery
                  </button>
                  <button
                    className={`${
                      showVideos ? `${classNames.primary}` : ""
                    } text-2xl font-bold py-2  pb-10`}
                    onClick={handleVideos}
                  >
                    Videos
                  </button>
                </div>
                <div className="pb-10">
                  {showNews && <PlayerNews data={data?.news!} />}
                  {showPerformance && (
                    <PlayerPerformance
                      data={data?.performance!}
                      refreshData={() => refreshPage()}
                      refetch={refetch}
                    />
                  )}
                  {showBio && (
                    <PlayerBio
                      refreshData={() => refreshPage()}
                      data={data?.text!}
                    />
                  )}
                  {showGallery && (
                    <PlayerGallery
                      data={data?.galery!}
                      refreshData={() => refreshPage()}
                    />
                  )}
                  {showVideos && (
                    <PlayerVideos
                      refreshData={() => refreshPage()}
                      data={data?.videos!}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className="border shadow p-4"
              style={{ backgroundColor: "white" }}
            >
              <div
                className="flex justify-center"
                style={{
                  backgroundPosition: "center",
                  backgroundImage:
                    "url('https://grupopineda.eu/wp-content/uploads/2022/03/shutterstock_1912601503.jpg')",
                }}
              >
                {data?.data?.img_header_url === null ? (
                  <img
                    loading="lazy"
                    src={image}
                    alt=""
                    className="w-96 object-cover"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; //prevents looping
                      currentTarget.src = image;
                    }}
                  />
                ) : (
                  <img
                    alt="alt"
                    src={BASE_URL_IMG + data?.data?.img_header_url}
                    className="w-72 h-96 object-contain"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; //prevents looping
                      currentTarget.src = image;
                    }}
                  />
                )}
                <ButtonPencil onClick={() => setShowImage(!showImage)} />
              </div>
              <PlayerData
                refetch={refetch}
                data={data}
                showEditSecond={showEditSecond}
                showEditFirst={showEditFirst}
                showEditThird={showEditThird}
                setShowEditFirst={setShowEditFirst}
                setShowEditSecond={setShowEditSecond}
                setShowEditThird={setShowEditThird}
                refreshData={() => refreshPage()}
              />

              <PlayerYouthCareer data={data} />
            </div>
          </div>
          <Footer />
        </>
      </div>
    );
}

export default Players;
