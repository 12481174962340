import React, { ReactNode, SetStateAction } from "react";
import PropTypes from "prop-types";

// LOCALS

// ICONS
import { IoCloseSharp } from "react-icons/io5";

// STYLES
import wizTimeOutFun from "../utils/WizTimeOutFun";

interface Props {
  modalID: string;
  modalTitle: string;
  children: ReactNode;
  modalOpen: boolean;
  setOpenModal: SetStateAction<any>;
  modalType?: string;
  modalWhithDelay: number;
  onModalClose: (desition: string) => Promise<void>;
}

const WizModal = ({
  modalID,
  modalTitle,
  children,
  modalOpen,
  setOpenModal,
  modalType,
  onModalClose,
  modalWhithDelay,
}: Props) => {
  const closeModal = async (desitionType: string) => {
    if (modalType === "DESITION" && desitionType === "YES") {
      await onModalClose(desitionType);
      if (modalWhithDelay) {
        await wizTimeOutFun(100); // Espera solo 1 segundo antes de recargar
        // window.location.reload();
        setOpenModal(false);
      }
    } else {
      onModalClose(desitionType);
      setOpenModal(false);
    }
  };

  const renderModalType = () => {
    switch (modalType) {
      case "CONFIRM":
        return (
          <label
            htmlFor={modalID}
            className="btn"
            onClick={() => closeModal("YES")}
          >
            ok
          </label>
        );
        break;
      case "DESITION":
        return (
          <>
            <div className="btn" onClick={() => closeModal("YES")}>
              Yes
            </div>
            <div className="btn" onClick={() => closeModal("NO")}>
              No
            </div>
          </>
        );
        break;

      case "NONE":
        return null;
        break;

      default:
        return null;
        break;
    }
  };

  return (
    <div>
      <input type="checkbox" id={modalID} className={`modal-toggle`} />
      <div className={`modal ${modalOpen && "modal-open"}`}>
        <div className="modal-box ">
          <div
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={() => closeModal("NO")}
          >
            <IoCloseSharp />
          </div>

          <h3 className="font-bold text-lg">{modalTitle}</h3>
          <div className="p-2">{children}</div>

          <div className="modal-action">{renderModalType()}</div>
        </div>
      </div>
    </div>
  );
};

WizModal.propTypes = {
  modalTitle: PropTypes.string,
  children: PropTypes.object,
  modalOpen: PropTypes.bool,
  setOpenModal: PropTypes.func,
  modalType: PropTypes.oneOf(["CONFIRM", "DESITION", "NONE"]),
  onModalClose: PropTypes.func,
};

WizModal.defaultProps = {
  modalTitle: "",
  children: {},
  modalOpen: false,
  onModalClose: () => true,
};

export default React.memo(WizModal);
