import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

//LOCALS
import { BASE_URL } from "../../../api";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Loader } from "../../Loader";
import toast from "../../Toast";

interface Values {
  bio: string;
}

interface Props {
  onSubmit: (values: Values) => void;
  item: string;
}

interface BioProps {
  bio: string;
  country_code: string;
  player_id: string;
}

export const EditBioForm = ({ onSubmit, item }: Props) => {
  const [uid, setUid] = useLocalStorage("UID", "");
  const navigate = useNavigate();
  const route = useParams();
  const [bioValue, setBioValue] = React.useState("");
  const [updatedBioResponse, setUpdatedBioResponse] =
    React.useState<React.SetStateAction<any>>();
  const playerId = route.id as any;

  //GET BIO BY COUNTRY

  const getBiosByCountry = async () => {
    const { data } = await axios.get(
      `${BASE_URL}bio?player_id=${playerId}&country_code=${item}`
    );

    return data.result[0];
  };

  const { data: bioData, isLoading: isBioLoading } = useQuery(
    ["playerBioByCountry", item],
    getBiosByCountry
  );

  //UPDATE ROW BIO
  const { mutate: updateRowBio, isLoading } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("player_id", playerId);
      formData.append("country_code", item);
      formData.append("bio_text", bioValue);
      return axios.post(`${BASE_URL}bio`, formData, {
        headers: {
          Authorization: "Bearer" + uid,
        },
      });
    },
    {
      onSuccess: async (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setUpdatedBioResponse(result);
      },
      onError: (err: any) => {
        setUpdatedBioResponse(err.response?.data || err);
      },
    }
  );

  //TOAST
  const notify = React.useCallback((type: string, message: string) => {
    toast({ type, message });
  }, []);

  const dismiss = React.useCallback(() => {
    toast.dismiss();
  }, []);

  //UPDATE BIO RESPONSE
  React.useEffect(() => {
    if (updatedBioResponse?.data?.result === 1) {
      notify("success", `Bio Actualizada`);

      setTimeout(() => {
        dismiss();
      }, 1000);
    } else if (updatedBioResponse?.data?.status === "error") {
      notify("error", `${updatedBioResponse?.data.result.error_msg}`);
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  }, [updatedBioResponse]);

  if (!isBioLoading || !isLoading)
    return (
      <div>
        <h1>{item}</h1>

        <Formik
          initialValues={{
            bio: bioData?.bio,
          }}
          onSubmit={(values, { resetForm }) => {
            setBioValue(values.bio);
            onSubmit(values);
            resetForm();
            if (bioValue !== "") {
              updateRowBio();
            }
          }}
        >
          {({ values, handleChange, handleBlur, submitForm }) => (
            <Form>
              <div className="flex flex-col justify-between space-y-4">
                <div>
                  <textarea
                    className="w-full h-40 px-4 py-2 border"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="bio"
                    placeholder="Biografía"
                    value={values.bio}
                  />
                </div>
              </div>
              <button        className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out " type="submit">Actualizar Bio</button>
            </Form>
          )}
        </Formik>
      </div>
    );
  else {
    return <Loader />;
  }
};
