import { Formik } from "formik";
import React, { ReactNode, SetStateAction, useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { players } from "../../types/data";
//LOCALS

import wizTimeOutFun from "../../utils/WizTimeOutFun";
import { Loader } from "../Loader";
import axios from "axios";
import { BASE_URL } from "../../api";
import { useAtom } from "jotai";
import { teamID } from "../jotai/jotaiAtom";
import CountryFilter from "../select/NationalityFilter";
import SecondNationalityFilter from "../select/SecondNationalityFilter";

interface Props {
  modalID: string;
  modalTitle: string;
  children: ReactNode;
  modalOpen: boolean;
  setOpenModal: SetStateAction<any>;
  modalType?: string;
  modalWhithDelay: number;
  onModalClose: (desition: string) => Promise<void>;
  data: players | undefined;
  setFormValues: SetStateAction<any>;
  update: any;
  isLoading: boolean;
}

function EditPlayerModal({
  modalID,
  modalTitle,
  children,
  modalOpen,
  setOpenModal,
  modalType,
  onModalClose,
  modalWhithDelay,
  data,
  setFormValues,
  update,
  isLoading,
}: Props) {
  const closeModal = async (desitionType: string) => {
    onModalClose(desitionType);
    if (modalType === "DESITION" && desitionType === "YES" && modalWhithDelay)
      await wizTimeOutFun(modalWhithDelay);
    setOpenModal(false);
  };

  const [teamData, setTeamData] = useState([]);
  const [team_ID, setTeam_ID] = useAtom(teamID);

  useEffect(() => {
    const fetchTeamData = async () => {
      const response = await axios.get(`${BASE_URL}teams`);
      const teamData = response.data;
      setTeamData(teamData);
    };

    fetchTeamData();
  }, []);

  const renderModalType = () => {
    const getTeams = async () => {
      const response = await axios.get(`${BASE_URL}teams`);
      const teamData = response.data;
      return teamData;
    };

    getTeams();

    switch (modalType) {
      case "CONFIRM":
        return (
          <label
            htmlFor={modalID}
            className="btn"
            onClick={() => closeModal("YES")}
          >
            ok
          </label>
        );
        break;
      case "DESITION":
        return (
          <>
            <div className="btn" onClick={() => closeModal("YES")}>
              Yes
            </div>
            <div className="btn" onClick={() => closeModal("NO")}>
              No
            </div>
          </>
        );
        break;

      case "NONE":
        return null;
        break;

      default:
        return null;
        break;
    }
  };

  return (
    <div>
      <div>
        <input type="checkbox" id={modalID} className={`modal-toggle`} />
        <div className={`modal ${modalOpen && "modal-open"}`}>
          <div className="modal-box ">
            <div
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={() => closeModal("NO")}
            >
              <IoCloseSharp />
            </div>

            <h3 className="font-bold text-lg">{modalTitle}</h3>
            <div className="p-2">{children}</div>
            {!isLoading ? (
              <Formik<{
                birthdate: string | undefined;
                actual_team: string | undefined;
                category: string | undefined;
              }>
                initialValues={{
                  birthdate: data?.birthdate,
                  actual_team: data?.current_team,
                  category: data?.category,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setFormValues({
                    birthdate: values.birthdate,
                    actual_team: values.actual_team,
                    category: values.category,
                  });
                  update();
                  if (values.actual_team) {
                    setTeam_ID(values.actual_team);
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {isSubmitting ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="flex-col">
                          <div className="flex flex-col items-start">
                            <label>Fecha de Nacimiento</label>
                            <input
                              className="border-2 px-4 py-3 rounded-lg bg-gray-100 w-[70%]"
                              type="text"
                              name="birthdate"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.birthdate}
                            />
                          </div>
                        </div>
                        <div className="flex-col ">
                          <div className="flex flex-col items-start">
                            <label>Equipo Actual</label>
                            <select
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-200 focus:border-blue-200 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-200 dark:focus:border-blue-200"
                              name="actual_team"
                              value={values.actual_team}
                              placeholder={values.actual_team}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option selected>Selecciona Equipo</option>
                              {teamData.map(
                                (team: {
                                  team_id: string;
                                  name: string;
                                  img_url: string;
                                }) => (
                                  <option key={team.name} value={team.team_id}>
                                    {team.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <label>Nacionalidad</label>
                        <CountryFilter />
                        <label>Segunda nacionalidad</label>
                        <SecondNationalityFilter/>
                        </div>
                      </>
                    )}
                    <button
                      style={{ marginTop: "12px" }}
                      disabled={isSubmitting}
                      type="submit"
                      // onClick={submitForm}
                      className="flex items-center bg-lime-400 hover:bg-lime-500 px-4 py-2 rounded-lg shadow-lg transition-all transform duration-200 ease-in-out "
                    >
                      Cambiar Datos
                    </button>
                  </form>
                )}
              </Formik>
            ) : (
              <Loader />
            )}

            <div className="modal-action">{renderModalType()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPlayerModal;
